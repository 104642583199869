import type { Table as TanstackTable } from "@tanstack/react-table"

import type { Vendor } from "@/server/schemas"
import { QueueItemRow, QueueItems } from "@/components/ui/queue"
import { QueueView } from "@/components/shared/queue"

export function VendorQueue({
	listDataState,
}: {
	listDataState: TanstackTable<Vendor>
}) {
	return (
		<QueueView>
			<QueueItems
				listDataState={listDataState}
				renderItem={(vendor) => (
					<>
						<QueueItemRow>
							<div className="truncate font-bold">
								{vendor.name}
							</div>
							<div className="shrink-0 text-sm text-foreground-weak">
								{vendor.contacts[0]?.name}
							</div>
						</QueueItemRow>
						<QueueItemRow variant="secondary">
							<div className="truncate">
								{vendor.city}, {vendor.state}
							</div>
							{/*  @TODO - mockup has this <div className="shrink-0">Last: 1/12/25</div> */}
						</QueueItemRow>
					</>
				)}
				onRowClick={(vendor) => {
					listDataState.setRowSelection({
						[vendor.id]: true,
					})
				}}
				isActive={(vendor) =>
					listDataState
						.getSelectedRowModel()
						.rows.some((row) => row.id === vendor.id.toString())
				}
			/>
		</QueueView>
	)
}
