import { PlusIcon, UsersIcon } from "lucide-react"

import type { Location } from "@/server/schemas"
import { Button } from "@/components/ui/button"
import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

import { LocationContactDetailsCard } from "../../../contact-details-card"

export const LocationContactsSection: DetailsSectionComponent<Location> = ({
	data: location,
}) => (
	<DetailsSection>
		<DetailsSectionHeading>Contacts</DetailsSectionHeading>
		{location.contacts ?
			location.contacts.map((contact) => (
				<LocationContactDetailsCard
					key={contact.id}
					contact={contact}
				/>
			))
		:	<div className="w-full pb-5 text-sm italic text-foreground-weakest">
				No Contacts
			</div>
		}
		<Button
			variant="outline"
			className="mt-2 w-full gap-1 border-dashed text-primary shadow-none"
		>
			<PlusIcon className="size-4" />
			Add Contact
		</Button>
	</DetailsSection>
)

LocationContactsSection.label = "Contacts"
LocationContactsSection.icon = UsersIcon
