import { motion } from "motion/react"

import { TeamViewUser } from "./user"
import { ShopWatchTeamViewUserProvider } from "./user/context"

export const TeamView = ({ ...props }) => {
	const users = [
		{ name: "Mike Mechanic", location: "WP Tampa West" },
		{ name: "Larry David", location: "WP Tampa West" },
		{ name: "Andy Ford", location: "WP Tampa West" },
		{ name: "Mike David", location: "WP Ybor City" },
		{ name: "JL Mechalister", location: "WP Ybor City" },
		{ name: "Anne Govea", location: "WP Ybor City" },
	]
	return (
		<motion.div {...props}>
			<div className="mb-2 mt-3 text-sm font-semibold uppercase tracking-wider text-foreground-weak">
				Active
			</div>
			<motion.div
				className="flex gap-3 overflow-x-auto pb-2 pr-3"
				transition={{
					staggerChildren: 1,
					duration: 0.3,
					type: "spring",
					stiffness: 35,
					damping: 2.2,
					mass: 0.1,
				}}
			>
				{users.map((user, index) => (
					<motion.div
						className="flex w-[220px] shrink-0 flex-col rounded-lg border border-border-weak bg-background shadow-md"
						key={`swi-` + index}
						initial={{ opacity: 0 }}
						animate={{
							opacity: 1,
						}}
						transition={{
							duration: 0.1,
							type: "spring",
							stiffness: 35,
							damping: 2.2,
							mass: 0.1,
						}}
					>
						<ShopWatchTeamViewUserProvider>
							<TeamViewUser user={user} />
						</ShopWatchTeamViewUserProvider>
					</motion.div>
				))}
			</motion.div>
		</motion.div>
	)
}
