import { pgTable, primaryKey } from "drizzle-orm/pg-core"

import { tagFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"
import { customerTable } from "./customer"

export const customerTagTable = pgTable(
	"customer_tag",
	tagFields(scopeTable, customerTable),
	(table) => [primaryKey({ columns: [table.rowId, table.tag] })],
)
