import type { FakeService } from "@/routes/_main+/admin+/services/fake-services"
import { InfoIcon } from "lucide-react"

import {
	DetailsSection,
	DetailsSectionHeading,
	DetailsSectionsGrouping,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"
import { Notes } from "@/components/shared/details/notes"

import { ServiceAttachmentsSection } from "./attachments"
import { ServiceLaborSection } from "./labor"
import { ServicePartsSection } from "./parts"

export const ServiceInfoSection: DetailsSectionComponent<FakeService> = ({
	data: service,
}) => (
	<>
		<DetailsSectionsGrouping>
			{service.notes && <Notes notes={[service.notes]} />}
			<DetailsSection>
				<DetailsSectionHeading>Description</DetailsSectionHeading>
				<p>{service.description}</p>
			</DetailsSection>
			<ServiceLaborSection data={service} />
			<ServicePartsSection data={service} />
			<ServiceAttachmentsSection data={service} />
		</DetailsSectionsGrouping>
	</>
)
ServiceInfoSection.label = "Info"
ServiceInfoSection.icon = InfoIcon
