import { trpcClient } from "@/trpc/client"

import type { DetailsViewComponent } from "@/components/shared/details/details-view"
import { DetailsViewContainer } from "@/components/shared/details/details-view"

import { EquipmentDevicesSection } from "./sections/devices"
import { EquipmentFinancialSection } from "./sections/financial"
import { EquipmentHistorySection } from "./sections/history"
import { EquipmentInfoSection } from "./sections/info"
import { EquipmentMaintenanceSection } from "./sections/maintenance"
import { EquipmentPageHeader } from "./sections/page-header"
import { EquipmentPanelHeader } from "./sections/panel-header"
import { EquipmentSettingsSection } from "./sections/settings"

export const EquipmentDetailsView: DetailsViewComponent = (props) => {
	const { id: equipmentId } = props
	const [result] = trpcClient.equipment.getEquipmentById.useSuspenseQuery({
		id: parseInt(equipmentId),
	})
	if (!result.success) {
		throw new Error("Failed to fetch equipment")
	}

	return (
		<DetailsViewContainer
			data={result.value}
			headers={{ page: EquipmentPageHeader, panel: EquipmentPanelHeader }}
			sections={{
				info: EquipmentInfoSection,
				maintenance: EquipmentMaintenanceSection,
				devices: EquipmentDevicesSection,
				financial: EquipmentFinancialSection,
				settings: EquipmentSettingsSection,
				history: EquipmentHistorySection,
			}}
		/>
	)
}
