import { pgTable, primaryKey } from "drizzle-orm/pg-core"

import { tagFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"
import { employeeTable } from "./employee"

export const employeeTagTable = pgTable(
	"employee_tag",
	tagFields(scopeTable, employeeTable),
	(table) => [primaryKey({ columns: [table.rowId, table.tag] })],
)
