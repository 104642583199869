import type { FakeEquipment } from "@/routes/_main+/resources+/equipment/fake-equipment"
import { CalendarIcon } from "lucide-react"

import { DetailsSectionsGrouping } from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

import { EquipmentPMHistorySection } from "./history"
import { EquipmentNextDueSection } from "./next-due"
import { EquipmentSubscribedPMSection } from "./subscribed-pm"

export const EquipmentMaintenanceSection: DetailsSectionComponent<
	FakeEquipment
> = ({ data: equipment }) => (
	<DetailsSectionsGrouping>
		<EquipmentNextDueSection />
		<EquipmentSubscribedPMSection />
		<EquipmentPMHistorySection />
	</DetailsSectionsGrouping>
)

EquipmentMaintenanceSection.label = "Maintenance"
EquipmentMaintenanceSection.icon = CalendarIcon
