import { MailIcon, SettingsIcon } from "lucide-react"
import { useFormContext } from "react-hook-form"

import type { NewCustomer } from "@/server/schemas"
import {
	AddEditSection,
	AddEditSectionHeading,
	AddEditSectionsGrouping,
} from "@/components/ui/add-edit"
import { Checkbox } from "@/components/ui/checkbox"
import { DetailsInfoGroup } from "@/components/ui/details-view"
import { Input, InputSlot } from "@/components/ui/input"
import { OverlineSmall } from "@/components/ui/typography"
import type { AddEditSectionDefinition } from "@/components/shared/add-edit"

export const CustomerAddEditSettings: AddEditSectionDefinition = {
	label: "Settings",
	icon: SettingsIcon,
	component: (
		<AddEditSectionsGrouping>
			<EmailNotifications />
			<Rates />
			<Accounting />
		</AddEditSectionsGrouping>
	),
}

function EmailNotifications() {
	const { register } = useFormContext<NewCustomer>()

	return (
		<AddEditSection>
			<AddEditSectionHeading>Email Notifications</AddEditSectionHeading>
			<div className="flex flex-col gap-1">
				<OverlineSmall>Send to</OverlineSmall>
				<Input placeholder={"Email"}>
					<InputSlot>
						<MailIcon className="text-foreground-weaker" />
					</InputSlot>
				</Input>
			</div>
			<div className="flex gap-2">
				<div className="flex grow flex-col gap-1">
					<OverlineSmall>Work order completion?</OverlineSmall>
					<Input
						{...register("notifyWorkOrderComplete")}
						placeholder={"Yes"}
					>
						<InputSlot>
							<MailIcon className="text-foreground-weaker" />
						</InputSlot>
					</Input>
				</div>
				<div className="flex grow flex-col gap-1">
					<OverlineSmall>Work order notes?</OverlineSmall>
					<Input
						{...register("notifyWorkOrderNotes")}
						placeholder={"Yes"}
					>
						<InputSlot>
							<MailIcon className="text-foreground-weaker" />
						</InputSlot>
					</Input>
				</div>
			</div>
		</AddEditSection>
	)
}

function Rates() {
	const { register } = useFormContext<NewCustomer>()

	return (
		<AddEditSection columned>
			<AddEditSectionHeading>Rates</AddEditSectionHeading>
			<DetailsInfoGroup className="col-span-full" label="Terms">
				<Input
					placeholder={"Terms"}
					{...register("billing.termsCode")}
				/>
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Standard Price">
				<Input
					placeholder={"Standard Price"}
					{...register("billing.standardPriceCode")}
				/>
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Flat Rate Price">
				<Input
					placeholder={"Flat Rate Price"}
					{...register("billing.flatRatePriceCode")}
					defaultValue={""}
				/>
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Labor Rate">
				<Input
					placeholder={"Labor Rate"}
					{...register("billing.laborRate")}
					defaultValue={""}
				/>
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Labor Markup %">
				<Input
					placeholder={"Labor Markup %"}
					{...register("billing.laborMarkupPercent")}
				/>
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Fuel Markup %">
				<Input
					placeholder={"Fuel Markup %"}
					{...register("billing.fuelMarkupPercent")}
				/>
			</DetailsInfoGroup>
			<div className="flex items-center gap-1">
				<input
					type="checkbox"
					{...register("billing.usePartsInvoiceSurcharge")}
				/>
				Parts Invoice Surcharge
			</div>
			<div className="flex items-center gap-1">
				<input
					type="checkbox"
					{...register("billing.useWorkOrderSurcharge")}
				/>
				Work Order Surcharge
			</div>
		</AddEditSection>
	)
}

function Accounting() {
	// const { register } = useFormContext<NewCustomer>()

	return (
		<AddEditSection>
			<AddEditSectionHeading>Accounting</AddEditSectionHeading>
			<div className="flex flex-col gap-1">
				<OverlineSmall>Taxes</OverlineSmall>
				<Input placeholder={"Taxes"}>
					<InputSlot side="end" className="text-foreground-weaker">
						$
					</InputSlot>
				</Input>
			</div>
			<div className="flex gap-2">
				<div className="flex grow flex-col gap-1">
					<OverlineSmall>Accounts receivable?</OverlineSmall>
					<Input placeholder={"Accounts receivable?"} />
				</div>
				<div className="flex grow flex-col gap-1">
					<OverlineSmall>Department</OverlineSmall>
					<Input placeholder={"Department"} />
				</div>
			</div>

			<div className="flex gap-2">
				<div className="flex grow flex-col gap-1">
					<OverlineSmall>Expense Category</OverlineSmall>
					<Input placeholder={"Expense Category"} />
				</div>
				<div className="flex grow flex-col gap-1">
					<OverlineSmall>Location</OverlineSmall>
					<Input placeholder={"Location"} />
				</div>
			</div>
		</AddEditSection>
	)
}
