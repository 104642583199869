import { trpcClient } from "@/trpc/client"

import type { DetailsViewComponent } from "@/components/shared/details/details-view"
import { DetailsViewContainer } from "@/components/shared/details/details-view"

import { EmployeeHistorySection } from "./sections/history"
import { EmployeeInfoSection } from "./sections/info"
import { EmployeeJobDetailsSection } from "./sections/job-details"
import { EmployeePageHeader } from "./sections/page-header"
import { EmployeePanelHeader } from "./sections/panel-header"
import { EmployeeSettingsSection } from "./sections/settings"

export const EmployeeDetailsView: DetailsViewComponent = (props) => {
	const { id: employeeId } = props
	const [result] = trpcClient.employees.getEmployeeById.useSuspenseQuery({
		id: parseInt(employeeId),
	})

	if (!result.success) {
		throw new Error("Failed to fetch employee")
	}

	return (
		<DetailsViewContainer
			data={result.value}
			headers={{
				page: EmployeePageHeader,
				panel: EmployeePanelHeader,
			}}
			sections={{
				info: EmployeeInfoSection,
				jobDetails: EmployeeJobDetailsSection,
				settings: EmployeeSettingsSection,
				history: EmployeeHistorySection,
			}}
		/>
	)
}
