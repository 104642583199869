import type { Employee } from "@/server/schemas"
import {
	DetailsInfoGroup,
	DetailsInfoGroupsWrapper,
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"

export function EmployeePersonalInfoSection({
	employee,
}: {
	employee: Employee
}) {
	return (
		<DetailsSection>
			<DetailsSectionHeading>Personal Info</DetailsSectionHeading>
			<DetailsInfoGroupsWrapper>
				<DetailsInfoGroup label="Phone 1">
					{/* Employee schema doesn't have a personal phone */}
					{employee.workPhone}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Email">
					{/* Employee schema doesn't have a personal email */}
					{employee.workEmail}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Address">
					{/* Employee schema doesn't have address fields yet */}
					2697 Westshore Ave, #43 Seattle, WA 98126
				</DetailsInfoGroup>
				<DetailsInfoGroup label="DOB">
					{employee.birthDate}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Emergency Contact">
					{employee.contacts.find(
						(contact) => contact.isEmergencyContact,
					)?.name ?? "N/A"}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Emergency Phone">
					{employee.contacts.find(
						(contact) => contact.isEmergencyContact,
					)?.phone ?? "N/A"}
				</DetailsInfoGroup>
			</DetailsInfoGroupsWrapper>
		</DetailsSection>
	)
}
