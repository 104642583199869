import type { FakeService } from "@/routes/_main+/admin+/services/fake-services"
import { EllipsisIcon } from "lucide-react"

import { Button } from "@/components/ui/button"
import { Chip } from "@/components/ui/chip"
import {
	DetailsContext,
	DetailsContextBar,
	DetailsTitle,
	DetailsTopRightActions,
} from "@/components/ui/details-view"
import type { DetailsHeaderContent } from "@/components/shared/details/details-view"

import { ServiceVariantSelection } from "./variant-selection"

export const ServicesPageHeader: DetailsHeaderContent<FakeService> = ({
	data: service,
}) => (
	<>
		<DetailsContextBar>
			<DetailsContext feature="services" />
			<DetailsTopRightActions>
				<Button variant="ghost" size="icon">
					<EllipsisIcon className="size-4" />
				</Button>
			</DetailsTopRightActions>
		</DetailsContextBar>
		<DetailsTitle className="mb-1 flex items-center gap-2">
			<div className="grow">{service.name}</div>
			{service.status.toLowerCase() == "inactive" && (
				<Chip variant="warning">Inactive</Chip>
			)}
		</DetailsTitle>
		<ServiceVariantSelection />
		<div className="mt-1 flex flex-wrap gap-1">
			{service.isDefault && <Chip variant="primary">Default</Chip>}
			{service.tags?.map((tag, i) => (
				<Chip key={`service-` + i}>{tag}</Chip>
			))}
		</div>
	</>
)
