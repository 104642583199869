import { EllipsisIcon, Package } from "lucide-react"

import type { Vendor } from "@/server/schemas"
import { Button } from "@/components/ui/button"
import {
	DetailsContext,
	DetailsContextBar,
	DetailsSubtitle,
	DetailsTitle,
	DetailsTopRightActions,
} from "@/components/ui/details-view"
import type { DetailsHeaderContent } from "@/components/shared/details/details-view"

import { VendorLinksSection } from "./links"
import { VendorTagsSection } from "./tags"

export const VendorPanelHeader: DetailsHeaderContent<Vendor> = ({
	data: vendor,
}) => (
	<>
		<DetailsContextBar>
			<DetailsContext feature="vendor" />
			<DetailsTopRightActions>
				<Button variant="ghost" size="icon">
					<EllipsisIcon className="size-4" />
				</Button>
			</DetailsTopRightActions>
		</DetailsContextBar>
		<div className="flex gap-3">
			<div className="flex size-[90px] items-center justify-center rounded-md bg-background-weakest text-foreground-weakest">
				<Package size={60} />
			</div>
			<div>
				<DetailsTitle>{vendor.name}</DetailsTitle>
				<DetailsSubtitle className="capitalize">
					{vendor.city}, {vendor.state}
				</DetailsSubtitle>
				<VendorTagsSection vendor={vendor} className="mt-2" />
			</div>
		</div>
		<VendorLinksSection
			vendor={vendor}
			className="my-5 flex-row flex-wrap gap-x-5"
		/>
	</>
)
