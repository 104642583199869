import type { FakePart } from "@/routes/_main+/resources+/parts/fake-parts"
import { PlusIcon, StoreIcon } from "lucide-react"

import type { Vendor } from "@/server/schemas"
import { Button } from "@/components/ui/button"
import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"
import { VendorDetailsCard } from "@/components/features/vendor/details/card"

export const PartVendorsSection: DetailsSectionComponent<FakePart> = ({
	data: part,
}) => {
	const fakeVendor: Vendor = {
		id: 0,
		avatarUrl: null,
		name: "Bertand's Bright Stuff",
		street1: "123 Main St",
		street2: null,
		city: "Salt Lake City",
		state: "UT",
		zip: "32321",
		accountCode: "002",
		notes: null,
		performanceRank: 1,
		email: "bill.smythe@gbt.com",
		phone: "801-555-1212",
		termsCode: null,
		contacts: [
			{
				id: 0,
				name: "Bill Smythe",
				email: "bill.smythe@gbt.com",
				phone: "801-555-1212",
				vendorId: 0,
				isRepresentative: true,
				title: "Contractor",
			},
		],
		metas: [],
		tags: [],
	}

	return (
		<DetailsSection>
			<DetailsSectionHeading>Vendors</DetailsSectionHeading>
			<VendorDetailsCard vendor={fakeVendor} />
			<Button
				variant="outline"
				className="mt-1.5 gap-1 border-dashed text-primary shadow-none"
			>
				<PlusIcon className="size-4" />
				Add Vendor
			</Button>
		</DetailsSection>
	)
}

PartVendorsSection.label = "Vendors"
PartVendorsSection.icon = StoreIcon
