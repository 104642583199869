import { FilterIcon, PlusIcon, SearchIcon } from "lucide-react"

import { usePageInfo } from "@/lib/hooks/use-page-info"
import { Button } from "@/components/ui/button"
import { Input, InputSlot } from "@/components/ui/input"
import {
	Queue,
	QueueActions,
	QueueBody,
	QueueHeader,
	QueueSubheader,
} from "@/components/ui/queue"
import { useAddEdit } from "@/components/shared/add-edit/provider"
import { ViewSwitcherDropdown } from "@/components/shared/layout/view-switcher"
import { Info } from "@/components/shared/page-info"

export function QueueView({ children }: { children?: React.ReactNode }) {
	const addEdits = useAddEdit()
	const { addEdit } = usePageInfo()
	return (
		<Queue>
			<QueueHeader>
				<Info />
				<QueueActions>
					<Button
						variant="ghost"
						size="icon"
						onClick={() => {
							if (addEdit) {
								addEdits.openDialog(addEdit)
							}
						}}
					>
						<PlusIcon className="size-6" />
					</Button>
					<ViewSwitcherDropdown />
				</QueueActions>
			</QueueHeader>
			<QueueSubheader className="flex gap-1 border-b px-3 py-2">
				<Input placeholder="Search...">
					<InputSlot>
						<SearchIcon />
					</InputSlot>
				</Input>
				<Button variant="ghost" size="icon">
					<FilterIcon className="size-6" />
				</Button>
			</QueueSubheader>
			<QueueBody>{children}</QueueBody>
		</Queue>
	)
}
