import { GlobeIcon, MailIcon, PhoneIcon } from "lucide-react"

import { cn } from "@/lib/utils/classnames"
import type { Vendor } from "@/server/schemas"
import { IconField } from "@/components/ui/icon-field"
import { Link } from "@/components/ui/link"

interface VendorLinksSectionProps extends React.HTMLAttributes<HTMLDivElement> {
	vendor: Vendor
}
export function VendorLinksSection({
	vendor,
	className,
}: VendorLinksSectionProps) {
	return (
		<div className={cn("flex flex-col gap-2", className)}>
			<IconField icon={GlobeIcon}>
				<Link to="/" className="p-0">
					addwebsite.com
				</Link>
			</IconField>
			<IconField icon={MailIcon}>
				<Link to="/" className="p-0">
					{vendor.email}
				</Link>
			</IconField>
			<IconField icon={PhoneIcon}>
				<Link to="/" className="p-0">
					{vendor.phone}
				</Link>
			</IconField>
		</div>
	)
}
