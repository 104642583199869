import { useMemo } from "react"
import { Columns3Icon, Ellipsis, GridIcon, PanelLeft } from "lucide-react"

import { useGlobalView } from "@/lib/hooks/use-global-view"
import { usePageUrlState } from "@/lib/hooks/use-page-url-state"
import { cn } from "@/lib/utils/classnames"

import { useLayoutContext } from "."
import { Button } from "../../ui/button"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuTrigger,
} from "../../ui/dropdown-menu"
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "../../ui/tooltip"

const VIEW_CONFIG = {
	grid: {
		label: "Grid",
		Icon: GridIcon,
	},
	board: {
		label: "Board",
		Icon: Columns3Icon,
	},
	queue: {
		label: "Queue",
		Icon: PanelLeft,
	},
} as const

interface ViewSwitcherDropdownProps {
	children?: React.ReactNode
}

export function ViewSwitcherDropdown({ children }: ViewSwitcherDropdownProps) {
	const { availableViews } = useLayoutContext()
	const { setView } = usePageUrlState()
	const { view: activeView } = useGlobalView()

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" size="icon">
					<Ellipsis className="size-5" />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="start">
				<DropdownMenuGroup>
					<DropdownMenuLabel>Select View</DropdownMenuLabel>
					{availableViews.map((view) => {
						const { label, Icon } = VIEW_CONFIG[view]
						const isActive = view === activeView
						return (
							<DropdownMenuItem
								key={view}
								onClick={() => setView(view)}
								className={cn(
									"w-full gap-2",
									isActive &&
										"bg-accent/70 text-accent-foreground",
									!isActive && "hover:bg-hover",
								)}
							>
								<Icon />
								{label}
							</DropdownMenuItem>
						)
					})}
				</DropdownMenuGroup>
				{children}
			</DropdownMenuContent>
		</DropdownMenu>
	)
}

export function ViewSwitcherButtons() {
	// Use individual selectors
	const availableViews = useLayoutContext().availableViews
	const { setView } = usePageUrlState()
	const { view: activeView } = useGlobalView()

	// Memoize the views to prevent unnecessary re-renders
	const viewButtons = useMemo(() => {
		if (availableViews.length <= 1) return null

		return availableViews.map((view) => {
			const { label, Icon } = VIEW_CONFIG[view]
			const isActive = activeView === view
			return (
				<Tooltip key={view}>
					<TooltipTrigger asChild>
						<Button
							variant={isActive ? "primary" : "ghost"}
							size="icon"
							onClick={() => setView(view)}
							aria-label={label}
						>
							<Icon className="size-6" />
						</Button>
					</TooltipTrigger>
					<TooltipContent side="bottom" sideOffset={8}>
						{label}
					</TooltipContent>
				</Tooltip>
			)
		})
	}, [availableViews, activeView, setView])

	return (
		<div className="flex gap-1">
			<TooltipProvider delayDuration={300}>{viewButtons}</TooltipProvider>
		</div>
	)
}
