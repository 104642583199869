"use client"

import { useState } from "react"
import { Check, ChevronsUpDown } from "lucide-react"

import { cn } from "@/lib/utils/classnames"
import { Button } from "@/components/ui/button"
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "@/components/ui/command"
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover"

const fakeVariants = [
	{
		value: "Mack Anthem 2019-2024",
		label: "Mack Anthem 2019-2024",
	},
	{
		value: "Mack Anthem 2025+",
		label: "Mack Anthem 2025+",
	},
]

export function ServiceVariantSelection() {
	const [open, setOpen] = useState(false)
	const [value, setValue] = useState(fakeVariants[0]?.value || "")

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					role="combobox"
					aria-expanded={open}
					className="w-full max-w-[350px] justify-between"
				>
					{value ?
						fakeVariants.find((variant) => variant.value === value)
							?.label
					:	"Select variant..."}
					<ChevronsUpDown className="ml-2 size-4 shrink-0 opacity-50" />
				</Button>
			</PopoverTrigger>
			<PopoverContent align="start" className="w-full max-w-[350px] p-0">
				<Command>
					<CommandInput placeholder="Search variant..." />
					<CommandList>
						<CommandEmpty>No variant found.</CommandEmpty>
						<CommandGroup>
							{fakeVariants.map((variant) => (
								<CommandItem
									key={variant.value}
									value={variant.value}
									onSelect={(currentValue) => {
										setValue(
											currentValue === value ? "" : (
												currentValue
											),
										)
										setOpen(false)
									}}
								>
									<Check
										className={cn(
											"mr-2 h-4 w-4",
											value === variant.value ?
												"opacity-100"
											:	"opacity-0",
										)}
									/>
									{variant.label}
								</CommandItem>
							))}
						</CommandGroup>
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	)
}
