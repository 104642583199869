import type { WorkOrder } from "@/routes/_main+/work+/work-orders/columns"
import { EllipsisIcon } from "lucide-react"

import { Button } from "@/components/ui/button"
import {
	DetailsContext,
	DetailsContextBar,
	DetailsSubtitle,
	DetailsTitle,
	DetailsTopRightActions,
} from "@/components/ui/details-view"
import type { DetailsHeaderContent } from "@/components/shared/details/details-view"

export const WorkOrderPageHeader: DetailsHeaderContent<WorkOrder> = ({
	data: workOrder,
}) => (
	<>
		<DetailsContextBar>
			<DetailsContext feature="workOrder" />
			<DetailsTopRightActions>
				<Button variant="ghost" size="icon">
					<EllipsisIcon className="size-4" />
				</Button>
			</DetailsTopRightActions>
		</DetailsContextBar>
		<section>
			<DetailsTitle>
				{workOrder.year} {workOrder.model}
			</DetailsTitle>
			<DetailsSubtitle>VIN: {workOrder.vin}</DetailsSubtitle>
		</section>
	</>
)
