import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion"
import { Button } from "@/components/ui/button"
import { DetailsSectionHeading } from "@/components/ui/details-view"
import { Link } from "@/components/ui/link"

export function VendorAttachmentsSection() {
	//TODO: Replace with real attachments
	return (
		<Accordion type="multiple">
			<AccordionItem value="attachments">
				<AccordionTrigger className="p-0">
					<DetailsSectionHeading>
						Attachments (1)
					</DetailsSectionHeading>
				</AccordionTrigger>
				<AccordionContent className="p-0">
					<Button variant="link" className="m-0 p-0">
						<Link to="/">bobs_parts_catalog_2024.pdf</Link>
					</Button>
				</AccordionContent>
			</AccordionItem>
		</Accordion>
	)
}
