import type { FakePart } from "@/routes/_main+/resources+/parts/fake-parts"
import { InfoIcon } from "lucide-react"

import {
	DetailsInfoGroup,
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"
import { Notes } from "@/components/shared/details/notes"

import { PartAlternatesSection } from "./alternates"

export const PartInfoSection: DetailsSectionComponent<FakePart> = ({
	data: part,
}) => (
	<DetailsSection>
		<DetailsSectionHeading>Info</DetailsSectionHeading>
		{part.notes && <Notes notes={[part.notes]} className="mb-1" />}
		<div className="mb-2 text-foreground-strong">{part.description}</div>
		<div className="grid grid-cols-2 gap-3">
			<DetailsInfoGroup label="Last Cost">
				${part.cost.toFixed(2)}
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Core Fee">
				{part.coreFee.toFixed(2)}
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Bar Code">{part.barCode}</DetailsInfoGroup>
			<DetailsInfoGroup label="MFG Number">
				{part.MFGPartNo}
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Unit of Measure">
				{part.unitOfMeasure}
			</DetailsInfoGroup>
		</div>

		<PartAlternatesSection />
	</DetailsSection>
)
PartInfoSection.label = "Info"
PartInfoSection.icon = InfoIcon
