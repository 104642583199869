import { createContext, forwardRef, useContext } from "react"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import { X } from "lucide-react"
import { tv } from "tailwind-variants"
import type { VariantProps } from "tailwind-variants"

import { cn } from "@/lib/utils/classnames"

import { Button } from "./button"

const Dialog = DialogPrimitive.Root

const DialogTrigger = DialogPrimitive.Trigger

const DialogPortal = DialogPrimitive.Portal

const DialogClose = DialogPrimitive.Close

const DialogOverlay = forwardRef<
	React.ElementRef<typeof DialogPrimitive.Overlay>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
	<DialogPrimitive.Overlay
		ref={ref}
		className={cn(
			"fixed inset-0 z-50 bg-black/[10%] data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
			className,
		)}
		{...props}
	/>
))
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

const dialogVariants = tv({
	base: "fixed left-1/2 top-1/2 z-50 max-h-svh -translate-x-1/2 -translate-y-1/2 gap-4 border border-border-weak bg-background p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:max-h-[85svh] sm:w-[calc(100%-8rem)]",
	variants: {
		size: {
			sm: "h-fit w-[calc(100%-4rem)] max-w-lg rounded-lg",
			md: "h-svh w-full max-w-screen-sm sm:h-fit sm:rounded-lg",
			lg: "h-svh w-full max-w-[900px] sm:h-[640px] sm:rounded-lg",
		},
	},
	defaultVariants: {
		size: "md",
	},
})

const DialogHideCloseContext = createContext<boolean | null>(null)
const DialogContent = forwardRef<
	React.ElementRef<typeof DialogPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> &
		VariantProps<typeof dialogVariants> & {
			hideClose?: boolean
		}
>(({ className, children, size = "md", hideClose = false, ...props }, ref) => (
	<DialogPortal>
		<DialogOverlay />
		<DialogHideCloseContext.Provider value={hideClose}>
			<DialogPrimitive.Content
				ref={ref}
				className={cn(dialogVariants({ size, className }))}
				{...props}
			>
				{children}
				{!hideClose && (
					<DialogPrimitive.Close asChild>
						<Button
							variant="ghost"
							size="icon"
							className="absolute right-4 top-4 disabled:pointer-events-none"
						>
							<X className="size-4" />
							<span className="sr-only">Close</span>
						</Button>
					</DialogPrimitive.Close>
				)}
			</DialogPrimitive.Content>
		</DialogHideCloseContext.Provider>
	</DialogPortal>
))
DialogContent.displayName = DialogPrimitive.Content.displayName

const DialogHeader = ({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) => {
	const hideClose = useContext(DialogHideCloseContext)
	return (
		<div
			className={cn(
				"flex flex-col space-y-1.5 text-center sm:text-left",
				!hideClose ? "pr-10" : "pr-0", // make room for the close button!
				className,
			)}
			{...props}
		/>
	)
}
DialogHeader.displayName = "DialogHeader"

const DialogFooter = ({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) => (
	<div
		className={cn(
			"flex flex-col-reverse gap-2 sm:flex-row sm:justify-end",
			className,
		)}
		{...props}
	/>
)
DialogFooter.displayName = "DialogFooter"

const DialogTitle = forwardRef<
	React.ElementRef<typeof DialogPrimitive.Title>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
	<DialogPrimitive.Title
		ref={ref}
		className={cn(
			"text-lg font-semibold leading-none tracking-tight text-foreground-strong",
			className,
		)}
		{...props}
	/>
))
DialogTitle.displayName = DialogPrimitive.Title.displayName

const DialogDescription = forwardRef<
	React.ElementRef<typeof DialogPrimitive.Description>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
	<DialogPrimitive.Description
		ref={ref}
		className={cn("text-sm text-foreground-weak", className)}
		{...props}
	/>
))
DialogDescription.displayName = DialogPrimitive.Description.displayName

export {
	Dialog,
	DialogTrigger,
	DialogPortal,
	DialogClose,
	DialogOverlay,
	DialogContent,
	DialogHeader,
	DialogFooter,
	DialogTitle,
	DialogDescription,
}
