import type { ReactNode } from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import type { LucideIcon } from "lucide-react"
import type { DefaultValues, FieldErrors } from "react-hook-form"
import { FormProvider, useForm } from "react-hook-form"
import type { ZodType } from "zod"
import { z } from "zod"

import type { Feature } from "@/config/features"
import { featureConfig } from "@/config/features"
import {
	AddEditBody,
	AddEditDialog,
	AddEditEditor,
	AddEditFooter,
	AddEditHeader,
	AddEditTitle,
} from "@/components/ui/add-edit"
import { Button } from "@/components/ui/button"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"

export type AddEditMode = "add" | "edit"

export interface AddEditSectionDefinition {
	label: string
	icon: LucideIcon
	component: ReactNode
}

export interface AddEditStateProps<TSchema extends ZodType> {
	open: boolean
	onOpenChange: (open: boolean) => void
	mode: AddEditMode
	initialValue?: DefaultValues<z.infer<TSchema>>
}

export type AddEditProps<TSchema extends ZodType> =
	AddEditStateProps<TSchema> & {
		summary: ReactNode
		sectionDefinitions: Record<string, AddEditSectionDefinition>
		submitHandlers: any
		schema: TSchema
		defaultValues: DefaultValues<z.infer<TSchema>>
		feature?: Feature
	}

export const AddEdit = <TSchema extends ZodType>({
	mode,
	open,
	onOpenChange,
	initialValue,
	sectionDefinitions,
	summary,
	submitHandlers,
	feature,
	schema,
	defaultValues,
}: AddEditProps<TSchema>) => {
	type FormShape = z.infer<TSchema>

	const methods = useForm({
		resolver: zodResolver(schema),
		defaultValues: initialValue ?? defaultValues,
	})

	const onSubmit = async (data: FormShape) => {
		await submitHandlers(data)
		onOpenChange(false)
	}

	const onError = (errors: FieldErrors<FormShape>) => {
		console.error("Form validation errors:", errors)
	}

	return (
		<FormProvider {...methods}>
			<Tabs defaultValue={Object.keys(sectionDefinitions)[0]}>
				<AddEditDialog open={open} onOpenChange={onOpenChange}>
					<form
						onSubmit={methods.handleSubmit(onSubmit, onError)}
						className="flex size-full flex-col"
					>
						<AddEditHeader>
							<AddEditTitle>
								{mode === "add" ? "Add New " : "Edit "}
								{feature ?
									featureConfig[feature].labels.singular
								:	"Item"}
							</AddEditTitle>
						</AddEditHeader>

						<AddEditBody>
							{summary}
							<AddEditEditor>
								<TabsList className="w-full">
									{Object.entries(sectionDefinitions).map(
										([key, Section]) => (
											<TabsTrigger
												key={key}
												value={key}
												className="grow gap-1"
											>
												<Section.icon className="size-6" />
												<span className="hidden @[60px]:block">
													{Section.label}
												</span>
											</TabsTrigger>
										),
									)}
								</TabsList>
								<div className="mt-1 size-full overflow-auto px-1 [scrollbar-width:thin]">
									{Object.entries(sectionDefinitions).map(
										([key, Section]) => (
											<TabsContent
												key={key}
												value={key}
												className="size-full p-4"
											>
												{Section.component}
											</TabsContent>
										),
									)}
								</div>
							</AddEditEditor>
						</AddEditBody>
						<AddEditFooter>
							<Button
								onClick={(e) => {
									e.preventDefault()
									onOpenChange(false)
								}}
								size="lg"
							>
								Cancel
							</Button>
							<Button
								type="submit"
								variant="primary"
								size="lg"
								//disabled={!methods.formState.isValid}
							>
								Save
							</Button>
						</AddEditFooter>
					</form>
				</AddEditDialog>
			</Tabs>
		</FormProvider>
	)
}
