import { boolean, integer, pgTable, serial, text } from "drizzle-orm/pg-core"

import { timestampFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"
import { employeeTable } from "./employee"

export const employeeContactTable = pgTable("employee_contact", {
	id: serial("id").primaryKey(),
	scopeId: integer("scope_id")
		.references(() => scopeTable.id, { onDelete: "cascade" })
		.notNull(),
	employeeId: integer("employee_id")
		.references(() => employeeTable.id, { onDelete: "cascade" })
		.notNull(),
	name: text("name").notNull(),
	phone: text("phone"),
	mobilePhone: text("mobile_phone"),
	email: text("email"),
	street1: text("street1"),
	street2: text("street2"),
	city: text("city"),
	state: text("state"),
	zip: text("zip"),
	contactRelationship: text("contact_relationship").notNull().default("self"),
	isEmergencyContact: boolean("is_emergency_contact")
		.notNull()
		.default(false),
	...timestampFields,
})
