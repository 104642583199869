import { trpcClient } from "@/trpc/client"
import { useDebounce } from "use-debounce"

import { CommandGroup } from "@/components/ui/command"

import { VendorSearchItem } from "./search-item"

export function VendorSearchPage({ search }: { search: string }) {
	const [debouncedSearch] = useDebounce(search, 300)
	const { data } = trpcClient.vendors.fullSearch.useQuery(
		{ query: debouncedSearch.trim() },
		{ placeholderData: (prev) => prev },
	)

	if (!data?.length) return null

	return (
		<CommandGroup heading="Vendors">
			{data.map((result) => (
				<VendorSearchItem key={result.item.id} {...result} />
			))}
		</CommandGroup>
	)
}
