import { Calendar, Wrench } from "lucide-react"

import { Chip } from "@/components/ui/chip"
import {
	DetailsCard,
	DetailsCardInfoLine,
	DetailsCardInfoLinesWrapper,
	DetailsCardOverline,
	DetailsCardSubtitle,
	DetailsCardTitle,
} from "@/components/ui/details-card"

//TODO: Type this
// interface PreventiveMaintenanceProps {}

//TODO: Implement this with real data
export function PreventiveMaintenanceCard() {
	/* { purchaseOrder }: PreventiveMaintenanceProps */
	return (
		<DetailsCard className="@container">
			<div className="flex justify-between">
				<DetailsCardOverline icon={Calendar}>
					PM Event
				</DetailsCardOverline>
				{/* <Button
				variant="ghost"
				size="icon"
			>
				<EllipsisIcon className="size-4" />
			</Button> */}
			</div>

			<div>
				<div className="flex justify-between">
					<DetailsCardTitle>TRK-PMA</DetailsCardTitle>
					<Chip variant="success">Scheduled</Chip>
				</div>
				<DetailsCardSubtitle>Every 20,000 miles</DetailsCardSubtitle>
			</div>
			<DetailsCardInfoLinesWrapper>
				<DetailsCardInfoLine icon={Wrench}>
					<div>5 Services</div>
					<div className="text-primary-text">+ TRK-PMA</div>
				</DetailsCardInfoLine>
			</DetailsCardInfoLinesWrapper>
		</DetailsCard>
	)
}
