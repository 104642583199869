import { integer, pgTable, serial, text } from "drizzle-orm/pg-core"

import { timestampFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"
import { customerTable } from "./customer"

// Customer Contact primary table
export const customerContactTable = pgTable("customer_contact", {
	id: serial("id").primaryKey(),
	scopeId: integer("scope_id")
		.references(() => scopeTable.id, { onDelete: "cascade" })
		.notNull(),
	customerId: integer("customer_id")
		.references(() => customerTable.id, { onDelete: "cascade" })
		.notNull(),
	name: text("name").notNull(),
	title: text("title").notNull(),
	phone: text("phone"),
	email: text("email"),
	role: text("role").notNull(),
	...timestampFields,
})
