import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import { getTableSelectColumns } from "@/lib/utils/data/validators"
import { customerAddressTable } from "@/server/tables/customer"

const customerAddressOmitFields = {
	scopeId: true,
	createdAt: true,
	updatedAt: true,
} as const
export const customerAddressSelectColumns = {
	...getTableSelectColumns(customerAddressTable, customerAddressOmitFields),
}

export const customerAddressSchema = createSelectSchema(
	customerAddressTable,
).omit(customerAddressOmitFields)
export type CustomerAddress = z.infer<typeof customerAddressSchema>

export const newCustomerAddressSchema = createInsertSchema(
	customerAddressTable,
).omit(customerAddressOmitFields)
export type NewCustomerAddress = z.infer<typeof newCustomerAddressSchema>
