import { InfoIcon } from "lucide-react"

import type { Location } from "@/server/schemas"
import {
	DetailsInfoGroup,
	DetailsInfoGroupsWrapper,
	DetailsSection,
	DetailsSectionHeading,
	DetailsSectionsGrouping,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"
import { Notes } from "@/components/shared/details/notes"

export const LocationInfoSection: DetailsSectionComponent<Location> = ({
	data: location,
}) => (
	<DetailsSectionsGrouping>
		<DetailsSection>
			{location.notes && (
				<Notes notes={[location.notes]} className="mb-3" />
			)}
			<DetailsSectionHeading>Details</DetailsSectionHeading>
			<DetailsInfoGroupsWrapper>
				<DetailsInfoGroup label="Warehouse ID">Main</DetailsInfoGroup>
				<DetailsInfoGroup label="Warehouse Name">
					{location.workstationName}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Address">
					{location.street1} {location.street2} <br />
					{location.city}, {location.state} {location.zip}
				</DetailsInfoGroup>
			</DetailsInfoGroupsWrapper>
		</DetailsSection>
		<DetailsSection>
			<DetailsSectionHeading>Account Codes</DetailsSectionHeading>
			<DetailsInfoGroupsWrapper>
				<DetailsInfoGroup label="Seg Account Code 1">
					{location.accountCode}
				</DetailsInfoGroup>
			</DetailsInfoGroupsWrapper>
		</DetailsSection>
	</DetailsSectionsGrouping>
)
LocationInfoSection.label = "Info"
LocationInfoSection.icon = InfoIcon
