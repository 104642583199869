import { InfoIcon, PlusIcon } from "lucide-react"
import { useFormContext } from "react-hook-form"

import type { Location } from "@/server/schemas"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { OverlineSmall } from "@/components/ui/typography"
import type { AddEditSectionDefinition } from "@/components/shared/add-edit"

export const LocationAddEditInfo: AddEditSectionDefinition = {
	label: "Info",
	icon: InfoIcon,
	component: (
		<div className="flex flex-col gap-4">
			<NameGroup />
			<AddressGroup />
			<AddFieldButton />
		</div>
	),
}

function NameGroup() {
	const { register } = useFormContext<Location>()
	return (
		<div className="grid grid-cols-8 gap-1">
			<div className="col-span-6">
				<OverlineSmall>Name</OverlineSmall>
				<Input {...register("name")} placeholder={"Warehouse 1"} />
			</div>
			<div className="col-span-2">
				<OverlineSmall>ID</OverlineSmall>
				<Input {...register("id")} placeholder={"WH1"} />
			</div>
		</div>
	)
}

function AddressGroup() {
	const { register } = useFormContext<Location>()
	return (
		<div className="">
			<OverlineSmall>Address</OverlineSmall>
			<section className="grid grid-cols-8 gap-1">
				<Input
					{...register("street1")}
					placeholder={"Street 1"}
					className="col-span-full"
				/>
				<Input
					{...register("street2")}
					placeholder={"Street 2"}
					className="col-span-full"
				/>
				<Input
					{...register("city")}
					placeholder={"City"}
					className="col-span-5"
				/>
				<Input
					{...register("state")}
					placeholder={"ST"}
					className="col-span-1"
				/>
				<Input
					{...register("zip")}
					placeholder={"Zip"}
					className="col-span-2"
				/>
			</section>
		</div>
	)
}

function AddFieldButton() {
	return (
		<Button
			className="w-full gap-2 border-dashed border-border text-info-text"
			variant="outline"
			onClick={() => {
				console.log("TODO: Add field")
			}}
		>
			<PlusIcon className="size-4" />
			Add Field
		</Button>
	)
}
