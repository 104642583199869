import { ArrowDown, ChevronDown } from "lucide-react"

import { Button } from "@/components/ui/button"
import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"

export function EquipmentPMHistorySection() {
	return (
		<DetailsSection>
			<DetailsSectionHeading>PM History</DetailsSectionHeading>
			<div className="flex">
				<div className="w-[65px]">11/10/24</div>
				<div className="grow font-bold">
					PM <span className="text-info">TRK-PMA: 5/5 Services</span>
				</div>
				<div className="italic text-foreground-weaker">40,810</div>
			</div>
			<div className="flex">
				<div className="w-[65px]">10/12/24</div>
				<div className="grow font-bold">
					PM <span className="text-info">TRK-PMA: 5/5 Services</span>
				</div>
				<div className="italic text-foreground-weaker">36,120</div>
			</div>
			<div className="flex">
				<div className="w-[65px]">09/18/24</div>
				<div className="grow font-bold">
					PM <span className="text-info">TRK-PMA: 5/5 Services</span>
				</div>
				<div className="italic text-foreground-weaker">31,125</div>
			</div>
			<Button
				className="w-full gap-1 text-sm text-info"
				variant="ghost"
				size="sm"
			>
				Show more
				<ChevronDown size={14} className="opacity-70" />
			</Button>
		</DetailsSection>
	)
}
