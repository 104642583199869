interface LogoProps extends React.SVGProps<SVGSVGElement> {
	color?: "white" | "default"
}

export const Logo: React.FC<LogoProps> = ({ color = "default", ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		viewBox="0 0 524 48"
		{...props}
	>
		<g>
			<polyline
				className={color === "white" ? "fill-white" : "fill-[#032080]"}
				points="271.5 47.4 523 47.4 518.8 41.4 273.1 41.4"
			/>
			<path
				className={color === "white" ? "fill-white" : "fill-[#032080]"}
				d="M283.4,1.6h30.2c6.8,0,8.3,2.9,8.3,8,0,9.1-5,13.2-11.5,13.2h-2.7l10.4,14.7h-18l-7.4-12.5-3.2,12.5h-15.3l9.2-35.9ZM294.5,19.1h6.9c3.5,0,5-2.6,5-4.9s-1.2-2.5-3.3-2.5h-6.7l-1.9,7.4Z"
			/>
			<path
				className={color === "white" ? "fill-white" : "fill-[#032080]"}
				d="M331.2,1.6h15.4l-9.8,35.9h-15.1l9.5-35.9Z"
			/>
			<path
				className={color === "white" ? "fill-white" : "fill-[#032080]"}
				d="M381.9,36.7c-5.2,1.3-13.3,1.9-17.5,1.9-9.2,0-18.3-.2-18.3-11.1s2-21.9,13.2-25.3c4.5-1.4,8.8-1.6,12.7-1.6,6.4,0,12.8.8,19.1,2.2l-2.9,10.6c-3.5-.4-7.1-.9-10.7-.9s-6,0-8.6.7c-4.6,1.3-6.7,7.2-6.7,11.8s2.8,4.6,6.4,4.6l2.6-9.4h15.3l-4.6,16.5Z"
			/>
			<path
				className={color === "white" ? "fill-white" : "fill-[#032080]"}
				d="M425.5,36.7c-5.2,1.3-13.3,1.9-17.5,1.9-9.2,0-18.3-.2-18.3-11.1s2-21.9,13.2-25.3c4.5-1.4,8.8-1.6,12.7-1.6,6.4,0,12.8.8,19.1,2.2l-2.9,10.6c-3.5-.4-7.1-.9-10.7-.9s-6,0-8.6.7c-4.6,1.3-6.7,7.2-6.7,11.8s2.8,4.6,6.4,4.6l2.6-9.4h15.3l-4.6,16.5Z"
			/>
			<path
				className={color === "white" ? "fill-white" : "fill-[#032080]"}
				d="M440.1,1.6h37.1l-3,11.4h-21.4l-1,4h21.4l-2,7.7h-21.4l-.9,3.2h21.4l-2.5,9.6h-37l9.3-35.9Z"
			/>
			<path
				className={color === "white" ? "fill-white" : "fill-[#032080]"}
				d="M482.4,1.6h29.7c6.8,0,8.3,2.9,8.3,8,0,9.1-5,13.2-11.5,13.2h-2.7l10.4,14.7h-18l-7.4-12.5-3.2,12.5h-15.3l9.8-35.9ZM493,19.1h6.9c3.5,0,5-2.6,5-4.9s-1.2-2.5-3.3-2.5h-6.7l-1.9,7.4Z"
			/>
			<path
				className="fill-[#ff003b]"
				d="M3.3,27.9c5.7.7,11.4,1.2,17.2,1.2s5.2,0,5.2-2.4-.9-2.1-9.9-2.1-9.7-1.6-9.7-7.2S10,3.3,16.1,1.6c3.4-1,8.2-1.1,12-1.1s13.1.3,20.5,1.4l-3.2,11.1c-4.9-.6-9.7-1.1-14.6-1.1s-7.4.9-7.4,2.6,1,1.9,8.9,1.9,10.8.3,10.8,7.8-1.3,6.7-2.4,8.4c-3.1,5-8.1,6-18.1,6s-14.9-.5-21.6-1.7l2.3-9.1Z"
			/>
			<path
				className="fill-[#ff003b]"
				d="M59.1,38.3c-6.9-.6-11.5-3-11.5-11.3s2.1-12.9,5.7-18.7c4.3-6.9,11.8-7.8,21-7.8s19.5.4,19.5,11.6-2.2,13.7-6.1,19.8c-2.9,4.6-8.7,6-14.5,6.5l7.3,9h-14.2l-7.2-9.1ZM66.1,16c-1.4,2.6-2.5,6.6-2.5,8.4,0,3.7,2.5,4.6,4.6,4.6,3.7,0,5.2-.7,6.7-3.4.6-1,2.5-4.9,2.5-8.9s-1.4-4.2-4.9-4.2-4.5.2-6.3,3.6Z"
			/>
			<path
				className="fill-[#ff003b]"
				d="M156.3,1.6h26.9v35.9h-15.5v-5.2h-12.7l-3.1,5.2h-17.2L156.3,1.6ZM167.7,10.7h-.1l-7.9,12.9h8v-12.9Z"
			/>
			<path
				className="fill-[#ff003b]"
				d="M195.2,1.6h29.7c6.8,0,8.3,2.9,8.3,8,0,9.1-5,13.2-11.5,13.2h-2.7l10.4,14.7h-18l-7.4-12.5-3.2,12.5h-15.3L195.2,1.6ZM205.8,19.1h6.9c3.5,0,5-2.6,5-4.9s-1.2-2.5-3.3-2.5h-6.7l-1.9,7.4Z"
			/>
			<path
				className="fill-[#ff003b]"
				d="M241.6,1.6h37.1l-3,11.4h-21.4l-1,4h21.4l-2,7.7h-21.4l-.9,3.2h21.4l-2.5,9.6h-37L241.6,1.6Z"
			/>
			<path
				className="fill-[#ff003b]"
				d="M134,28.1c-2.6,9.1-9.9,10.5-22.8,10.5s-16.2-.5-16.2-8.5.2-2.4,1.3-6.4l6.2-22.1h15.7l-5.9,21.2c-.4,1.4-.9,3.3-.9,4.1,0,1.9,1.3,2.6,3.2,2.6,2.8,0,4-1.6,4.7-4.1l6.7-23.8h15.4l-7.4,26.4Z"
			/>
			<polygon
				className="fill-[#ff003b]"
				points="56.3 41.4 1.9 41.4 1.9 47.4 61.2 47.4 56.3 41.4"
			/>
			<polygon
				className="fill-[#ff003b]"
				points="268.3 41.4 80.4 41.4 85.4 47.4 266.8 47.4 268.3 41.4"
			/>
		</g>
	</svg>
)

interface LogoSmallProps extends React.SVGProps<SVGSVGElement> {
	color?: "white" | "default"
}

export const LogoSmall: React.FC<LogoSmallProps> = ({
	color = "default",
	...props
}) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
		<path
			d="M197.9 51.3C196.3 39.7 190 31 167.8 31H94.4c1.6 2.9 1.7 6.3.3 9.4L82 68h48.5c8.9 0 12.6 5.6 10.2 14.2-2.4 8.8-8.6 12.8-18.6 12.8h-18.7l-16.2 17.6 30.6 50.4h67l-38.6-54c5.7 0 10.3 0 15-.5 16.1-1.5 27.3-9.7 33.2-24.8 4.1-10.5 4.8-21.5 3.2-32.4Z"
			style={{
				fill: color === "white" ? "#FFF" : "#032080",
			}}
		/>
		<path
			d="M104.6 73.5H73.5l16.2-35.4c1.5-3.3-.9-7.1-4.5-7.1H48.4c-3.9 0-7.5 2.3-9.1 5.8l-33 72.1c-1.5 3.3.9 7.1 4.5 7.1h30.3L24 173l84.2-91.1c3-3.2.7-8.4-3.7-8.4Z"
			style={{
				fill: "#ff003b",
			}}
		/>
	</svg>
)
