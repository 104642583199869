import { motion } from "motion/react"

import { cn } from "@/lib/utils/classnames"
import { LogoSmall } from "@/components/shared/logo"

import { useNavbar } from "../../context"

export const CollapsedLogo = ({ className }: { className?: string }) => {
	const { animations } = useNavbar()
	return (
		<motion.div
			{...animations.collapsedLogo}
			className={cn("shrink-0", className)}
		>
			<LogoSmall className={cn("mx-auto w-full max-w-10 dark:hidden")} />
			<LogoSmall
				color="white"
				className={cn("mx-auto hidden w-full max-w-10 dark:block")}
			/>
		</motion.div>
	)
}
