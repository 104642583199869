import { pgTable, primaryKey } from "drizzle-orm/pg-core"

import { metaFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"
import { customerTable } from "./customer"

export const customerMetaTable = pgTable(
	"customer_meta",
	metaFields(scopeTable, customerTable),
	(table) => [primaryKey({ columns: [table.field, table.rowId] })],
)
