import { useState } from "react"
import { InfoIcon, PlusIcon } from "lucide-react"
import { useFieldArray, useFormContext } from "react-hook-form"

import type {
	CustomerAddress,
	NewCustomerAddress,
	NewVendor,
} from "@/server/schemas"
import { AddEditSectionsGrouping } from "@/components/ui/add-edit"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { OverlineSmall } from "@/components/ui/typography"
import type { AddEditSectionDefinition } from "@/components/shared/add-edit"

export const VendorAddEditInfo: AddEditSectionDefinition = {
	label: "Info",
	icon: InfoIcon,
	component: (
		<AddEditSectionsGrouping>
			<NameGroup />
			<ContactInfoGroup />
			<AddressGroup />
			<ReturnAddressGroup />
			<AddFieldButton />
		</AddEditSectionsGrouping>
	),
}

function NameGroup() {
	const { register } = useFormContext<NewVendor>()
	return (
		<div className="grid grid-cols-8 gap-1">
			<div className="col-span-6">
				<OverlineSmall>Name</OverlineSmall>
				<Input {...register("name")} placeholder={"Acme Corporation"} />
			</div>
			<div className="col-span-2">
				<OverlineSmall>ID</OverlineSmall>
				<Input {...register("id")} placeholder={"ACME"} />
			</div>
		</div>
	)
}

function ContactInfoGroup() {
	const { register } = useFormContext<NewVendor>()
	return (
		<div className="flex flex-col gap-1">
			<div>
				<OverlineSmall>Website</OverlineSmall>
				{/* There is no website field in the schema */}
				<Input placeholder={"www.acmecompany.com"} />
			</div>
			<div>
				<OverlineSmall>Email</OverlineSmall>
				<Input
					{...register("email")}
					placeholder={"name@acmecompany.com"}
				/>
			</div>
			<div>
				<OverlineSmall>Phone</OverlineSmall>
				<Input {...register("phone")} placeholder={"(555)-123-4567"} />
			</div>
		</div>
	)
}

function AddressGroup() {
	const { control, register, watch, setValue } = useFormContext()
	const { fields, append, remove } = useFieldArray({
		control,
		name: "addresses",
	})

	const [addressBeingEdited, setAddressBeingEdited] = useState<{
		index: number
		data: CustomerAddress | null
	} | null>(null)
	const formValues = watch()

	const handleAddAddress = () => {
		const newAddress: NewCustomerAddress = {
			customerId: 0,
			street1: "",
			city: "",
			state: "",
			zip: "",
		}
		append(newAddress)
		const newIndex = fields.length
		setAddressBeingEdited({ index: newIndex, data: null })
	}

	return (
		<div className="">
			<OverlineSmall>Address</OverlineSmall>
			<section className="grid grid-cols-8 gap-1">
				<Input
					{...register("street1")}
					placeholder={"Street 1"}
					className="col-span-full"
				/>
				<Input
					{...register("street2")}
					placeholder={"Street 2"}
					className="col-span-full"
				/>
				<Input
					{...register("city")}
					placeholder={"City"}
					className="col-span-5"
				/>
				<Input
					{...register("state")}
					placeholder={"ST"}
					className="col-span-1"
				/>
				<Input
					{...register("zip")}
					placeholder={"Zip"}
					className="col-span-2"
				/>
			</section>
		</div>
	)
}

function ReturnAddressGroup() {
	//const { register } = useFormContext<NewVendor>()
	return (
		<div>
			<OverlineSmall>Return Address</OverlineSmall>
			{/* Theres no return address in the schema. */}
			<section className="grid grid-cols-8 gap-1">
				<Input placeholder={"Street 1"} className="col-span-full" />
				<Input placeholder={"Street 2"} className="col-span-full" />
				<Input placeholder={"City"} className="col-span-5" />
				<Input placeholder={"ST"} className="col-span-1" />
				<Input placeholder={"Zip"} className="col-span-2" />
			</section>
		</div>
	)
}

function AddFieldButton() {
	return (
		<Button
			className="w-full gap-2 border-dashed border-border text-info-text"
			variant="outline"
			onClick={() => {
				console.log("TODO: Add field")
			}}
		>
			<PlusIcon className="size-4" />
			Add Field
		</Button>
	)
}
