import { getTableSelectColumns } from "@/lib/utils/data/validators"
import { customerMetaTable } from "@/server/tables"

export const customerMetaSelectColumns = {
	...getTableSelectColumns(customerMetaTable, {
		scopeId: true,
		createdAt: true,
		updatedAt: true,
	}),
}
