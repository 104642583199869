import { useEffect, useState } from "react"

/**
 * Use this hook only for one-off screensize checks.
 * In all other cases, use the {@link useBreakpoint} hook instead.
 */
export function useMediaQuery(query: string): boolean {
	// Default to false on server to prevent hydration mismatches
	const [matches, setMatches] = useState(false)

	useEffect(() => {
		const media = window.matchMedia(query)

		// Initial check
		setMatches(media.matches)

		// Listen for changes
		const listener = (e: MediaQueryListEvent) => {
			setMatches(e.matches)
		}

		// Add listener
		media.addEventListener("change", listener)

		// Cleanup
		return () => media.removeEventListener("change", listener)
	}, [query])

	return matches
}
