import type { FakeService } from "@/routes/_main+/admin+/services/fake-services"
import { EllipsisIcon } from "lucide-react"

import { Button } from "@/components/ui/button"
import { Chip } from "@/components/ui/chip"
import {
	DetailsContext,
	DetailsContextBar,
	DetailsTitle,
	DetailsTopRightActions,
} from "@/components/ui/details-view"
import type { DetailsHeaderContent } from "@/components/shared/details/details-view"

import { ServiceVariantSelection } from "./variant-selection"

export const ServicesPanelHeader: DetailsHeaderContent<FakeService> = ({
	data: service,
}) => (
	<>
		<DetailsContextBar>
			<DetailsContext feature="services" />
			<DetailsTopRightActions>
				<Button variant="ghost" size="icon">
					<EllipsisIcon className="size-4" />
				</Button>
			</DetailsTopRightActions>
		</DetailsContextBar>
		<div className="flex flex-col">
			<DetailsTitle className="mb-1 flex items-center gap-2">
				<div className="grow">{service.name}</div>
				{service.status.toLowerCase() == "inactive" && (
					<Chip variant="warning">Inactive</Chip>
				)}
			</DetailsTitle>
			<ServiceVariantSelection />
			<div className="mb-2 mt-3 flex flex-wrap gap-1">
				{service.isDefault && <Chip variant="primary">Default</Chip>}
				{service.tags?.map((tag, i) => (
					<Chip key={`service-` + i}>{tag}</Chip>
				))}
			</div>
		</div>
		{/* <div className="mt-3 flex flex-wrap gap-x-5 gap-y-3 rounded-md border border-border-weak bg-background-weaker p-3">
			<FieldGroup label="Serv Grp">{service.group}</FieldGroup>
			<FieldGroup label="Serv Type">{service.type}</FieldGroup>
			<FieldGroup label="Serv Code">{service.code}</FieldGroup>
		</div> */}
	</>
)
