import { useView } from "@/routes/utils+/view-switch"

import { featureConfig } from "@/config/features"
import type { Feature } from "@/config/features"
import type { SchemaMap } from "@/config/views"
import type { ListView } from "@/components/shared/layout"

import { usePageInfo } from "./use-page-info"
import type { View } from "./use-page-url-state"

export const DEFAULT_PREFERRED_VIEW: Exclude<View, "board"> = "queue"
export const DEFAULT_BOARD_OVERRIDE: boolean = false

//Indicate whether the view is enabled or disabled in the URL's search parameters.
export const USE_VIEW_SEARCH_PARAM: boolean = false

/**
 * Retrieves and updates view preferences stored in a cookie
 *
 *  * Cookie Structure:
 * {
 *   boardOverride: boolean, default: false
 *   preferredView: "queue" | "grid", default: queue
 * }
 *
 *  * - `boardOverride`: A boolean flag that is set to `true` when the "board" view is selected by the user.
 * 						if 'true', the "board" view is activated, regardless of the user's preferred view.
 *
 * - `preferredView`: Contains either "queue" or "grid". This is the view selected if the boardOverride is 'false' or does not support "board"
 */
export const useGlobalView = () => {
	const { preferredView, boardOverride, setView } = useView()
	const { feature } = usePageInfo() as {
		feature: Extract<Feature, keyof SchemaMap>
	}
	const listViews = featureConfig[feature].listViews

	const availableViews = Array.from(
		new Set(Object.keys(listViews)),
	).sort() as View[]

	const boardAvailable = availableViews.includes("board")

	const view: View = boardAvailable && boardOverride ? "board" : preferredView

	const setBoardOverride = (preference: boolean) => {
		const updatedView = {
			boardOverride: preference,
			preferredView: preferredView,
		}
		setView(updatedView)
	}

	const setPreferredView = (view: Exclude<View, "board">) => {
		const updatedView = {
			boardOverride: boardOverride,
			preferredView: view,
		}
		setView(updatedView)
	}

	const revokeBoardOverride = (view: Exclude<View, "board">) => {
		const updatedView = {
			boardOverride: false,
			preferredView: view,
		}
		setView(updatedView)
	}

	const setGlobalView = (newView: View) => {
		if (newView === view) return
		if (newView === "board") {
			setBoardOverride(true)
		} else {
			if (boardAvailable) {
				revokeBoardOverride(newView)
			} else {
				setPreferredView(newView)
			}
		}
	}

	return {
		view,
		setGlobalView,
		availableViews,
	}
}
