import { useMemo } from "react"
import Fuse from "fuse.js"
import {
	BellIcon,
	BuildingIcon,
	HelpCircleIcon,
	KeyboardIcon,
	LogOutIcon,
	PackageIcon,
	SettingsIcon,
	SunIcon,
	UserIcon,
	UsersIcon,
} from "lucide-react"

import {
	CommandGroup,
	CommandItem,
	CommandShortcut,
} from "@/components/ui/command"

import type { PageType } from "."
import type { CommandData } from "./types"

interface QuickCommandsProps {
	search: string
	onNavigate: (page: PageType, shouldClearSearch?: boolean) => void
}

export function QuickCommands({ search, onNavigate }: QuickCommandsProps) {
	const commandsData: CommandData[] = useMemo(
		() => [
			// {
			// 	value: "toggle-theme",
			// 	label: "Toggle Theme",
			// 	icon: SunIcon,
			// 	keywords: ["theme", "dark"],
			// 	action: () => {
			// 		// TODO: Implement theme toggle
			// 	},
			// },
			// {
			// 	value: "notifications",
			// 	label: "Notifications",
			// 	icon: BellIcon,
			// 	keywords: ["bell", "alerts"],
			// 	action: () => {
			// 		// TODO: Implement notifications
			// 	},
			// },
			// {
			// 	value: "shortcuts",
			// 	label: "Keyboard Shortcuts",
			// 	icon: KeyboardIcon,
			// 	keywords: ["help", "hotkeys"],
			// 	action: () => {
			// 		// TODO: Implement keyboard shortcuts
			// 	},
			// },
			{
				value: "customers-search",
				label: "Search Customers",
				icon: UsersIcon,
				keywords: ["search", "customers"],
				action: () => {
					onNavigate("customers", true)
				},
			},
			{
				value: "employees-search",
				label: "Search Employees",
				icon: UserIcon,
				keywords: ["search", "employees"],
				action: () => {
					onNavigate("employees", true)
				},
			},
			{
				value: "locations-search",
				label: "Search Locations",
				icon: BuildingIcon,
				keywords: ["search", "locations"],
				action: () => {
					onNavigate("locations", true)
				},
			},
			{
				value: "vendors-search",
				label: "Search Vendors",
				icon: PackageIcon,
				keywords: ["search", "vendors"],
				action: () => {
					onNavigate("vendors", true)
				},
			},
			// {
			// 	value: "settings",
			// 	label: "Open Settings",
			// 	icon: SettingsIcon,
			// 	keywords: ["preferences", "config"],
			// 	action: () => {
			// 		// TODO: Implement settings
			// 	},
			// },
			// {
			// 	value: "logout",
			// 	label: "Sign Out",
			// 	icon: LogOutIcon,
			// 	keywords: ["exit", "logout"],
			// 	action: () => {
			// 		// TODO: Implement logout
			// 	},
			// },
			// {
			// 	value: "help",
			// 	label: "Help Center",
			// 	icon: HelpCircleIcon,
			// 	keywords: ["support", "docs", "documentation"],
			// 	action: () => {
			// 		// TODO: Implement help center
			// 	},
			// },
		],
		[onNavigate],
	)

	const fuseCommands = useMemo(
		() =>
			new Fuse(commandsData, {
				keys: [
					{ name: "label", weight: 2 },
					{ name: "keywords", weight: 1 },
					{ name: "value", weight: 1 },
				],
				threshold: 0.3,
			}),
		[commandsData],
	)

	const searchResults =
		search ?
			fuseCommands.search(search)
		:	commandsData.map((item) => ({ item }))

	if (!searchResults.length) return null

	return (
		<CommandGroup heading="Quick Commands">
			{searchResults.map(({ item }) => (
				<CommandItem key={item.value} onSelect={item.action}>
					{item.icon && <item.icon />}
					<span>{item.label}</span>
					{item.value === "shortcuts" && (
						<CommandShortcut>⌘K</CommandShortcut>
					)}
				</CommandItem>
			))}
		</CommandGroup>
	)
}
