import { Settings2Icon } from "lucide-react"

import type { Customer } from "@/server/schemas"
import {
	DetailsInfoGroup,
	DetailsInfoGroupsWrapper,
	DetailsSection,
	DetailsSectionHeading,
	DetailsSectionsGrouping,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

const CustomerSettingsSection: DetailsSectionComponent<Customer> = ({
	data: customer,
}) => {
	return (
		<DetailsSectionsGrouping>
			<DetailsSection>
				<DetailsSectionHeading>
					Email Notifications
				</DetailsSectionHeading>
				<DetailsInfoGroupsWrapper>
					<DetailsInfoGroup label="Send to">
						<div className="flex justify-between">
							<div>Bill Smith</div>
							<div className="lowercase text-foreground-weak">
								billsmith@abcdtrucking.com
							</div>
						</div>
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Work order completion?" inline>
						{customer.notifyWorkOrderComplete}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Work order notes?" inline>
						{customer.notifyWorkOrderNotes}
					</DetailsInfoGroup>
				</DetailsInfoGroupsWrapper>
			</DetailsSection>
			<DetailsSection>
				<DetailsSectionHeading>Rates</DetailsSectionHeading>
				<DetailsInfoGroupsWrapper>
					<DetailsInfoGroup label="Terms">
						{customer.billing?.termsCode}
					</DetailsInfoGroup>
					<div className="grid grid-cols-2 gap-3">
						<DetailsInfoGroup label="Standard Rate">
							{customer.billing?.standardPriceCode}
						</DetailsInfoGroup>
						<DetailsInfoGroup label="Flat Rate">
							{customer.billing?.flatRatePriceCode}
						</DetailsInfoGroup>
						<DetailsInfoGroup label="Labor Rate">
							{customer.billing?.laborRate}
						</DetailsInfoGroup>
						<DetailsInfoGroup label="Markup %">
							{customer.billing?.laborMarkupPercent}
						</DetailsInfoGroup>
					</div>
					<DetailsInfoGroup label="Fuel Markup %">
						{customer.billing?.fuelMarkupPercent}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Use Work Order Surcharge">
						{customer.billing?.useWorkOrderSurcharge}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Use Parts Invoice Surcharge">
						{customer.billing?.usePartsInvoiceSurcharge}
					</DetailsInfoGroup>
				</DetailsInfoGroupsWrapper>
			</DetailsSection>
			<DetailsSection>
				<DetailsSectionHeading>Accounting</DetailsSectionHeading>
				<DetailsInfoGroupsWrapper>
					<DetailsInfoGroup label="Taxes">
						{customer.tax1}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Accounts Receivable">
						{customer.accountsReceivable}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Department">
						<div className="text-foreground-strong">
							1000 - Fleet Maintenance
						</div>
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Expense Category">
						<div className="text-foreground-strong">
							001 - Sales Department
						</div>
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Location">
						<div className="text-foreground-strong">
							03 - North Region
						</div>
					</DetailsInfoGroup>
				</DetailsInfoGroupsWrapper>
			</DetailsSection>
		</DetailsSectionsGrouping>
	)
}

CustomerSettingsSection.label = "Settings"
CustomerSettingsSection.icon = Settings2Icon

export { CustomerSettingsSection }
