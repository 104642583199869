import type { WorkOrder } from "@/routes/_main+/work+/work-orders/columns"
import type { Table as TanstackTable } from "@tanstack/react-table"

import { cn } from "@/lib/utils/classnames"
import { Chip } from "@/components/ui/chip"
import { QueueItemRow, QueueItems } from "@/components/ui/queue"
import { QueueView } from "@/components/shared/queue"

export function WorkOrderQueue({
	listDataState,
}: {
	listDataState: TanstackTable<WorkOrder>
}) {
	return (
		<QueueView>
			<QueueItems
				listDataState={listDataState}
				renderItem={(item) => (
					<>
						<QueueItemRow>
							<span className="truncate font-bold">
								{`${item.year} ${item.model}`}
							</span>
							<Chip
								variant="outline"
								className={cn(
									"shrink-0 px-1.5",
									item.status == "Out of Service" &&
										"border-error-border/30 text-error",
									item.status == "Available" &&
										"border-success-border/30 text-success",
								)}
							>
								{item.status}
							</Chip>
						</QueueItemRow>
						<QueueItemRow variant="secondary">
							<span className="truncate">{item.driver}</span>
							<span className="opacity-70">{item.vin}</span>
						</QueueItemRow>
					</>
				)}
				onRowClick={(workOrder) => {
					listDataState.setRowSelection({
						[workOrder.id]: true,
					})
				}}
				isActive={(workOrder) =>
					listDataState
						.getSelectedRowModel()
						.rows.some((row) => row.id === workOrder.id.toString())
				}
			/>
		</QueueView>
	)
}
