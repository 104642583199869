import { SettingsIcon } from "lucide-react"
import { useFormContext } from "react-hook-form"

import type { NewVendor } from "@/server/schemas"
import {
	AddEditSection,
	AddEditSectionHeading,
	AddEditSectionsGrouping,
} from "@/components/ui/add-edit"
import { DetailsSectionHeading } from "@/components/ui/details-view"
import { Input } from "@/components/ui/input"
import { OverlineSmall } from "@/components/ui/typography"
import type { AddEditSectionDefinition } from "@/components/shared/add-edit"

export const VendorAddEditSettings: AddEditSectionDefinition = {
	label: "Settings",
	icon: SettingsIcon,
	component: (
		<AddEditSectionsGrouping>
			<SettingsGroup />
			<Accounting />
		</AddEditSectionsGrouping>
	),
}

function SettingsGroup() {
	const { register } = useFormContext<NewVendor>()
	return (
		<AddEditSection>
			<DetailsSectionHeading>Settings</DetailsSectionHeading>
			<div>
				<OverlineSmall>Performance Rank</OverlineSmall>
				<Input
					{...register("performanceRank")}
					placeholder={"Performance Rank"}
				/>
			</div>
		</AddEditSection>
	)
}

function Accounting() {
	const { register } = useFormContext<NewVendor>()

	return (
		<AddEditSection>
			<AddEditSectionHeading>Accounting</AddEditSectionHeading>
		</AddEditSection>
	)
}
