import { trpcClient } from "@/trpc/client"
import { useRevalidator } from "@remix-run/react"

import { customerFormSchema } from "@/server/schemas"
import type { NewCustomer } from "@/server/schemas"
import { AddEdit } from "@/components/shared/add-edit"
import type { AddEditStateProps } from "@/components/shared/add-edit"

import { CustomerAddEditContacts } from "./sections/contacts"
import { CustomerAddEditInfo } from "./sections/info"
import { CustomerAddEditSettings } from "./sections/settings"
import { CustomerAddEditSummary } from "./sections/summary"

type CustomerAddEditProps = AddEditStateProps<typeof customerFormSchema>
export function CustomerAddEdit({ ...props }: CustomerAddEditProps) {
	const { revalidate } = useRevalidator()
	const utils = trpcClient.useUtils()
	const { mutateAsync } = trpcClient.customers.addEditCustomer.useMutation({
		onSuccess: () => {
			utils.customers.getCustomerById.invalidate()
			revalidate()
		},
	})
	return (
		<AddEdit
			{...props}
			summary={<CustomerAddEditSummary />}
			sectionDefinitions={{
				info: CustomerAddEditInfo,
				contacts: CustomerAddEditContacts,
				settings: CustomerAddEditSettings,
			}}
			feature="customer"
			submitHandlers={mutateAsync}
			schema={customerFormSchema}
			defaultValues={defaultCustomer}
		/>
	)
}

const defaultCustomer: NewCustomer = {
	name: "",
	pmNotificationMethod: "email",
	notifyWorkOrderComplete: "yes",
	notifyWorkOrderNotes: "yes",
	tax1: "",
	accountsReceivable: false,
	accountCode: "0.0.0.0",
	billing: null,
	contacts: [],
	addresses: [],
	metas: [],
	tags: [],
}
