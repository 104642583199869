import {
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion"
import {
	DetailsInfoGroup,
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import { Overline } from "@/components/ui/typography"

export function EquipmentSpecsSection() {
	return (
		<AccordionItem value="specs">
			<AccordionTrigger className="px-0">
				<DetailsSectionHeading>Specifications</DetailsSectionHeading>
			</AccordionTrigger>
			<AccordionContent className="mb-5 p-0 pt-2">
				<DetailsSection columned>
					<DetailsInfoGroup label="Fuel type">
						Diesel
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Oil type">15W-40</DetailsInfoGroup>
					<DetailsInfoGroup label="Fuel card #">
						...2214
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Fuel group">DU</DetailsInfoGroup>
					<DetailsInfoGroup label="MPG">11</DetailsInfoGroup>
					<DetailsInfoGroup label="MPG last updated">
						10/23/2019
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Limit between fueling">
						1800 <Overline>miles</Overline>
					</DetailsInfoGroup>
					<DetailsInfoGroup label="EXT color">White</DetailsInfoGroup>
					<DetailsInfoGroup label="Vocation">
						Freight
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Engine test group #">
						12345
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Axles">3</DetailsInfoGroup>
					<DetailsInfoGroup label="Wheels">10</DetailsInfoGroup>
					<DetailsInfoGroup label="Brake type">Air</DetailsInfoGroup>
					<DetailsInfoGroup label="Suspension type">
						Air
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Equipment unit barcode">
						228218020395413021
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Specific gross weight">
						18,000 <Overline>lbs</Overline>
					</DetailsInfoGroup>
				</DetailsSection>
			</AccordionContent>
		</AccordionItem>
	)
}
