import { EllipsisIcon, Wrench } from "lucide-react"

import type { Employee } from "@/server/schemas"
import { Button } from "@/components/ui/button"
import {
	DetailsContext,
	DetailsContextBar,
	DetailsSubtitle,
	DetailsTitle,
	DetailsTopRightActions,
} from "@/components/ui/details-view"
import { Overline } from "@/components/ui/typography"
import { Tags } from "@/components/shared/details/tags"

import { EmployeeAvatar } from "./avatar"

export function EmployeePanelHeader({ data: employee }: { data: Employee }) {
	return (
		<>
			<DetailsContextBar>
				<DetailsContext feature="employee" />
				<DetailsTopRightActions>
					<Button variant="ghost" size="icon">
						<EllipsisIcon className="size-4" />
					</Button>
				</DetailsTopRightActions>
			</DetailsContextBar>
			<div className="flex flex-col gap-3">
				<div className="flex grow flex-col gap-1">
					<div className="flex gap-3">
						<div>
							<DetailsTitle className="flex items-center gap-1">
								{employee?.firstName + " " + employee?.lastName}
								{employee.isAdmin && (
									<div className="flex items-center gap-1">
										<Wrench
											size={16}
											className="translate-y-px"
										/>
									</div>
								)}
							</DetailsTitle>
							<DetailsSubtitle className="flex flex-wrap gap-x-1 gap-y-0.5 text-wrap capitalize">
								{employee.isAdmin && <div>Admin |</div>}
								<div>{employee?.title}</div>|
								<div className="italic text-foreground-weaker">
									{employee?.id}
								</div>
							</DetailsSubtitle>
							{employee.tags.length > 0 && (
								<Tags
									tags={employee.tags}
									className="mt-1.5 flex flex-wrap"
								/>
							)}
						</div>
					</div>
				</div>
				<div className="flex items-center gap-5">
					<EmployeeAvatar
						name={employee.firstName + " " + employee.lastName}
						img={`https://i.pravatar.cc/150?img=${employee.id % 50}`}
						className="size-[90px] shadow-none"
					/>
					<div className="flex max-w-[240px] grow flex-col gap-1 leading-tight">
						<div className="flex items-baseline justify-between gap-5">
							<Overline>Role</Overline>
							<div className="capitalize">{employee?.role}</div>
						</div>
						<div className="flex items-baseline justify-between gap-5">
							<Overline>Emp Type</Overline>
							<div className="capitalize">
								{employee?.employmentType}
							</div>
						</div>
						<div className="flex items-baseline justify-between gap-5">
							<Overline>Shift</Overline>
							M-F 9AM-5PM
						</div>
						<div className="flex items-baseline justify-between gap-5">
							<Overline>Hire Date</Overline>
							{employee?.hiredDate}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
