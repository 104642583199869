import type { FakeService } from "@/routes/_main+/admin+/services/fake-services"
import { Hammer } from "lucide-react"

import { Checkbox } from "@/components/ui/checkbox"
import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import { FieldGroup } from "@/components/ui/feild-group"
import { Label } from "@/components/ui/form"
import { Input, InputSlot } from "@/components/ui/input"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

export const ServiceLaborSection: DetailsSectionComponent<FakeService> = ({
	data: service,
}) => (
	<DetailsSection>
		<DetailsSectionHeading>Labor</DetailsSectionHeading>
		<div className="flex flex-col gap-5">
			<FieldGroup label="Estimated Labor" className="w-[130px]">
				<Input value={service?.estimatedLabor}>
					<InputSlot side="end" className="text-foreground-weaker">
						min
					</InputSlot>
				</Input>
			</FieldGroup>
			<div className="flex items-center gap-5">
				<FieldGroup label="Flat Labor Fee" className="w-[130px]">
					<Input value={service?.flatLaborFee}>
						<InputSlot side="start">$</InputSlot>
					</Input>
				</FieldGroup>
				<div className="mt-4 flex items-center gap-1.5">
					<Checkbox
						value="test"
						id="lf-use-by-def"
						checked={service?.isDefaultFlatLaborFee}
					/>
					<Label htmlFor="lf-use-by-def" className="cursor-pointer">
						Use flat fee by default
					</Label>
				</div>
			</div>
		</div>
	</DetailsSection>
)
ServiceLaborSection.label = "Labor"
ServiceLaborSection.icon = Hammer
