import type {
	DetailsViewComponent,
	MultiDetailsViewComponent,
} from "@/components/shared/details/details-view"
import { CustomerMultiDetailsView } from "@/components/features/customer/batch-actions"
import { CustomerDetailsView } from "@/components/features/customer/details"
import { EmployeeMultiDetailsView } from "@/components/features/employee/batch-actions"
import { EmployeeDetailsView } from "@/components/features/employee/details"
import { EquipmentMultiDetailsView } from "@/components/features/equipment/batch-actions"
import { EquipmentDetailsView } from "@/components/features/equipment/details"
import { LocationMultiDetailsView } from "@/components/features/location/batch-actions"
import { LocationDetailsView } from "@/components/features/location/details"
import { PartMultiDetailsView } from "@/components/features/part/batch-actions"
import { PartDetailsView } from "@/components/features/part/details"
import { ServicesMultiDetailsView } from "@/components/features/services/batch-actions"
import { ServicesDetailsView } from "@/components/features/services/details"
import { VendorMultiDetailsView } from "@/components/features/vendor/batch-actions"
import { VendorDetailsView } from "@/components/features/vendor/details"
import { WorkOrderMultiDetailsView } from "@/components/features/work-order/batch-actions"
import { WorkOrdersDetailView } from "@/components/features/work-order/details"

type DetailsViewConfigShape = Record<
	string,
	DetailsViewComponent | MultiDetailsViewComponent
>

export const DEFAULT_DETAILS_VIEW = "error" as const satisfies DetailsView

export const detailsViewConfig = {
	// Single-select views
	"customer": CustomerDetailsView,
	"employee": EmployeeDetailsView,
	"equipment": EquipmentDetailsView,
	"location": LocationDetailsView,
	"part": PartDetailsView,
	"services": ServicesDetailsView,
	"vendor": VendorDetailsView,
	"work-order": WorkOrdersDetailView,

	// Multi-select views
	"customer-multi": CustomerMultiDetailsView,
	"employee-multi": EmployeeMultiDetailsView,
	"equipment-multi": EquipmentMultiDetailsView,
	"location-multi": LocationMultiDetailsView,
	"part-multi": PartMultiDetailsView,
	"services-multi": ServicesMultiDetailsView,
	"vendor-multi": VendorMultiDetailsView,
	"work-order-multi": WorkOrderMultiDetailsView,

	// Error view
	"error": {} as DetailsViewComponent,
} as const satisfies DetailsViewConfigShape

export type DetailsView = keyof typeof detailsViewConfig

export type SingleDetailsView = {
	[K in keyof typeof detailsViewConfig]: (typeof detailsViewConfig)[K] extends (
		DetailsViewComponent
	) ?
		K
	:	never
}[keyof typeof detailsViewConfig]

export type MultiDetailsView = {
	[K in keyof typeof detailsViewConfig]: (typeof detailsViewConfig)[K] extends (
		MultiDetailsViewComponent
	) ?
		K
	:	never
}[keyof typeof detailsViewConfig]

// Asserts that a string is a valid DetailsView
export function isDetailsView(view: string): view is DetailsView {
	return Object.keys(detailsViewConfig).includes(view as DetailsView)
}
