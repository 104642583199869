import { useCallback, useId, useMemo, useState } from "react"
import type { DragEndEvent, DragOverEvent, DragStartEvent } from "@dnd-kit/core"
import {
	DndContext,
	DragOverlay,
	PointerSensor,
	pointerWithin,
	useDroppable,
	useSensor,
	useSensors,
} from "@dnd-kit/core"
import {
	arrayMove,
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import type { Table } from "@tanstack/react-table"
import type { LucideIcon } from "lucide-react"
import { MoreHorizontalIcon } from "lucide-react"

import { Badge } from "@/components/ui/badge"
import {
	Board,
	BoardBody,
	BoardColumn,
	BoardColumnBody,
	BoardColumnHeader,
} from "@/components/ui/board"
import { Button } from "@/components/ui/button"
import { PageHeader } from "@/components/ui/page"
import { Head } from "@/components/ui/typography"
import { ViewSwitcherButtons } from "@/components/shared/layout/view-switcher"

import { Info } from "../page-info"

interface BoardColumnsConfig<TData> {
	key: string
	title: string
	icon: LucideIcon
	matcher: (item: TData) => boolean
}

interface BoardCard<TData> {
	data: TData
	columnKey: string
	id: string
}

export function BoardView<TData>({
	listDataState,
	columns: columnConfig,
	renderCard,
}: {
	listDataState: Table<TData>
	columns: BoardColumnsConfig<TData>[]
	renderCard: (item: TData, isActive: boolean) => React.ReactNode
}) {
	const initialItems = useMemo(() => {
		return listDataState
			.getRowModel()
			.rows.map((item) => ({
				data: item.original,
				columnKey: columnConfig.find((col) =>
					col.matcher(item.original),
				)?.key,
				id: item.id.toString(),
			}))
			.filter(
				(item): item is BoardCard<TData> =>
					item.columnKey !== undefined,
			)
	}, [listDataState, columnConfig])

	const columns = useMemo(() => {
		return columnConfig.map((col) => ({
			id: col.key,
			header: col.title,
			icon: col.icon,
		}))
	}, [columnConfig])

	const [items, setItems] = useState<BoardCard<TData>[]>(initialItems)
	const [draggingItem, setDraggingItem] = useState<BoardCard<TData> | null>(
		null,
	)

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 5,
			},
		}),
	)

	const handleDragStart = useCallback(
		(event: DragStartEvent) => {
			setDraggingItem(
				items.find((item) => item.id === event.active.id) ?? null,
			)
		},
		[items],
	)

	const handleDragEnd = useCallback((event: DragEndEvent) => {
		setDraggingItem(null)

		const { active, over } = event
		if (!over) return

		const activeId = active.id
		const overId = over.id

		if (activeId === overId) return
	}, [])

	const handleDragOver = useCallback((event: DragOverEvent) => {
		const { active, over } = event
		if (!over) return

		const activeId = active.id
		const overId = over.id

		if (activeId === overId) return

		const isOverACard = over.data.current?.type === "card"

		if (isOverACard) {
			setItems((prevItems) => {
				const activeIndex = prevItems.findIndex(
					(t) => t.id === activeId,
				)
				const overIndex = prevItems.findIndex((t) => t.id === overId)

				if (
					prevItems[activeIndex]?.columnKey !=
					prevItems[overIndex]?.columnKey
				) {
					const newItems = [...prevItems]
					newItems[activeIndex]!.columnKey =
						prevItems[overIndex]!.columnKey
					return arrayMove(newItems, activeIndex, overIndex - 1)
				}

				return arrayMove(prevItems, activeIndex, overIndex)
			})
		}

		const isOverAColumn = over.data.current?.type === "column"

		if (isOverAColumn) {
			setItems((prevItems) => {
				const activeIndex = prevItems.findIndex(
					(t) => t.id === activeId,
				)
				const newItems = [...prevItems]
				newItems[activeIndex]!.columnKey = overId as string
				return newItems
			})
		}
	}, [])

	const boardId = useId()

	const renderColumns = useCallback(
		() =>
			columns.map((column) => {
				const IconComponent = column.icon
				const colItems = items.filter(
					(item) => item.columnKey === column.id,
				)
				return (
					<BoardColumn key={column.id}>
						<BoardColumnHeader>
							<div className="flex items-center gap-2">
								<IconComponent className="size-4" />
								<Head>{column.header}</Head>
								{items.length !== undefined && (
									<Badge
										variant="info"
										value={colItems.length.toString()}
									/>
								)}
							</div>
							<Button variant="ghost" size="icon">
								<MoreHorizontalIcon className="size-4" />
							</Button>
						</BoardColumnHeader>
						<DroppableColumnBody<TData>
							key={column.id}
							column={column}
							items={colItems}
							renderCard={renderCard}
							listDataState={listDataState}
						/>
					</BoardColumn>
				)
			}),
		[columns, items, renderCard, listDataState],
	)

	return (
		<DndContext
			id={boardId}
			sensors={sensors}
			collisionDetection={pointerWithin}
			onDragStart={handleDragStart}
			onDragOver={handleDragOver}
			onDragEnd={handleDragEnd}
		>
			<Board>
				<PageHeader>
					<Info />
					<ViewSwitcherButtons />
				</PageHeader>
				<BoardBody>{renderColumns()}</BoardBody>
			</Board>
			<DragOverlay>
				{draggingItem && (
					<div className="cursor-grabbing select-none bg-background">
						<div className="pointer-events-none">
							{renderCard(draggingItem.data, false)}
						</div>
					</div>
				)}
			</DragOverlay>
		</DndContext>
	)
}

function DroppableColumnBody<TData>({
	column,
	items,
	renderCard,
	listDataState,
}: {
	column: { id: string; header: string; icon: LucideIcon }
	items: BoardCard<TData>[]
	renderCard: (item: TData, isActive: boolean) => React.ReactNode
	listDataState: Table<TData>
}) {
	const itemIds = useMemo(() => items.map((item) => item.id), [items])

	const { setNodeRef } = useDroppable({
		id: column.id,
		data: {
			type: "column",
		},
	})

	return (
		<BoardColumnBody ref={setNodeRef}>
			<SortableContext
				id={column.id}
				items={itemIds}
				strategy={verticalListSortingStrategy}
			>
				{items.map((item) => {
					const isActive = listDataState
						.getSelectedRowModel()
						.rows.some((row) => row.id === item.id)
					return (
						<SortableCard key={item.id} id={item.id}>
							{renderCard(item.data, isActive)}
						</SortableCard>
					)
				})}
			</SortableContext>
		</BoardColumnBody>
	)
}

function SortableCard({
	id,
	children,
}: {
	id: string
	children: React.ReactNode
}) {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({
		id,
		data: {
			type: "card",
		},
	})

	const style = {
		transform: CSS.Translate.toString(transform),
		transition,
	}

	if (isDragging) {
		return (
			<div
				ref={setNodeRef}
				style={style}
				// initial={{ opacity: 0 }}
				// animate={{ opacity: 1, transition: { duration: 0.5 } }}
				className="w-full rounded outline outline-2 -outline-offset-2 outline-info"
			>
				<div className="pointer-events-none opacity-0">{children}</div>
			</div>
		)
	}

	return (
		<div ref={setNodeRef} style={style} {...attributes} {...listeners}>
			{children}
		</div>
	)
}
