import type { Table as TanstackTable } from "@tanstack/react-table"
import { Wrench } from "lucide-react"

import { getYearFromDate } from "@/lib/utils/date-formatting-helpers"
import type { Employee } from "@/server/schemas"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { QueueItemRow, QueueItems } from "@/components/ui/queue"
import { QueueView } from "@/components/shared/queue"

export function EmployeeQueue({
	listDataState,
}: {
	listDataState: TanstackTable<Employee>
}) {
	return (
		<QueueView>
			<QueueItems
				listDataState={listDataState}
				renderItem={(employee) => (
					<div className="flex items-center gap-2">
						<Avatar className="size-9">
							<AvatarImage
								src={`https://i.pravatar.cc/150?img=${employee.id % 50}`}
							/>
							<AvatarFallback>{`${employee.firstName.charAt(0)}${employee.lastName.charAt(0)}`}</AvatarFallback>
						</Avatar>
						<div className="flex grow flex-col gap-0.5">
							<QueueItemRow>
								<div className="truncate font-bold capitalize">
									{`${employee.firstName} ${employee.lastName}`}
								</div>
								{/* @TODO Employee doesnt have address yet 
									<div>City, State</div>
								 */}
								{employee.isAdmin && (
									<Wrench
										size={14}
										className="text-foreground-weak"
									/>
								)}
							</QueueItemRow>
							<QueueItemRow variant="secondary">
								<div className="capitalize">
									{employee.isAdmin && <span>Admin |</span>}{" "}
									{employee.employmentType}
								</div>
								<div>
									Since {getYearFromDate(employee.hiredDate)}
								</div>
							</QueueItemRow>
						</div>
					</div>
				)}
				onRowClick={(employee) => {
					listDataState.setRowSelection({
						[employee.id]: true,
					})
				}}
				isActive={(employee) =>
					listDataState
						.getSelectedRowModel()
						.rows.some((row) => row.id === employee.id.toString())
				}
			/>
		</QueueView>
	)
}
