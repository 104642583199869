import { trpcClient } from "@/trpc/client"
import { useRevalidator } from "@remix-run/react"
import { Ellipsis, User } from "lucide-react"

import type { Customer } from "@/server/schemas"
import { Button } from "@/components/ui/button"
import {
	DetailsContext,
	DetailsContextBar,
	DetailsSubtitle,
	DetailsTitle,
	DetailsTopRightActions,
} from "@/components/ui/details-view"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { useAddEdit } from "@/components/shared/add-edit/provider"
import type { DetailsHeaderContent } from "@/components/shared/details/details-view"
import { Tags } from "@/components/shared/details/tags"

export const CustomerPageHeader: DetailsHeaderContent<Customer> = ({
	data: customer,
}) => {
	const addEdits = useAddEdit()
	const { revalidate } = useRevalidator()
	const utils = trpcClient.useUtils()
	const { mutateAsync } = trpcClient.customers.deleteCustomer.useMutation({
		onSuccess: () => {
			utils.customers.getCustomerById.invalidate({ id: customer.id })
			revalidate()
		},
	})

	const handleDelete = async () => {
		await mutateAsync({ id: customer.id })
	}

	return (
		<>
			<DetailsContextBar>
				<DetailsContext feature="customer" />
				<DetailsTopRightActions>
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button variant="ghost" size="icon">
								<Ellipsis className="size-5" />
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent align="start">
							<DropdownMenuGroup>
								<DropdownMenuLabel>Settings</DropdownMenuLabel>
							</DropdownMenuGroup>
							<DropdownMenuItem
								onClick={() =>
									addEdits.openDialog("customer", customer)
								}
								className="p-2"
							>
								Edit
							</DropdownMenuItem>
							<DropdownMenuItem
								onClick={handleDelete}
								className="p-2"
							>
								Delete
							</DropdownMenuItem>
						</DropdownMenuContent>
					</DropdownMenu>
				</DetailsTopRightActions>
			</DetailsContextBar>
			<div className="flex gap-3">
				<div className="flex size-[90px] items-center justify-center rounded-md bg-background-weakest text-foreground-weakest">
					<User size={60} />
				</div>
				<div>
					<DetailsTitle>{customer.name}</DetailsTitle>
					<DetailsSubtitle className="flex flex-wrap gap-1.5 capitalize">
						<span>
							{(
								customer.addresses[0]?.city &&
								customer.addresses[0]?.state
							) ?
								`${customer.addresses[0]?.city}, ${customer.addresses[0]?.state}`
							:	"N/A"}
						</span>
						|
						<span className="italic text-foreground-weaker">
							{customer.id}
						</span>
					</DetailsSubtitle>
					<Tags tags={customer.tags} className="mt-2" />
				</div>
			</div>
		</>
	)
}
