import { PlusCircle } from "lucide-react"
import { AnimatePresence, motion } from "motion/react"

import { Popover, PopoverTrigger } from "@/components/ui/popover"

import { ShopWatchTeamViewActivityDropdown } from "./activity-dropdown"
import { useShopWatchTeamViewUser } from "./context"
import { TeamViewUserHeader } from "./header"
import { TeamViewUserTracking } from "./tracking"

export const TeamViewUser = ({
	user,
}: {
	user: { name: string; location: string }
}) => {
	const { selectedActivity, setIsListOpen, isListOpen } =
		useShopWatchTeamViewUser()

	return (
		<>
			<Popover open={isListOpen} onOpenChange={setIsListOpen}>
				<TeamViewUserHeader user={user} />
				<div className="relative flex grow flex-col p-2">
					{selectedActivity ?
						<AnimatePresence>
							<motion.div
								initial={{ y: 10, opacity: 1 }}
								animate={{ y: 0, opacity: 1 }}
								exit={{ y: 10, opacity: 1 }}
								transition={{
									type: "spring",
									stiffness: 450,
									damping: 25,
									mass: 1,
								}}
							>
								<TeamViewUserTracking />
							</motion.div>
						</AnimatePresence>
					:	<div className="mt-auto flex">
							<PopoverTrigger asChild>
								<motion.button
									onClick={() => setIsListOpen(true)}
									whileTap={{ scale: 0.98, y: 2 }} // Shrinks and moves slightly downward
									initial={{ scale: 1, y: 0 }}
									animate={{ scale: 1, y: 0 }}
									transition={{
										type: "spring",
										stiffness: 600,
										damping: 25,
									}}
									className="flex w-full items-center justify-around rounded border border-primary-weak/30 py-2 text-primary"
								>
									<div className="flex items-center">
										<PlusCircle
											size={18}
											className="mr-2"
										/>
										Track Activity
									</div>
								</motion.button>
							</PopoverTrigger>
						</div>
					}
				</div>
				<ShopWatchTeamViewActivityDropdown />
			</Popover>
		</>
	)
}
