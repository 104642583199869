import { useNavigate } from "@remix-run/react"
import type { FuseResultMatch } from "fuse.js"

import type { Customer } from "@/server/schemas"
import { Avatar, AvatarFallback } from "@/components/ui/avatar"
import { CommandItem } from "@/components/ui/command"

import { useCommandMenu } from "../../command-menu-context"
import { HighlightedText } from "../../highlighted-text"

export function CustomerSearchItem({
	item: customer,
	matches,
}: {
	item: Customer
	matches: readonly FuseResultMatch[] | undefined
}) {
	const navigate = useNavigate()
	const { setOpen } = useCommandMenu()

	const primaryAddress = customer.addresses[0]

	// Helper to check if a field has matches
	const hasMatch = (path: string) => matches?.some((m) => m.key === path)

	// Helper to check if any address field matches
	const hasAddressMatch = matches?.some((m) =>
		m.key?.startsWith("addresses."),
	)

	// Get all matched fields excluding name and address-related fields
	const hasNonAddressMatch = matches?.some(
		(m) => !m.key?.startsWith("addresses.") && m.key !== "name",
	)

	// Find the first matching contact
	const matchingContact = customer.contacts.find((contact) =>
		matches?.some(
			(m) =>
				(m.key === "contacts.name" && m.value === contact.name) ||
				(m.key === "contacts.email" && m.value === contact.email) ||
				(m.key === "contacts.phone" && m.value === contact.phone),
		),
	)

	const getAddressFieldPositions = (address: typeof primaryAddress) => {
		if (!address) return {}

		let currentPosition = 0
		const positions: { [key: string]: { start: number; end: number } } = {}

		const fields = [
			{ key: "addresses.street1", value: address.street1 },
			{ key: "addresses.street2", value: address.street2 },
			{ key: "addresses.city", value: address.city },
			{ key: "addresses.state", value: address.state },
			{ key: "addresses.zip", value: address.zip },
		].filter((field) => field.value) // Only include fields with values

		fields.forEach((field, index) => {
			positions[field.key] = {
				start: currentPosition,
				end: currentPosition + (field.value?.length ?? 0) - 1,
			}

			currentPosition += field.value?.length ?? 0
			// Add separator length only if it's not the last field
			if (index < fields.length - 1) {
				currentPosition += 2 // ", " = 2 characters
			}
		})

		return positions
	}

	return (
		<CommandItem
			className="flex items-center justify-between"
			value={customer.id.toString()}
			onSelect={() => {
				setOpen(false)
				navigate(
					`/resources/customers?details_type=customer&details_id=${customer.id}`,
				)
			}}
		>
			<div className="flex items-center gap-3">
				<Avatar>
					<AvatarFallback>{customer.name}</AvatarFallback>
				</Avatar>
				<div className="flex flex-col items-start gap-1">
					<HighlightedText
						text={customer.name}
						matches={matches}
						path="name"
						className="font-medium"
					/>
					<div className="flex flex-col text-sm text-foreground-weak">
						{/* Show billing ID if it matches */}
						{hasMatch("billingId") && customer.billingId && (
							<span>
								Billing ID:{" "}
								<HighlightedText
									text={customer.billingId.toString()}
									matches={matches}
									path="billingId"
									className="font-medium"
								/>
							</span>
						)}

						{/* Show account code if it matches */}
						{hasMatch("accountCode") && customer.accountCode && (
							<span>
								Account:{" "}
								<HighlightedText
									text={customer.accountCode}
									matches={matches}
									path="accountCode"
									className="font-medium"
								/>
							</span>
						)}

						{/* Show contact info if any contact field matches */}
						{matchingContact && (
							<div className="flex gap-2">
								{matchingContact.name &&
									hasMatch("contacts.name") && (
										<span>
											Contact:{" "}
											<HighlightedText
												text={matchingContact.name}
												matches={matches}
												path="contacts.name"
												className="font-medium"
											/>
										</span>
									)}
								{matchingContact.email &&
									hasMatch("contacts.email") && (
										<span>
											Email:{" "}
											<HighlightedText
												text={matchingContact.email}
												matches={matches}
												path="contacts.email"
												className="font-medium"
											/>
										</span>
									)}
								{matchingContact.phone &&
									hasMatch("contacts.phone") && (
										<span>
											Phone:{" "}
											<HighlightedText
												text={matchingContact.phone}
												matches={matches}
												path="contacts.phone"
												className="font-medium"
											/>
										</span>
									)}
							</div>
						)}

						{/* Show address only if there are no other matches or if address fields match */}
						{primaryAddress &&
							(!hasNonAddressMatch || hasAddressMatch) && (
								<span>
									Address:{" "}
									<HighlightedText
										text={[
											primaryAddress.street1,
											primaryAddress.street2,
											primaryAddress.city,
											primaryAddress.state,
											primaryAddress.zip,
										]
											.filter(Boolean)
											.join(", ")}
										matches={matches}
										paths={[
											"addresses.street1",
											"addresses.street2",
											"addresses.city",
											"addresses.state",
											"addresses.zip",
										]}
										fieldPositions={getAddressFieldPositions(
											primaryAddress,
										)}
										className="font-medium"
									/>
								</span>
							)}
					</div>
				</div>
			</div>
		</CommandItem>
	)
}
