import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import { getTableSelectColumns } from "@/lib/utils/data/validators"
import { vendorContactTable } from "@/server/tables"

const vendorContactOmitFields = {
	scopeId: true,
	createdAt: true,
	updatedAt: true,
} as const
export const vendorContactSelectColumns = {
	...getTableSelectColumns(vendorContactTable, vendorContactOmitFields),
}

export const vendorContactSchema = createSelectSchema(vendorContactTable).omit(
	vendorContactOmitFields,
)
export type VendorContact = z.infer<typeof vendorContactSchema>

export const newVendorContactSchema = createInsertSchema(
	vendorContactTable,
).omit(vendorContactOmitFields)
export type NewVendorContact = z.infer<typeof newVendorContactSchema>
