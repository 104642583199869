import { Pause, Play } from "lucide-react"

import { cn } from "@/lib/utils/classnames"
import { formatTimerFromSeconds } from "@/lib/utils/time-formatting-helpers"

import { useShopWatch } from "../context"
import { MockShopWatchActivities, MockShopWatchTasks } from "../mock-data"
import type { MockShopWatchActivity } from "../mock-data"

export const ShopWatchActivityDropdown = ({ ...props }) => {
	const {
		selectedActivity,
		time,
		isRunning,
		setSelectedActivity,
		setIsRunning,
		clearShopWatchTimers,
		setIsListOpen,
	} = useShopWatch()

	const selectItem = (item: MockShopWatchActivity) => {
		if (item.id == selectedActivity.id) return // do nothing if they clicked the current item

		// Select new item, reset timers, etc
		setSelectedActivity(item)
		clearShopWatchTimers()
		setIsListOpen(false)
	}

	return (
		<div
			className="flex max-h-[80vh] min-h-[200px] w-[320px] flex-col overflow-hidden rounded-lg text-foreground shadow transition-all ease-out"
			{...props}
		>
			<div className="flex items-center p-3">
				<div className="flex grow flex-col [&>*]:leading-tight">
					<div className="text-xs font-light uppercase tracking-widest text-foreground-weak">
						Shop Watch
					</div>
					<div className="text-lg font-bold">Track Activity</div>
				</div>
				{selectedActivity.id && (
					<button
						onClick={() => setIsRunning(!isRunning)}
						className={cn(
							"flex items-center gap-1 rounded bg-success-weak px-1.5 py-1 text-[11px] leading-none text-success-foreground",
							!isRunning && "bg-selected text-primary-weak",
							isRunning &&
								"bg-success-weak text-success-foreground",
						)}
					>
						<div>{formatTimerFromSeconds(time)}</div>
						{isRunning ?
							<Pause className="text-white" size={12} />
						:	<>
								<Play size={12} />
							</>
						}
					</button>
				)}
			</div>
			<div className="flex grow flex-col overflow-y-scroll border-t border-border-weak">
				<div className="sticky top-0 z-10 border-b border-border-weaker bg-background-weak p-3 py-1 text-sm uppercase tracking-wider dark:bg-background-weakest">
					Services
				</div>
				{MockShopWatchActivities.length > 0 ?
					<>
						{MockShopWatchActivities.map((item, index) => (
							<button
								onClick={() => selectItem(item)}
								key={"serv-" + index}
								className={cn(
									"flex cursor-pointer border-b border-border-weaker px-3 py-2 text-base transition-colors",
									item.id == selectedActivity?.id &&
										"bg-selected text-selected-foreground",
									item.id != selectedActivity?.id &&
										"hover:bg-hover",
									item.id == selectedActivity?.id &&
										!isRunning &&
										"bg-selected text-primary-weak",
									item.id == selectedActivity?.id &&
										isRunning &&
										"bg-success-weak text-success-foreground",
								)}
							>
								<div className="flex grow items-center">
									<div className="grow">
										<div className="flex justify-between gap-2">
											<div className="text-sm opacity-70">
												{item?.id}
											</div>
										</div>
										<div className="flex justify-between leading-tight">
											<div className="font-bold">
												{item?.name}
											</div>
										</div>
									</div>
									<div className="flex items-center gap-1 rounded bg-[rgba(0,0,0,.04)] px-1.5 py-1 text-[11px] leading-none">
										0:00:00
									</div>
								</div>
							</button>
						))}
					</>
				:	<div className="p-3 text-sm text-foreground-weaker">
						No items have been assigned
					</div>
				}

				{MockShopWatchTasks.length > 0 && (
					<>
						<div className="sticky top-0 z-10 border-b border-border-weaker bg-background-weak p-3 py-1 text-sm uppercase tracking-wider dark:bg-background-weakest">
							Other
						</div>
						{MockShopWatchTasks.map((item, index) => (
							<button
								onClick={() => selectItem(item)}
								key={"task-" + index}
								className={cn(
									"flex cursor-pointer border-b border-border-weaker px-3 py-1.5 text-base transition-colors",
									item.id == selectedActivity?.id &&
										"bg-selected text-selected-foreground",
									item.id != selectedActivity?.id &&
										"hover:bg-hover",
									item.id == selectedActivity?.id &&
										!isRunning &&
										"bg-selected text-primary-weak",
									item.id == selectedActivity?.id &&
										isRunning &&
										"bg-success-weak text-success-foreground",
								)}
							>
								<div className="flex flex-col text-left">
									<div className="text-sm uppercase opacity-70">
										{item?.type}
									</div>
									<div className="font-bold leading-tight">
										{item?.name}
									</div>
								</div>
							</button>
						))}
					</>
				)}
			</div>
		</div>
	)
}
