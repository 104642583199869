import { useCallback, useEffect, useMemo, useRef } from "react"
import { useSearchParams } from "@remix-run/react"
import { z } from "zod"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

import { isDetailsView } from "@/config/details-view"
import type { SingleDetailsView } from "@/config/details-view"
import type { PageId } from "@/config/pages"

import { USE_VIEW_SEARCH_PARAM, useGlobalView } from "./use-global-view"
import { usePageId } from "./use-page-id"

const FILTER_PREFIX = "filter_"
const DETAIL_TYPE_PARAM = "details_type"
const DETAIL_ID_PARAM = "details_id"
const DETAIL_TAB_PARAM = "details_tab"
const SORT_PARAM = "sort"
export const VIEW_PARAM = "view"

export const ViewSchema = z.enum(["queue", "grid", "board"])
export type View = z.infer<typeof ViewSchema>

export interface PageState {
	details: {
		type: SingleDetailsView
		id: string
		tab?: string
	} | null
	filters: Record<string, string>
	sort?: string
	view: View
}

interface PageStateStore {
	pages: Record<string, PageState>
	setPageState: (pageId: PageId, state: PageState) => void
}

// not exporting, setting to this store can only happen as a side effect to the usePageUrlState hook
const usePageStateStore = create<PageStateStore>()(
	immer((set) => ({
		pages: {},
		setPageState: (pageId, state) => {
			set((draft) => {
				draft.pages[pageId] = state
			})
		},
	})),
)

// Public hook that only exposes read access to the store
export const usePageState = (pageId: PageId) => {
	return usePageStateStore((state) => state.pages[pageId])
}

export function pageStateToSearchParams(state: PageState): URLSearchParams {
	const params = new URLSearchParams()

	// Set view in searchParams only if enabled
	if (state.view && USE_VIEW_SEARCH_PARAM) {
		params.set(VIEW_PARAM, state.view)
	}

	if (state.sort) {
		params.set(SORT_PARAM, state.sort)
	}

	// Add filters
	Object.entries(state.filters).forEach(([key, value]) => {
		params.set(`${FILTER_PREFIX}${key}`, value)
	})

	// Add details
	if (state.details?.type && state.details.id) {
		params.set(DETAIL_TYPE_PARAM, state.details.type)
		params.set(DETAIL_ID_PARAM, state.details.id)
		if (state.details.tab) {
			params.set(DETAIL_TAB_PARAM, state.details.tab)
		}
	}

	return params
}

/**
 * Hook: usePageUrlState
 *
 * Responsibilities:
 * - Reads current state from URL search params
 * - Provides setter methods to update them
 * - Syncs all changes to a Zustand store
 * - Leaves "shouldRevalidate" logic for loader to an external utility
 *
 * Example usage:
 *  const { state, setSort, setFilter, setDetailPanel } = usePageUrlState();
 */
export function usePageUrlState() {
	const [searchParams, setSearchParams] = useSearchParams()
	const pageId = usePageId()

	const { setPageState } = usePageStateStore()
	const { view, setGlobalView } = useGlobalView()

	/**
	 * Parse the search params into our unified PageState object.
	 */
	const parsedState: PageState = useMemo(() => {
		// Extract filters from search params
		const filters = Object.fromEntries(
			Array.from(searchParams.entries())
				.filter(([key]) => key.startsWith(FILTER_PREFIX))
				.map(([key, value]) => [key.replace(FILTER_PREFIX, ""), value]),
		)

		// Get detail panel params
		let detailType = searchParams.get(
			DETAIL_TYPE_PARAM,
		) as SingleDetailsView | null
		detailType = isDetailsView(detailType ?? "") ? detailType : "error"
		const detailId = searchParams.get(DETAIL_ID_PARAM)
		const detailTab = searchParams.get(DETAIL_TAB_PARAM)

		return {
			view: view,
			sort: searchParams.get(SORT_PARAM) || undefined,
			filters,
			details:
				detailType && detailId ?
					{
						type: detailType,
						id: detailId,
						tab: detailTab ?? undefined,
					}
				:	null,
		}
	}, [searchParams, view])

	// Sync the parsed state only on initial mount
	const isFirstRender = useRef(true)
	useEffect(() => {
		if (isFirstRender.current) {
			// Set view in searchParams only if enabled
			if (USE_VIEW_SEARCH_PARAM) {
				setSearchParams({
					...Object.fromEntries(searchParams),
					view: view,
				})
			}
			setPageState(pageId, parsedState)
			isFirstRender.current = false
		}
	}, [pageId, parsedState, setPageState])

	const setView = useCallback(
		(view: PageState["view"]) => {
			setGlobalView(view)
			// Set view in searchParams only if enabled
			if (USE_VIEW_SEARCH_PARAM) {
				const newSearchParams = new URLSearchParams(searchParams)
				if (view) {
					newSearchParams.set(VIEW_PARAM, view)
				} else {
					newSearchParams.delete(VIEW_PARAM)
				}
				setSearchParams(newSearchParams, { replace: true })
			}
			setPageState(pageId, { ...parsedState, view })
		},
		[pageId, parsedState, searchParams, setPageState, setSearchParams],
	)

	const setSort = useCallback(
		(sort: PageState["sort"]) => {
			const newSearchParams = new URLSearchParams(searchParams)
			if (sort) {
				newSearchParams.set(SORT_PARAM, sort)
			} else {
				newSearchParams.delete(SORT_PARAM)
			}
			setSearchParams(newSearchParams, { replace: true })
			setPageState(pageId, { ...parsedState, sort })
		},
		[pageId, parsedState, searchParams, setPageState, setSearchParams],
	)

	const setFilters = useCallback(
		(filters: PageState["filters"]) => {
			const newSearchParams = new URLSearchParams(searchParams)
			// Remove existing filters
			for (const key of Array.from(newSearchParams.keys())) {
				if (key.startsWith(FILTER_PREFIX)) {
					newSearchParams.delete(key)
				}
			}
			// Set new filters
			for (const [key, value] of Object.entries(filters)) {
				newSearchParams.set(`${FILTER_PREFIX}${key}`, value)
			}
			setSearchParams(newSearchParams, { replace: true })
			setPageState(pageId, { ...parsedState, filters })
		},
		[pageId, parsedState, searchParams, setPageState, setSearchParams],
	)

	const setDetails = useCallback(
		(details: PageState["details"]) => {
			const newSearchParams = new URLSearchParams(searchParams)
			if (details?.type && details.id) {
				newSearchParams.set(DETAIL_TYPE_PARAM, details.type)
				newSearchParams.set(DETAIL_ID_PARAM, details.id)
				if (details.tab) {
					newSearchParams.set(DETAIL_TAB_PARAM, details.tab)
				} else {
					newSearchParams.delete(DETAIL_TAB_PARAM)
				}
			} else {
				newSearchParams.delete(DETAIL_TYPE_PARAM)
				newSearchParams.delete(DETAIL_ID_PARAM)
				newSearchParams.delete(DETAIL_TAB_PARAM)
			}
			setSearchParams(newSearchParams, { replace: true })
			setPageState(pageId, { ...parsedState, details })
		},
		[pageId, parsedState, searchParams, setPageState, setSearchParams],
	)

	return { pageState: parsedState, setView, setSort, setFilters, setDetails }
}
