import React from "react"
import type { ZodType } from "zod"

import {
	customerFormSchema,
	employeeFormSchema,
	locationFormSchema,
	vendorFormSchema,
} from "@/server/schemas"
import type { AddEditStateProps } from "@/components/shared/add-edit"
import { CustomerAddEdit } from "@/components/features/customer/add-edit"
import { EmployeeAddEdit } from "@/components/features/employee/add-edit"
import { EquipmentAddEdit } from "@/components/features/equipment/add-edit"
import { LocationAddEdit } from "@/components/features/location/add-edit"
import { VendorAddEdit } from "@/components/features/vendor/add-edit"

interface ConfigEntry<T extends ZodType> {
	component: React.FunctionComponent<AddEditStateProps<any>>
	schema: T
}

export const addEditConfig = {
	customer: {
		component: CustomerAddEdit,
		schema: customerFormSchema,
	},
	// employee: {
	// 	component: EmployeeAddEdit,
	// 	schema: employeeFormSchema,
	// },
	equipment: {
		component: EquipmentAddEdit,
		schema: employeeFormSchema, // TODO: Add equipment form schema
	},
	location: {
		component: LocationAddEdit,
		schema: locationFormSchema,
	},
	vendor: {
		component: VendorAddEdit,
		schema: vendorFormSchema,
	},
} as const satisfies {
	[key: string]: ConfigEntry<ZodType>
}

export type AddEditConfigKeys = keyof typeof addEditConfig
