import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import {
	getTableSelectColumns,
	jsonBuildArray,
} from "@/lib/utils/data/validators"
import { locationTable } from "@/server/tables"

import { metaSchema, newMetaSchema, newTagSchema, tagSchema } from "../shared"
import {
	locationContactSchema,
	locationContactSelectColumns,
	newLocationContactSchema,
} from "./locationContact"
import { locationMetaSelectColumns } from "./locationMeta"
import { locationTagSelectColumns } from "./locationTag"

const locationOmitFields = {
	scopeId: true,
	createdAt: true,
	updatedAt: true,
	isActive: true,
	isDeleted: true,
	deletedDate: true,
} as const
export const locationSelectColumns = {
	...getTableSelectColumns(locationTable, locationOmitFields),
	contacts: jsonBuildArray(locationContactSelectColumns),
	metas: jsonBuildArray(locationMetaSelectColumns),
	tags: jsonBuildArray(locationTagSelectColumns),
}

export const locationSchema = createSelectSchema(locationTable)
	.omit(locationOmitFields)
	.extend({
		contacts: locationContactSchema.array(),
		metas: metaSchema.array(),
		tags: tagSchema.array(),
	})
export type Location = z.infer<typeof locationSchema>

export const newLocationSchema = createInsertSchema(locationTable)
	.omit(locationOmitFields)
	.extend({
		contacts: newLocationContactSchema.array(),
		metas: newMetaSchema.array(),
		tags: newTagSchema.array(),
	})
export type NewLocation = z.infer<typeof newLocationSchema>

// Add/Edit form schema
// TODO: Add validation
export const locationFormSchema = newLocationSchema
