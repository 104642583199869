import { useState } from "react"
import {
	CheckIcon,
	MailIcon,
	PhoneIcon,
	PlusIcon,
	UserIcon,
	UsersIcon,
	XIcon,
} from "lucide-react"
import { useFieldArray, useFormContext } from "react-hook-form"

import type {
	CustomerContact,
	NewCustomer,
	NewCustomerContact,
} from "@/server/schemas"
import { Button } from "@/components/ui/button"
import { Input, InputSlot } from "@/components/ui/input"
import { Overline } from "@/components/ui/typography"
import type { AddEditSectionDefinition } from "@/components/shared/add-edit"
import { ContactDetailsCard } from "@/components/features/contact/details/card"
import { LocationContactDetailsCard } from "@/components/features/location/contact-details-card"
import type { TempLocationContact } from "@/components/features/location/contact-details-card"

export const CustomerAddEditContacts: AddEditSectionDefinition = {
	label: "Contacts",
	icon: UsersIcon,
	component: (
		<div className="flex h-full flex-col">
			<Contacts />
		</div>
	),
}

function Contacts() {
	const { control, register, watch, setValue } = useFormContext<NewCustomer>()
	const { fields, append, remove } = useFieldArray({
		control,
		name: "contacts",
	})

	const [contactBeingEdited, setContactBeingEdited] = useState<{
		index: number
		data: CustomerContact | null
	} | null>(null)
	const formValues = watch()

	// Start editing a contact
	// const handleContactEdit = (index: number) => {
	// 	setContactBeingEdited({ index, data: formValues.contacts[index] })
	// }

	const handleSaveContact = () => {
		setContactBeingEdited(null)
	}

	// Cancel editing and restore previous data
	const handleCancelContactEdit = () => {
		if (!contactBeingEdited) return
		if (contactBeingEdited.data === null) {
			remove(contactBeingEdited.index)
		} else
			setValue(
				`contacts.${contactBeingEdited.index}`,
				contactBeingEdited.data,
			)

		setContactBeingEdited(null)
	}

	const handleAddContact = () => {
		const newContact: NewCustomerContact = {
			name: "",
			email: "",
			phone: "",
			title: "",
			customerId: 0,
			role: "",
		}
		append(newContact)
		const newIndex = fields.length
		setContactBeingEdited({ index: newIndex, data: null })
	}

	const isContactFormValid = () => {
		if (!contactBeingEdited) return true
		const contactToCheck =
			formValues.contacts[contactBeingEdited.index ?? -1]
		return (
			contactToCheck &&
			contactToCheck.name &&
			contactToCheck.email &&
			contactToCheck.phone &&
			contactToCheck.title
		)
	}

	return (
		<div className="my-4 flex flex-col gap-4">
			{fields.map((field, index) => (
				<div key={field.id}>
					{contactBeingEdited?.index === index ?
						<div className="flex flex-col gap-2 rounded-md border-2 border-focus p-3 pb-4">
							<div className="flex items-center justify-between">
								<div className="flex items-center gap-2">
									<UserIcon className="size-4 text-foreground-weak" />
									<Overline>Contact</Overline>
								</div>
								<div className="flex gap-2">
									<Button
										variant="outline"
										size="icon"
										onClick={() =>
											handleCancelContactEdit()
										}
									>
										<XIcon className="size-4" />
									</Button>
									<Button
										variant="primary"
										size="icon"
										onClick={() => handleSaveContact()}
										disabled={!isContactFormValid()}
									>
										<CheckIcon className="size-4" />
									</Button>
								</div>
							</div>

							<div>
								<Overline>Name</Overline>
								<Input
									{...register(`contacts.${index}.name`)}
									placeholder={"Name"}
								/>
							</div>
							<div>
								<Overline>Role / Title</Overline>
								<Input
									{...register(`contacts.${index}.title`)}
									placeholder={"Role/Title"}
								/>
							</div>
							<div className="flex flex-col">
								<Overline>Tags</Overline>
								<Button
									variant="outline"
									size="none"
									className="w-fit border-dashed text-xs uppercase"
								>
									Add Tag
								</Button>
							</div>
							<div>
								<Overline>Email</Overline>
								<Input
									{...register(`contacts.${index}.email`)}
									placeholder={"Email"}
								>
									<InputSlot>
										<MailIcon className="text-foreground" />
									</InputSlot>
								</Input>
							</div>
							<div>
								<Overline>Phone</Overline>
								<Input
									{...register(`contacts.${index}.phone`)}
									placeholder={"Phone"}
								>
									<InputSlot>
										<PhoneIcon className="text-foreground" />
									</InputSlot>
								</Input>
							</div>
						</div>
					:	<div>{formValues.contacts[index]?.name}</div>}
				</div>
			))}
			<Button
				className="w-full gap-2 border-dashed border-border text-info-text"
				variant="outline"
				onClick={() => handleAddContact()}
				disabled={contactBeingEdited !== null}
			>
				<PlusIcon className="size-4" />
				Add Contact
			</Button>
		</div>
	)
}

// function AddEditContactForm() {}
