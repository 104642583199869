import { vendorFormSchema } from "@/server/schemas"
import { AddEdit } from "@/components/shared/add-edit"
import type { AddEditStateProps } from "@/components/shared/add-edit"

import { VendorAddEditContacts } from "./sections/contacts"
import { VendorAddEditInfo } from "./sections/info"
import { VendorAddEditSettings } from "./sections/settings"
import { VendorAddEditSummary } from "./sections/summary"

type VendorAddEditProps = AddEditStateProps<typeof vendorFormSchema>

export function VendorAddEdit({ ...props }: VendorAddEditProps) {
	return (
		<AddEdit
			{...props}
			summary={<VendorAddEditSummary />}
			sectionDefinitions={{
				info: VendorAddEditInfo,
				contacts: VendorAddEditContacts,
				settings: VendorAddEditSettings,
			}}
			submitHandlers={{
				add: (data) => console.log("Adding vendor"),
				edit: (data) => console.log("Editing vendor"),
			}}
			feature="vendor"
			schema={vendorFormSchema}
		/>
	)
}
