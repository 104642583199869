// import { Bolt, Plus } from "lucide-react"

// import {
// 	Accordion,
// 	AccordionContent,
// 	AccordionItem,
// 	AccordionTrigger,
// } from "@/components/ui/accordion"
// import { Button } from "@/components/ui/button"
// import { Checkbox } from "@/components/ui/checkbox"
// import {
// 	DetailsSection,
// 	DetailsSectionHeading,
// } from "@/components/ui/details-view"
// import { FieldGroup } from "@/components/ui/feild-group"
// import { Label } from "@/components/ui/form"
// import { Input, InputSlot } from "@/components/ui/input"
// import { PartDetailsCard } from "@/components/features/part/details/card"

// export function ServicePartsSection() {
// 	return (
// 		<DetailsSection>
// 			<Accordion type="multiple">
// 				<AccordionItem value="operator-inspections">
// 					<AccordionTrigger className="px-0">
// 						<DetailsSectionHeading>
// 							Parts
// 							<span className="font-normal text-foreground-weaker">
// 								(5)
// 							</span>
// 						</DetailsSectionHeading>
// 					</AccordionTrigger>
// 					<AccordionContent className="mb-5 flex flex-col gap-2 p-0 pt-2">
// 						<div className="mb-3 flex items-center gap-5">
// 							<FieldGroup
// 								label="Flat Parts Fee"
// 								className="w-[130px]"
// 							>
// 								<Input placeholder={""}>
// 									<InputSlot side="start">$</InputSlot>
// 								</Input>
// 							</FieldGroup>
// 							<div className="mt-4 flex items-center gap-1.5">
// 								<Checkbox value="test" id="use-by-def" />
// 								<Label
// 									htmlFor="use-by-def"
// 									className="cursor-pointer"
// 								>
// 									Use flat fee by default
// 								</Label>
// 							</div>
// 						</div>
// 						{Array.from({ length: 5 }, (_, i) => (
// 							<PartDetailsCard key={`service-part-` + i} />
// 						))}
// 						<Button variant="placeholder" className="mt-3 gap-2">
// 							<Plus size={18} /> Add Part
// 						</Button>
// 					</AccordionContent>
// 				</AccordionItem>
// 			</Accordion>
// 		</DetailsSection>
// 	)
// }

import type { FakeService } from "@/routes/_main+/admin+/services/fake-services"
import { Bolt, Plus } from "lucide-react"

import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion"
import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import { FieldGroup } from "@/components/ui/feild-group"
import { Label } from "@/components/ui/form"
import { Input, InputSlot } from "@/components/ui/input"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"
import { PartDetailsCard } from "@/components/features/part/details/card"

export const ServicePartsSection: DetailsSectionComponent<FakeService> = ({
	data: service,
}) => (
	<DetailsSection>
		<Accordion type="multiple">
			<AccordionItem value="operator-inspections">
				<AccordionTrigger className="px-0">
					<DetailsSectionHeading>
						Parts
						<span className="font-normal text-foreground-weaker">
							(5)
						</span>
					</DetailsSectionHeading>
				</AccordionTrigger>
				<AccordionContent className="mb-5 flex flex-col gap-2 p-0 pt-2">
					<div className="mb-3 flex items-center gap-5">
						<FieldGroup
							label="Flat Parts Fee"
							className="w-[130px]"
						>
							<Input value={service?.flatPartsFee}>
								<InputSlot side="start">$</InputSlot>
							</Input>
						</FieldGroup>
						<div className="mt-4 flex items-center gap-1.5">
							<Checkbox
								value="test"
								id="fpf-use-by-def"
								checked={service?.isDefaultFlatPartsFee}
							/>
							<Label
								htmlFor="fpf-use-by-def"
								className="cursor-pointer"
							>
								Use flat fee by default
							</Label>
						</div>
					</div>
					{Array.from({ length: 5 }, (_, i) => (
						<PartDetailsCard key={`service-part-` + i} />
					))}
					<Button variant="placeholder" className="mt-3 gap-2">
						<Plus size={18} /> Add Part
					</Button>
				</AccordionContent>
			</AccordionItem>
		</Accordion>
	</DetailsSection>
)
ServicePartsSection.label = "Parts"
ServicePartsSection.icon = Bolt
