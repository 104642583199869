import type { WorkOrder } from "@/routes/_main+/work+/work-orders/columns"
import { Composer, Thread } from "@liveblocks/react-ui"
import {
	ClientSideSuspense,
	RoomProvider,
	useThreads,
} from "@liveblocks/react/suspense"
import { MessageCircleIcon } from "lucide-react"

import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

export const WorkOrderMessagesSection: DetailsSectionComponent<WorkOrder> = ({
	data: workOrder,
}) => {
	const roomId = `work-order-${workOrder.id}`

	return (
		<RoomProvider id={roomId}>
			<ClientSideSuspense fallback={<div>Loading...</div>}>
				{() => <MessagesContent workOrder={workOrder} />}
			</ClientSideSuspense>
		</RoomProvider>
	)
}

const MessagesContent = ({ workOrder }: { workOrder: WorkOrder }) => {
	const { threads } = useThreads()

	return (
		<div className="flex flex-col">
			<div className="flex-1 space-y-4 overflow-y-auto">
				{threads.map((thread) => (
					<Thread
						key={thread.id}
						thread={thread}
						// className={cn(
						// 	"rounded-lg px-2 py-3",
						// 	"bg-background-weak",
						// )}
					/>
				))}
			</div>

			<div className="pt-6">
				<Composer
					// className={cn(
					// 	"flex gap-2",
					// 	"[&_.lb-composer]:-mx-2 [&_.lb-composer]:rounded-full",
					// 	"[&_.lb-composer-input]:rounded-full",
					// 	"[&_.lb-composer-button]:rounded-full",
					// )}
					metadata={{
						workOrderId: workOrder.id,
					}}
				/>
			</div>
		</div>
	)
}

WorkOrderMessagesSection.label = "Messages"
WorkOrderMessagesSection.icon = MessageCircleIcon
