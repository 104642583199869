import { tv } from "tailwind-variants"

import { focusRing } from "../utils"

export interface ChipProps extends React.HTMLAttributes<HTMLDivElement> {
	variant?: "primary" | "outline" | "info" | "success" | "warning" | "error"
}

export const chipClasses = tv({
	base: [
		"inline-flex select-none items-center rounded-md border px-2 py-0.5 text-xs font-semibold transition-colors",
		focusRing,
	],
	variants: {
		variant: {
			primary:
				"border-transparent bg-primary text-primary-foreground shadow",
			outline: "bg-background text-foreground",
			info: "border-transparent bg-info text-info-foreground shadow",
			success:
				"border-transparent bg-success text-success-foreground shadow",
			warning:
				"border-transparent bg-warning text-warning-foreground shadow",
			error: "border-transparent bg-error text-error-foreground shadow",
		},
	},
	defaultVariants: {
		variant: "outline",
	},
})

const Chip: React.FC<ChipProps> = ({ className, variant, ...props }) => {
	return <div className={chipClasses({ variant, className })} {...props} />
}

export { Chip }
