import type { Table as TanstackTable } from "@tanstack/react-table"

import type { Customer } from "@/server/schemas"
import { QueueItemRow, QueueItems } from "@/components/ui/queue"
import { QueueView } from "@/components/shared/queue"

export function CustomerQueue({
	listDataState,
}: {
	listDataState: TanstackTable<Customer>
}) {
	return (
		<QueueView>
			<QueueItems
				listDataState={listDataState}
				renderItem={(customer) => (
					<>
						<QueueItemRow>
							<div className="truncate font-bold">
								{customer.name}
							</div>
							<div className="shrink-0 text-sm text-foreground-weak">
								{customer.contacts[0]?.name}
							</div>
						</QueueItemRow>
						<QueueItemRow variant="secondary">
							<div className="truncate">
								{(
									customer.addresses[0]?.city &&
									customer.addresses[0]?.state
								) ?
									`${customer.addresses[0]?.city}, ${customer.addresses[0]?.state}`
								:	"N/A"}
							</div>
							<div className="shrink-0">Since 2003</div>
							{/* TODO: Replace with real data */}
						</QueueItemRow>
					</>
				)}
				onRowClick={(customer) => {
					listDataState.setRowSelection({
						[customer.id]: true,
					})
				}}
				isActive={(customer) =>
					listDataState
						.getSelectedRowModel()
						.rows.some((row) => row.id === customer.id.toString())
				}
			/>
		</QueueView>
	)
}
