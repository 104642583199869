import { forwardRef } from "react"

import { cn } from "@/lib/utils/classnames"

export const Board = forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<div
		ref={ref}
		className={cn("flex flex-1 flex-col overflow-hidden", className)}
		{...props}
	/>
))
Board.displayName = "Board"

export const BoardBody = forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<div
		ref={ref}
		className={cn(
			"flex flex-1 overflow-x-auto px-2 pb-4 [scrollbar-width:thin]",
			className,
		)}
		{...props}
	/>
))
BoardBody.displayName = "BoardBody"

export const BoardColumn = forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<div
		ref={ref}
		className={cn("flex w-full min-w-72 max-w-96 flex-col", className)}
		{...props}
	/>
))
BoardColumn.displayName = "BoardColumn"

export const BoardColumnHeader = forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<div
		ref={ref}
		className={cn(
			"flex h-10 items-center justify-between px-2 py-0.5",
			className,
		)}
		{...props}
	/>
))
BoardColumnHeader.displayName = "BoardColumnHeader"

export const BoardColumnBody = forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<div
		ref={ref}
		className={cn(
			"min-h-48 flex-1 space-y-2 overflow-y-auto px-2 [scrollbar-width:thin]",
			className,
		)}
		{...props}
	/>
))
BoardColumnBody.displayName = "BoardColumnBody"
