import { motion } from "motion/react"

const formatDigit = (digit: string, key: string) => (
	<motion.div
		key={key + digit}
		initial={
			digit != "0" && { y: -10, opacity: 0, rotateX: -45, scale: 0.8 }
		}
		animate={{ y: 0, opacity: 1, rotateX: 0, scale: 1 }}
		transition={{ type: "spring", stiffness: 500, damping: 30 }}
	>
		{digit}
	</motion.div>
)

const renderDigits = (unit: string, unitKey: string) =>
	unit.split("").map((digit, index) => formatDigit(digit, unitKey + index))

export const formatTimerFromSeconds = (seconds: number) => {
	const h = String(Math.floor(seconds / 3600)).padStart(2, "0")
	const m = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0")
	const s = String(seconds % 60).padStart(2, "0")
	return (
		<div className="flex">
			<motion.div className="flex">{renderDigits(h, "h")}</motion.div>
			<span>:</span>
			<motion.div className="flex">{renderDigits(m, "m")}</motion.div>
			<span>:</span>
			<motion.div className="flex">{renderDigits(s, "s")}</motion.div>
		</div>
	)
}

export const formatFriendlyCountFromMinutes = (minutes: number) => {
	const time = minutes * 60
	const hrs = Math.floor(time / 3600)
	const mins = Math.floor((time % 3600) / 60)
	const seconds = time % 60

	if (seconds < 60 && mins < 2) return <>1 min</>
	if (hrs < 1) return <>{mins} min</>

	return (
		<>
			<span>{String(hrs).padStart(1, "0")}</span>
			<span>:{String(mins).padStart(2, "0")}</span>
		</>
	)
}

export const formatFriendlyCountFromSeconds = (seconds: number) => {
	const timeTotalInMinutes = seconds / 60
	return formatFriendlyCountFromMinutes(timeTotalInMinutes)
}
