import type { FakeEquipment } from "@/routes/_main+/resources+/equipment/fake-equipment"
import { EllipsisIcon } from "lucide-react"

import { cn } from "@/lib/utils/classnames"
import { Button } from "@/components/ui/button"
import { Chip } from "@/components/ui/chip"
import {
	DetailsContext,
	DetailsContextBar,
	DetailsInfoGroup,
	DetailsSubtitle,
	DetailsTitle,
	DetailsTopRightActions,
} from "@/components/ui/details-view"
import { Separator } from "@/components/ui/separator"
import type { DetailsHeaderContent } from "@/components/shared/details/details-view"

export const EquipmentPageHeader: DetailsHeaderContent<FakeEquipment> = ({
	data: equipment,
}) => (
	<>
		<DetailsContextBar>
			<DetailsContext feature="equipment" />
			<DetailsTopRightActions>
				<Button variant="ghost" size="icon">
					<EllipsisIcon className="size-4" />
				</Button>
			</DetailsTopRightActions>
		</DetailsContextBar>

		<div className="flex justify-between pr-3">
			<div className="flex gap-3">
				<div className="size-[90px] shrink-0 rounded-md bg-background-weakest" />
				<div>
					<DetailsTitle>
						{equipment.unit}
						<span className="mx-1 font-normal opacity-70">|</span>
						{`${equipment.year} ${equipment.manufacturer} ${equipment.model}`}
					</DetailsTitle>
					<DetailsSubtitle>
						<div className="mb-0.5 flex items-center gap-2 font-bold uppercase">
							{equipment.type}
							{equipment.status !== "Available" && (
								<Chip
									variant="outline"
									className={cn(
										"shrink-0 px-1.5",
										equipment.status == "Out of Service" &&
											"border-error-border/30 text-error",
									)}
								>
									{equipment.status}
								</Chip>
							)}
						</div>
						<div>
							{equipment.vin} | {equipment.licenseNumber}
						</div>
					</DetailsSubtitle>
					{/* TODO: Replace with real equipment tags */}
					<div className="mt-2 flex flex-wrap gap-1">
						<Chip variant="primary">PRIMARY</Chip>
						<Chip variant="info">HARD TO FIND</Chip>
						<Chip variant="info">NON-BILLABLE</Chip>
					</div>
				</div>
			</div>
			<div className="flex flex-col items-end gap-3">
				<div className="flex w-fit gap-2 rounded-md border bg-background-weak px-2">
					<DetailsInfoGroup
						label="Fleet"
						className="items-center p-1"
					>
						{equipment.fleet}
					</DetailsInfoGroup>
					<Separator orientation="vertical" className="flex-none" />
					<DetailsInfoGroup
						label="Group"
						className="items-center p-1"
					>
						{equipment.group}
					</DetailsInfoGroup>
					<Separator orientation="vertical" className="flex-none" />
					<DetailsInfoGroup label="Unit" className="items-center p-1">
						{equipment.unit}
					</DetailsInfoGroup>
				</div>
				<div className="flex justify-between gap-2">
					<DetailsInfoGroup label="Owner">
						{equipment.registeredOwner}
					</DetailsInfoGroup>
					<DetailsInfoGroup
						label="Base Location"
						className="text-right"
					>
						{equipment.baseLocation}
					</DetailsInfoGroup>
				</div>
			</div>
		</div>
	</>
)
