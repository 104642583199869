import type { FakePart } from "@/routes/_main+/resources+/parts/fake-parts"
import { SearchIcon, TimerResetIcon } from "lucide-react"

import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import { Input, InputSlot } from "@/components/ui/input"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

export const PartHistorySection: DetailsSectionComponent<FakePart> = ({
	data: part,
}) => (
	<DetailsSection>
		<DetailsSectionHeading>Part Usage History</DetailsSectionHeading>
		<Input placeholder="Search...">
			<InputSlot>
				<SearchIcon />
			</InputSlot>
		</Input>
	</DetailsSection>
)
PartHistorySection.label = "History"
PartHistorySection.icon = TimerResetIcon
