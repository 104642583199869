import { Calendar, Clock9 } from "lucide-react"

import type { Employee } from "@/server/schemas"
import {
	DetailsInfoGroup,
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"

export function EmployeeWorkInfoSection({ employee }: { employee: Employee }) {
	return (
		<DetailsSection>
			<DetailsSectionHeading>Work Info</DetailsSectionHeading>
			<div className="flex flex-col gap-3">
				<div className="mb-2 flex flex-col gap-2">
					<div className="flex items-center gap-2">
						<Calendar size={18} className="text-foreground-weak" />
						<span>Hired {employee?.hiredDate}</span>
					</div>
					<div className="flex items-center gap-2">
						<Clock9 size={18} className="text-foreground-weak" />
						<span>Weekdays 9AM-5PM</span>
					</div>
				</div>
				<DetailsInfoGroup label="Supervisor" className="col-span-full">
					Sally Beth Smergens
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Work Site">
					{employee.workSiteCode}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Work Phone">
					{employee.workPhone}
				</DetailsInfoGroup>
			</div>
		</DetailsSection>
	)
}
