import { forwardRef } from "react"
import { MenuIcon } from "lucide-react"

import { Avatar, AvatarFallback } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {
	Sheet,
	SheetContent,
	SheetDescription,
	SheetTitle,
	SheetTrigger,
} from "@/components/ui/sheet"
import { VisuallyHidden } from "@/components/ui/visually-hidden"
import { Logo as SVGLogo } from "@/components/shared/logo"

import { UserDropdownContent } from "../components/user-dropdown"
import { NAVBAR_DIMENSIONS } from "../constants"
import { useNavbar } from "../context"

export const MobileNavbarContainer = forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, children, style, ...props }, ref) => {
	const { openMobile, setOpenMobile } = useNavbar()

	return (
		<div className={className} {...props} ref={ref}>
			<div className="grid h-14 grid-cols-[auto_1fr_auto] items-center gap-4 px-4">
				<Sheet
					open={openMobile}
					onOpenChange={setOpenMobile}
					direction="left"
				>
					<SheetTrigger asChild>
						<Button variant="ghost" size="icon">
							<MenuIcon className="size-5" />
						</Button>
					</SheetTrigger>
					<SheetContent
						style={
							{
								"--navbar-width": NAVBAR_DIMENSIONS.MOBILE,
							} as React.CSSProperties
						}
						className="*:relative *:w-[--navbar-width] *:p-4"
					>
						<VisuallyHidden>
							<SheetTitle>Navigation Menu</SheetTitle>
							<SheetDescription>
								Main navigation menu providing access to all key
								areas of the application. Use this menu to
								navigate between different sections and
								features.
							</SheetDescription>
						</VisuallyHidden>
						{children}
					</SheetContent>
				</Sheet>

				<div className="flex justify-center">
					<SVGLogo className="w-full max-w-[200px] dark:hidden" />
					<SVGLogo
						color="white"
						className="hidden w-full max-w-[200px] dark:block"
					/>
				</div>

				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Button variant="ghost" size="icon">
							<Avatar className="scale-75">
								<AvatarFallback>Mike Mechanic</AvatarFallback>
							</Avatar>
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent side="bottom" align="end">
						<UserDropdownContent />
					</DropdownMenuContent>
				</DropdownMenu>
			</div>
		</div>
	)
})
MobileNavbarContainer.displayName = "MobileNavbarContainer"
