/**
 * Returns true if any of the strings are defined and not empty.
 * @param strings - The strings to check.
 * @returns True if any of the strings are defined and not empty, false otherwise.
 */
export function areAnyStringsDefined(
	...strings: (string | null | undefined)[]
): boolean {
	return strings.some(
		(str) => str !== undefined && str !== null && str.trim() !== "",
	)
}
