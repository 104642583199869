import type { LatLngTuple } from "leaflet"
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet"

import { cn } from "@/lib/utils/classnames"
import type { Location } from "@/server/schemas"

export function LocationMap({
	location,
	// mapOptions,
	className,
}: {
	location?: Location
	mapOptions?: React.ComponentProps<typeof MapContainer>
	className?: string
}) {
	// const fullAddress = useMemo(() => {
	// 	return (
	// 		location?.street1 +
	// 		" " +
	// 		location?.street2 +
	// 		" " +
	// 		location?.city +
	// 		", " +
	// 		location?.state +
	// 		" " +
	// 		location?.zip
	// 	)
	// }, [location])
	const position = [29.652, -82.325] as LatLngTuple
	// if (!results[0]) position = [29.652, -82.325] as LatLngTuple
	// else position = [results[0].x, results[0].y] as LatLngTuple

	return (
		<MapContainer
			zoom={13}
			center={position}
			scrollWheelZoom={false}
			className={cn(
				"isolate col-span-6 aspect-square min-w-[100px] max-w-[400px] rounded-lg border border-border shadow-sm",
				className,
			)}
		>
			<TileLayer
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
			<Marker position={position}>
				<Popup>{location?.name}</Popup>
			</Marker>
		</MapContainer>
	)
}
