import { useState } from "react"
import type { ReactNode } from "react"
import { trpcClient } from "@/trpc/client"
import { useLocation } from "@remix-run/react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { httpBatchLink } from "@trpc/react-query"
import superjson from "superjson"

import { DetailsMultiStateProvider } from "../shared/details/multi-select-provider"
import { ShopWatchGlobalProvider } from "./shop-watch/global-context"

interface ProvidersProps {
	children?: ReactNode
}
export const Providers = ({ children }: ProvidersProps) => {
	const [queryClient] = useState(() => new QueryClient())
	const [trpcClientState] = useState(() => {
		return trpcClient.createClient({
			links: [
				httpBatchLink({
					url: "/api/trpc",
					transformer: superjson,
				}),
			],
		})
	})
	const location = useLocation()

	return (
		<trpcClient.Provider client={trpcClientState} queryClient={queryClient}>
			<QueryClientProvider client={queryClient}>
				<ShopWatchGlobalProvider>
					<DetailsMultiStateProvider key={location.pathname}>
						{children}
					</DetailsMultiStateProvider>
				</ShopWatchGlobalProvider>
			</QueryClientProvider>
		</trpcClient.Provider>
	)
}
