import { Pause, Play } from "lucide-react"

import { cn } from "@/lib/utils/classnames"
import { formatTimerFromSeconds } from "@/lib/utils/time-formatting-helpers"

import { useShopWatch } from "../context"

export const ShopWatchTrackingCollapsed = () => {
	const { time, isRunning, setIsRunning } = useShopWatch()

	return (
		<>
			<button
				onClick={() => setIsRunning(!isRunning)}
				className={cn(
					"flex w-[48px] flex-col items-center gap-1 rounded border bg-success-weak px-1.5 py-1 text-[11px] leading-none text-success-foreground",
					!isRunning &&
						"border-primary-weak/30 bg-selected text-primary-weak",
					isRunning &&
						"border-success-weak bg-success-weak text-success-foreground",
				)}
			>
				{isRunning ?
					<Pause className="text-white" />
				:	<>
						<Play />
					</>
				}
				<div className="text-[9px]">{formatTimerFromSeconds(time)}</div>
			</button>
		</>
	)
}
