import { trpcClient } from "@/trpc/client"

import { DetailsViewContainer } from "@/components/shared/details/details-view"
import type { DetailsViewComponent } from "@/components/shared/details/details-view"

import { WorkOrderInfoSection } from "./sections/info"
import { WorkOrderMessagesSection } from "./sections/messages"
import { WorkOrderPageHeader } from "./sections/page-header"
import { WorkOrderPanelHeader } from "./sections/panel-header"

export const WorkOrdersDetailView: DetailsViewComponent = ({
	id,
}: {
	id: string
}) => {
	const [result] = trpcClient.workOrders.getWorkOrderById.useSuspenseQuery({
		id,
	})

	if (!result.success) {
		throw new Error("Failed to fetch work order")
	}

	return (
		<DetailsViewContainer
			data={result.value}
			headers={{ page: WorkOrderPageHeader, panel: WorkOrderPanelHeader }}
			sections={{
				info: WorkOrderInfoSection,
				messages: WorkOrderMessagesSection,
			}}
		/>
	)
}
