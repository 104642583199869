import { forwardRef } from "react"

import { cn } from "@/lib/utils/classnames"
import { formatFriendlyCountFromSeconds } from "@/lib/utils/time-formatting-helpers"

import { useShopWatch } from "../../context"

export const ShopWatchTotal = forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
	const { timeTotal } = useShopWatch()

	return (
		<div
			className={cn("flex items-baseline gap-1.5", className)}
			{...props}
			ref={ref}
		>
			<div className="text-sm text-foreground-weak">Total</div>
			<b>{formatFriendlyCountFromSeconds(timeTotal)}</b>
		</div>
	)
})
ShopWatchTotal.displayName = "ShopWatchTotal"
