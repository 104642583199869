import { createContext, useContext } from "react"

import { useDetailsState } from "@/lib/hooks/use-details-state"
import { useGlobalView } from "@/lib/hooks/use-global-view"
import { cn } from "@/lib/utils/classnames"
import { Page } from "@/components/ui/page"

import { Details } from "../details"
import { NothingSelected } from "../details/nothing-selected"

export type ListView = "queue" | "board" | "grid"
export const DEFAULT_LIST_VIEW: ListView = "queue"

interface ListViewContextValue {
	availableViews: ListView[]
}

const ListViewContext = createContext<ListViewContextValue>({
	availableViews: [DEFAULT_LIST_VIEW],
})

interface ViewComponents {
	queue: React.ReactNode
	active: React.ReactNode
}

export function useLayoutContext() {
	const context = useContext(ListViewContext)
	if (!context) {
		throw new Error("useListView must be used within a ListViewProvider")
	}
	return context
}

interface LayoutProps {
	listViews: ViewComponents
	children?: React.ReactNode
}

export function Layout({ listViews, children }: LayoutProps) {
	const details = useDetailsState()

	const { view, availableViews } = useGlobalView()

	return (
		<ListViewContext.Provider
			value={{
				availableViews,
			}}
		>
			{/* List Mobile */}
			<Page className={cn("w-full md:hidden")}>{listViews.queue}</Page>

			{/* List Desktop */}
			<Page
				className={cn(
					"max-md:hidden",
					view === "queue" ?
						"shrink-0 grow-0 sm:w-[320px]"
					:	"grow basis-[1600px]",
				)}
			>
				{listViews.active}
			</Page>

			{/* Detail Container */}
			{details.isOpen ?
				<Page
					className={cn(
						view === "queue" ? "grow sm:block" : (
							"min-w-96 3xl:min-w-[650px] 3xl:basis-1/2"
						),
						"max-md:absolute max-md:inset-0 max-md:w-full",
					)}
				>
					<Details />
				</Page>
			: view === "queue" ?
				<NothingSelected />
			:	null}

			{children}
		</ListViewContext.Provider>
	)
}
