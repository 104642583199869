/**
 * Extracts the year (YYYY) from a date string in YYYY-MM-DD format.
 * @param dateString - The date string in YYYY-MM-DD format.
 * @returns The year (YYYY) as a string.
 */
export function getYearFromDate(dateString: string | null): string | null {
	if (!dateString) return null

	const dateParts = dateString.split("-")
	if (dateParts.length !== 3) {
		console.error("Invalid date format. Expected YYYY-MM-DD.")
		return null
	}

	const year = dateParts[0] // The year is the first part in YYYY-MM-DD format
	return year || null
}
