import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import { getTableSelectColumns } from "@/lib/utils/data/validators"
import { employeeContactTable } from "@/server/tables"

const employeeContactOmitFields = {
	scopeId: true,
	createdAt: true,
	updatedAt: true,
} as const
export const employeeContactSelectColumns = {
	...getTableSelectColumns(employeeContactTable, employeeContactOmitFields),
}

export const employeeContactSchema = createSelectSchema(
	employeeContactTable,
).omit(employeeContactOmitFields)
export type EmployeeContact = z.infer<typeof employeeContactSchema>

export const newEmployeeContactSchema = createInsertSchema(
	employeeContactTable,
).omit(employeeContactOmitFields)
export type NewEmployeeContact = z.infer<typeof newEmployeeContactSchema>
