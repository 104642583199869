import { useMemo } from "react"
import { useLocation } from "@remix-run/react"

import type { PageConfig } from "@/config/pages"
import {
	pageStateToSearchParams,
	usePageState,
} from "@/lib/hooks/use-page-url-state"

export const useNavItemState = (page: PageConfig) => {
	const location = useLocation()
	const pageState = usePageState(page.id)

	const isActive = location.pathname === page.path

	const queryParams = pageState ? pageStateToSearchParams(pageState) : null

	const pathWithState = useMemo(() => {
		if (!queryParams) return page.path
		return `${page.path}?${queryParams.toString()}`
	}, [page.path, queryParams])

	return { isActive, pathWithState }
}
