import { TimerResetIcon } from "lucide-react"

import type { Employee } from "@/server/schemas"
import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

const EmployeeHistorySection: DetailsSectionComponent<Employee> = ({
	data: employee,
}) => (
	<DetailsSection>
		<DetailsSectionHeading>History</DetailsSectionHeading>
		<div className="flex flex-col gap-3">
			<div>
				<span className="mr-1.5 italic text-foreground-weak">
					11/12/24 -
				</span>
				<span>Emergency contact updated</span>
			</div>
			<div>
				<span className="mr-1.5 italic text-foreground-weak">
					09/27/22 -
				</span>
				<span>
					Lorem ipsum, dolor sit amet consectetur adipisicing elit.
					Alias repudiandae nulla reprehenderit provident natus et
					doloribus, autem ullam dolor eum excepturi eaque dignissimos
					aspernatur optio magnam pariatur, animi tenetur culpa?
				</span>
			</div>
		</div>
	</DetailsSection>
)

EmployeeHistorySection.label = "History"
EmployeeHistorySection.icon = TimerResetIcon

export { EmployeeHistorySection }
