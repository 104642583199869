import { Suspense, useEffect } from "react"
import { QueryErrorResetBoundary } from "@tanstack/react-query"
import { ErrorBoundary } from "react-error-boundary"

import { detailsViewConfig } from "@/config/details-view"
import { useDetailsState } from "@/lib/hooks/use-details-state"
import { ClientOnly } from "@/lib/utils/client-only"
import { Button } from "@/components/ui/button"
import { Skeleton } from "@/components/ui/skeleton"

const DetailsSkeleton = () => {
	return (
		<div className="space-y-4 p-6">
			<Skeleton className="h-6 w-32" />
			<Skeleton className="h-4 w-full" />
			<Skeleton className="h-4 w-3/4" />
			<Skeleton className="h-4 w-1/2" />
		</div>
	)
}

const ErrorFallback = ({
	resetErrorBoundary,
}: {
	resetErrorBoundary: () => void
}) => (
	<div className="space-y-4 p-6">
		<p className="text-lg font-semibold">Unable to Load Details</p>
		<p className="text-sm text-foreground-weak">
			An unexpected error occurred while loading the details. Please try
			again.
		</p>
		<Button onClick={resetErrorBoundary} variant="outline">
			Retry
		</Button>
	</div>
)

/**
 * Details component that handles displaying single or multiple detail views
 * with error boundaries, loading states, and client-only rendering
 */
export const Details = () => {
	const details = useDetailsState()

	// Early return if no details to show
	if (!details.isOpen) return null

	if (details.type === "error") {
		return (
			<div className="grid size-full place-items-center text-foreground-weak">
				Invalid view
			</div>
		)
	}

	const DetailViewComponent = detailsViewConfig[details.type]

	return (
		<ClientOnly>
			{() => (
				<QueryErrorResetBoundary>
					{({ reset }) => (
						<ErrorBoundary
							onReset={reset}
							fallbackRender={({ resetErrorBoundary }) => (
								<ErrorFallback
									resetErrorBoundary={resetErrorBoundary}
								/>
							)}
						>
							<Suspense
								fallback={<DetailsSkeleton />}
								key={`details=${details.type}&id=${details.mode === "single" ? details.id : details.ids.join(",")}`}
							>
								{details.mode === "single" && (
									<DetailViewComponent id={details.id} />
								)}
								{details.mode === "multi" && (
									<DetailViewComponent ids={details.ids} />
								)}
							</Suspense>
						</ErrorBoundary>
					)}
				</QueryErrorResetBoundary>
			)}
		</ClientOnly>
	)
}
