import { forwardRef, useEffect, useRef } from "react"
import type { Table } from "@tanstack/react-table"
import { tv } from "tailwind-variants"

import { useBreakpoint } from "@/lib/hooks/use-breakpoint"
import { useDetailsState } from "@/lib/hooks/use-details-state"
import { usePageUrlState } from "@/lib/hooks/use-page-url-state"
import { cn } from "@/lib/utils/classnames"

export const Queue = forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<div
		ref={ref}
		className={cn(
			"flex w-full flex-1 flex-col overflow-hidden sm:shrink-0",
			className,
		)}
		{...props}
	/>
))
Queue.displayName = "Queue"

export const QueueHeader = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div
		ref={ref}
		{...props}
		className={cn(
			"flex items-center justify-between border-b p-3",
			className,
		)}
	>
		{children}
	</div>
))
QueueHeader.displayName = "QueueHeader"

export const QueueActions = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div ref={ref} {...props} className={cn("flex shrink-0 gap-1", className)}>
		{children}
	</div>
))
QueueActions.displayName = "QueueActions"

export const QueueSubheader = forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<div
		ref={ref}
		className={cn("flex gap-1 border-b px-3 py-2", className)}
		{...props}
	/>
))
QueueSubheader.displayName = "QueueSubheader"

export const QueueBody = forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<div
		ref={ref}
		className={cn(
			"flex flex-1 flex-col space-y-1 overflow-auto p-1.5 [scrollbar-width:thin]",
			className,
		)}
		{...props}
	/>
))
QueueBody.displayName = "QueueBody"

export type StatusLineType =
	| "success"
	| "warning"
	| "error"
	| "info"
	| "primary"
	| null
export const QueueItem = forwardRef<
	HTMLButtonElement,
	React.HTMLAttributes<HTMLButtonElement> & {
		isActive?: boolean
		statusLine?: StatusLineType
	}
>(({ className, children, statusLine, isActive, ...props }, ref) => (
	<button
		ref={ref}
		className={cn(
			"relative flex w-full flex-1 grow-0 cursor-pointer flex-col items-stretch gap-[3px] rounded px-2 py-1.5 text-left leading-tight transition-colors focus-visible:outline-2 focus-visible:-outline-offset-1 focus-visible:outline-focus",
			isActive && "bg-selected text-selected-foreground",
			!isActive && "hover:bg-hover",
			statusLine && "pl-4",
			className,
		)}
		{...props}
	>
		{statusLine && (
			<div
				className={cn(
					"absolute inset-y-1 left-1 w-1 rounded-full",
					statusLine === "success" && "bg-success",
					statusLine === "warning" && "bg-warning",
					statusLine === "error" && "bg-error",
					statusLine === "info" && "bg-info",
					statusLine === "primary" && "bg-primary",
				)}
			/>
		)}

		{children}
	</button>
))
QueueItem.displayName = "QueueItem"

export interface QueueItemRowProps
	extends React.HTMLAttributes<HTMLDivElement> {
	variant?: "primary" | "secondary" | "tertiary"
}

export const queueItemRowClasses = tv({
	base: ["flex items-center justify-between gap-2"],
	variants: {
		variant: {
			primary: "",
			secondary: "text-sm text-foreground-weak",
			tertiary: "text-sm text-foreground-weak opacity-70",
		},
	},
	defaultVariants: {
		variant: "primary",
	},
})
export const QueueItemRow = forwardRef<HTMLDivElement, QueueItemRowProps>(
	({ className, variant, ...props }, ref) => (
		<div
			ref={ref}
			// className={cn("flex items-center justify-between gap-3", className)}
			className={queueItemRowClasses({ variant, className })}
			{...props}
		/>
	),
)
QueueItemRow.displayName = "QueueItemRow"

interface QueueItemsProps<T> extends React.HTMLAttributes<HTMLDivElement> {
	listDataState: Table<T>
	renderItem: (data: T) => React.ReactNode
	statusLine?: (data: T) => StatusLineType
	onRowClick?: (data: T) => void
	isActive?: (data: T) => boolean
}

export function QueueItems<T>({
	listDataState,
	renderItem,
	onRowClick,
	isActive = () => false,
	statusLine,
	className,
}: QueueItemsProps<T>) {
	const { isAboveMd } = useBreakpoint("md")
	const details = useDetailsState()
	const rows = listDataState.getRowModel().rows
	const hasAutoSelected = useRef(false)
	const { pageState } = usePageUrlState()
	const { view } = pageState

	useEffect(() => {
		if (!details.isOpen) {
			hasAutoSelected.current = false
		}
	}, [details.isOpen])

	useEffect(() => {
		if (
			// We want to auto-select the first item
			// when on a medium or larger screen,
			// the details panel is closed,
			// there are items in the list,
			// and we haven't auto-selected yet
			isAboveMd &&
			!details.isOpen &&
			rows.length > 0 &&
			!hasAutoSelected.current &&
			view == "queue"
		) {
			hasAutoSelected.current = true
			onRowClick?.(rows[0]!.original)
		}
	}, [rows, details.isOpen, onRowClick, isAboveMd, view])

	return (
		<>
			{listDataState.getRowModel().rows.map((row) => (
				<QueueItem
					key={row.id}
					onClick={() => onRowClick?.(row.original)}
					isActive={isActive(row.original)}
					statusLine={
						statusLine ? statusLine(row.original) : undefined
					}
					className={className}
				>
					{renderItem(row.original)}
				</QueueItem>
			))}
		</>
	)
}
