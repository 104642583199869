import { forwardRef } from "react"

import { cn } from "@/lib/utils/classnames"

const predefinedColumnSizes = {
	"2xs": "25px",
	"xs": "75px",
	"sm": "125px",
	"md": "200px",
	"lg": "350px",
	"xl": "525px",
	"2xl": "750px",
	// "2xs": "25px",
	// "xs": "50px",
	// "sm": "75px",
	// "md": "125px",
	// "lg": "200px",
	// "xl": "325px",
	// "2xl": "525px",
}

const predefinedGaps = {
	none: "gap-0",
	xs: "gap-0.5",
	sm: "gap-1",
	md: "gap-3",
	lg: "gap-4",
	xl: "gap-8",
}

interface GridRowProps extends React.HTMLAttributes<HTMLDivElement> {
	children?: React.ReactNode
	fill?: boolean // Should columns grow in width to fill horizontal space
	columnSize?: keyof typeof predefinedColumnSizes | string // Min width of columns (ideal)
	gap?: "none" | "sm" | "md" | "lg" | "xl" | string
}

const GridRow = forwardRef<HTMLDivElement, GridRowProps>(
	(
		{
			children,
			className,
			columnSize = "md",
			gap = "md",
			fill = false,
			...props
		},
		ref,
	) => {
		const resolvedColumnSize =
			columnSize in predefinedColumnSizes ?
				predefinedColumnSizes[
					columnSize as keyof typeof predefinedColumnSizes
				]
			:	columnSize

		const resolvedGapClass =
			gap in predefinedGaps ?
				predefinedGaps[gap as keyof typeof predefinedGaps]
			:	gap

		const autoFitClass =
			fill ?
				"grid-cols-[repeat(auto-fit,minmax(var(--column-size),1fr))]"
			:	"grid-cols-[repeat(auto-fill,minmax(var(--column-size),1fr))]"

		return (
			<div
				ref={ref}
				style={
					{
						"--column-size": resolvedColumnSize,
						"gap": resolvedGapClass === "" ? gap : undefined,
					} as React.CSSProperties
				}
				className={cn(
					"grid",
					resolvedGapClass,
					autoFitClass,
					className,
				)}
				{...props}
			>
				{children}
			</div>
		)
	},
)
GridRow.displayName = "GridRow"

export { GridRow }
