import { Bolt, MapPin, MessageCircleCodeIcon } from "lucide-react"

import { Chip } from "@/components/ui/chip"
import {
	DetailsCard,
	DetailsCardInfoLine,
	DetailsCardInfoLinesWrapper,
	DetailsCardOverline,
	DetailsCardSubtitle,
	DetailsCardTitle,
} from "@/components/ui/details-card"

//TODO: Type this
// interface PartDetailsCardProps {}

//TODO: Implement this with real data
export function PartDetailsCard() {
	return (
		<DetailsCard>
			<DetailsCardOverline icon={Bolt}>Part</DetailsCardOverline>
			{/* <Button
				variant="ghost"
				size="icon"
				className="absolute right-1 top-1"
			>
				<EllipsisIcon className="size-4" />
			</Button> */}

			<DetailsCardTitle>Fog Lamp Bulb, Amber</DetailsCardTitle>
			<DetailsCardSubtitle>Bosch International</DetailsCardSubtitle>

			{/* TODO: Replace with real part tags */}
			<div className="mt-1 flex flex-wrap items-center gap-1">
				<Chip variant="primary">ELECTRICAL SYSTEMS</Chip>
				<Chip variant="success">IN STOCK</Chip>
			</div>

			<DetailsCardInfoLinesWrapper>
				<DetailsCardInfoLine icon={MapPin}>
					3 Locations
				</DetailsCardInfoLine>
				<DetailsCardInfoLine icon={MessageCircleCodeIcon}>
					56453716201
				</DetailsCardInfoLine>
			</DetailsCardInfoLinesWrapper>
		</DetailsCard>
	)
}
