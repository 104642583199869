import { Calendar } from "lucide-react"

import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"

export function EquipmentSubscribedPMSection() {
	return (
		<DetailsSection>
			<DetailsSectionHeading>Subscribed PM Series</DetailsSectionHeading>
			<div className="flex flex-col gap-3">
				<div className="flex items-center gap-2">
					<Calendar className="size-8 text-foreground-weak" />
					<div>
						<div>
							TRK-PMA{" "}
							<span className="opacity-70">(5 services)</span>
						</div>
						<div className="text-xs text-foreground-weak">
							5,000 miles/6 months | Due: 45,810 or 12/24/24
						</div>
					</div>
				</div>
				<div className="flex items-center gap-2">
					<Calendar className="size-8 text-foreground-weak" />
					<div>
						<div>
							TRK-PMA{" "}
							<span className="opacity-70">(5 services)</span>
						</div>
						<div className="text-xs text-foreground-weak">
							5,000 miles/6 months | Due: 45,810 or 12/24/24
						</div>
					</div>
				</div>{" "}
				<div className="flex items-center gap-2">
					<Calendar className="size-8 text-foreground-weak" />
					<div>
						<div>
							TRK-PMA{" "}
							<span className="opacity-70">(5 services)</span>
						</div>
						<div className="text-xs text-foreground-weak">
							5,000 miles/6 months | Due: 45,810 or 12/24/24
						</div>
					</div>
				</div>
			</div>
		</DetailsSection>
	)
}
