import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import { PreventiveMaintenanceCard } from "@/components/features/preventive-maintenance/details-card"

export function EquipmentNextDueSection() {
	return (
		<DetailsSection>
			<DetailsSectionHeading>Next PM Due</DetailsSectionHeading>
			<PreventiveMaintenanceCard />
			<PreventiveMaintenanceCard />
		</DetailsSection>
	)
}
