import resolveConfig from "tailwindcss/resolveConfig"

import tailwindConfig from "../../../tailwind.config"
import { useMediaQuery } from "./use-media-query"

const fullConfig = resolveConfig(tailwindConfig)

const breakpoints = fullConfig.theme.screens

/**
 * @desc The 'useBreakpoint()' hook is used to get the current
 *       screen breakpoint based on the TailwindCSS config.
 *
 * @usage
 *    ```tsx
 *    import { useBreakpoint } from "@/hooks/useBreakpoint";
 *
 *    const { isAboveSm, isBelowSm, sm } = useBreakpoint("sm");
 *    console.log({ isAboveSm, isBelowSm, sm });
 *
 *    const { isAboveMd } = useBreakpoint("md");
 *    const { isAboveLg } = useBreakpoint("lg");
 *    const { isAbove2Xl } = useBreakpoint("2xl");
 *    console.log({ isAboveMd, isAboveLg, isAbove2Xl });
 *
 * {@link https://stackoverflow.com/questions/59982018}
 *
 */

export function useBreakpoint<K extends string>(breakpointKey: K) {
	const breakpointValue =
		breakpoints[breakpointKey as keyof typeof breakpoints]

	const bool = useMediaQuery(`(max-width: ${breakpointValue})`)

	const capitalizedKey =
		breakpointKey[0]?.toUpperCase() + breakpointKey.substring(1)

	type KeyAbove = `isAbove${Capitalize<K>}`
	type KeyBelow = `isBelow${Capitalize<K>}`

	return {
		[breakpointKey]: Number(String(breakpointValue).replace(/[^0-9]/g, "")),
		[`isAbove${capitalizedKey}`]: !bool,
		[`isBelow${capitalizedKey}`]: bool,
	} as Record<K, number> & Record<KeyAbove | KeyBelow, boolean>
}
