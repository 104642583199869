import { pgTable, primaryKey } from "drizzle-orm/pg-core"

import { tagFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"
import { locationTable } from "./location"

export const locationTagTable = pgTable(
	"location_tag",
	tagFields(scopeTable, locationTable),
	(table) => {
		return {
			pk: primaryKey({ columns: [table.rowId, table.tag] }),
		}
	},
)
