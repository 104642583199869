import { ListChecksIcon } from "lucide-react"

import type { Employee } from "@/server/schemas"
import {
	DetailsInfoGroup,
	DetailsInfoGroupsWrapper,
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

const EmployeeJobDetailsSection: DetailsSectionComponent<Employee> = ({
	data: employee,
}) => (
	<DetailsSection>
		<DetailsSectionHeading>Job Details</DetailsSectionHeading>
		<DetailsInfoGroupsWrapper>
			<div className="flex flex-wrap gap-y-3">
				<DetailsInfoGroup label="Rate Code" className="basis-1/2">
					{/* Employee schema doesn't have a pay rate code */}
					BULK
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Rate Function" className="basis-1/2">
					{/* Employee schema doesn't have a pay rate function */}
					Conley Bulk
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Hourly Rate" className="basis-1/2">
					${employee.payRate}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Rate Inactive">
					{/* Employee schema doesn't have a pay rate inactive field */}
					Yes
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Overhead %" className="basis-1/2">
					{/* Employee schema doesn't have an overhead % field */}
					0%
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Overhead Amount" className="basis-1/2">
					{/* Employee schema doesn't have an overhead amount field */}
					$0.00
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Flat Rate" className="basis-1/2">
					{/* Employee schema doesn't have a flat rate field */}
					<></>
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Charge Out Rate" className="basis-1/2">
					{/* Employee schema doesn't have a charge out rate field */}
					$25.00
				</DetailsInfoGroup>
			</div>

			<DetailsInfoGroup
				label="Driver's License Number"
				className="col-span-full"
			>
				{employee.driverLicenseNumber}
			</DetailsInfoGroup>
			<div className="flex flex-wrap gap-y-3">
				<DetailsInfoGroup
					label="Driver's License State"
					className="basis-1/2"
				>
					{employee.driverLicenseState}
				</DetailsInfoGroup>
				<DetailsInfoGroup
					label="Driver's License Class"
					className="basis-1/2"
				>
					{/* Employee schema doesn't have a driver's license class field */}
					CDL C
				</DetailsInfoGroup>
			</div>

			<DetailsInfoGroup
				label="Driver's License Expiration Date"
				className="col-span-full"
			>
				{employee.driverLicenseExpires}
			</DetailsInfoGroup>
			<DetailsInfoGroup
				label="Driver's License Endorsements"
				className="col-span-full"
			>
				{/* Employee schema doesn't have a driver's license endorsements field */}
				Hazardous Materials, Double Trailer
			</DetailsInfoGroup>
			<DetailsInfoGroup
				label="Driver's License Restrictions"
				className="col-span-full"
			>
				{/* Employee schema doesn't have a driver's license restrictions field */}
				Medical Variance
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Fuel Card Number">
				{/* Employee schema doesn't have a fuel card number field */}
				651535
			</DetailsInfoGroup>
		</DetailsInfoGroupsWrapper>
	</DetailsSection>
)

EmployeeJobDetailsSection.label = "Job Details"
EmployeeJobDetailsSection.icon = ListChecksIcon

export { EmployeeJobDetailsSection }
