import { useEffect, useState } from "react"

const MOBILE_BREAKPOINT = 1024

export function useIsMobile(): boolean {
	const [isMobile, setIsMobile] = useState<boolean>(() =>
		typeof window !== "undefined" ?
			window.innerWidth < MOBILE_BREAKPOINT
		:	false,
	)

	useEffect(() => {
		const mediaQuery = window.matchMedia(
			`(max-width: ${MOBILE_BREAKPOINT - 1}px)`,
		)
		const onChange = (e: MediaQueryListEvent) => setIsMobile(e.matches)

		mediaQuery.addEventListener("change", onChange)
		return () => mediaQuery.removeEventListener("change", onChange)
	}, [])

	return isMobile
}
