import { useCallback, useMemo } from "react"

import type { MultiDetailsView, SingleDetailsView } from "@/config/details-view"
import { useMultiState } from "@/components/shared/details/multi-select-provider"

import { usePageUrlState } from "./use-page-url-state"

interface DetailsStateClosed {
	isOpen: false
	mode: "single"
}

interface DetailsStateSingle {
	isOpen: true
	mode: "single"
	id: string
	type: SingleDetailsView
}

interface DetailsStateMulti {
	isOpen: true
	mode: "multi"
	ids: string[]
	type: MultiDetailsView
}

type DetailsState = DetailsStateClosed | DetailsStateSingle | DetailsStateMulti

export function useDetailsState(): DetailsState & {
	open: (
		params:
			| { type: SingleDetailsView; id: string; tab?: string }
			| { type: MultiDetailsView; ids: string[] },
	) => void
	close: () => void
	tab?: string
	setTab: (tab: string) => void
} {
	const { pageState, setDetails: setUrlDetails } = usePageUrlState()
	const { multiSelection, setMultiSelection, clearMultiSelection } =
		useMultiState()

	/**
	 * Multi-selection local state
	 * If defined, we're in multi mode. If undefined, check the single mode from pageState.
	 */

	const detailState: DetailsState = useMemo(() => {
		if (multiSelection) {
			return {
				isOpen: true,
				mode: "multi",
				type: multiSelection.type,
				ids: multiSelection.ids,
			}
		}
		if (!pageState.details?.type || !pageState.details?.id) {
			return {
				isOpen: false,
				mode: "single",
				tab: pageState.details?.tab,
			}
		}
		return {
			isOpen: true,
			mode: "single",
			type: pageState.details.type,
			id: pageState.details.id,
			tab: pageState.details.tab,
		}
	}, [multiSelection, pageState.details])

	/**
	 * Single function to open detail(s).
	 * - If called with a single string as the ID, opens single mode (URL-based).
	 * - If called with an array of IDs:
	 *   - If the array has one item, treated as single mode.
	 *   - If multiple items, treated as multi mode.
	 */
	const open = useCallback(
		(
			params:
				| { type: SingleDetailsView; id: string; tab?: string }
				| { type: MultiDetailsView; ids: string[] },
		) => {
			if ("ids" in params) {
				if (params.ids.length === 1) {
					// Single detail if only one ID
					clearMultiSelection()
					// setUrlDetails({
					// 	type: params.type,
					// 	id: params.ids[0]!,
					// })
				} else if (params.ids.length > 1) {
					// Multi selection mode
					setMultiSelection({ type: params.type, ids: params.ids })
				} else {
					// No IDs provided, close the panel
					setUrlDetails(null)
					setMultiSelection(null)
				}
			} else {
				// Single ID
				clearMultiSelection()
				setUrlDetails({
					type: params.type,
					id: params.id,
					tab: params.tab,
				})
			}
		},
		[clearMultiSelection, setMultiSelection, setUrlDetails],
	)

	const close = useCallback(() => {
		setUrlDetails(null)
		clearMultiSelection()
	}, [clearMultiSelection, setUrlDetails])

	const setTab = useCallback(
		(newTab: string | undefined) => {
			// Only applicable in single mode
			if (
				multiSelection === null &&
				pageState.details?.type &&
				pageState.details.id
			) {
				setUrlDetails({
					type: pageState.details.type,
					id: pageState.details.id,
					tab: newTab,
				})
			}
		},
		[multiSelection, pageState.details, setUrlDetails],
	)

	return {
		...detailState,
		open,
		close,
		setTab,
	}
}
