import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import type { z } from "zod"

import { getTableSelectColumns } from "@/lib/utils/data/validators"
import { customerBillingTable } from "@/server/tables/customer"

const customerBillingOmitFields = {
	scopeId: true,
	createdAt: true,
	updatedAt: true,
} as const
export const customerBillingSelectColumns = {
	...getTableSelectColumns(customerBillingTable, customerBillingOmitFields),
}

export const customerBillingSchema = createSelectSchema(
	customerBillingTable,
).omit(customerBillingOmitFields)
export type CustomerBilling = z.infer<typeof customerBillingSchema>

export const newCustomerBillingSchema = createInsertSchema(
	customerBillingTable,
).omit(customerBillingOmitFields)
export type NewCustomerBilling = z.infer<typeof newCustomerBillingSchema>
