import type { LucideIcon } from "lucide-react"

import { CommandItem } from "@/components/ui/command"

interface CommandItemProps {
	onSelect: () => void
	icon: LucideIcon
	label: string
	count?: number
}

export function ViewAllItem({
	onSelect,
	icon: Icon,
	label,
	count,
}: CommandItemProps) {
	return (
		<CommandItem
			onSelect={onSelect}
			className="flex items-center justify-between"
		>
			<div className="flex items-center gap-2">
				<Icon />
				<span>{label}</span>
			</div>
			{!!count && (
				<span className="text-foreground-weak">
					{count > 99 ? "99+" : count} results
				</span>
			)}
		</CommandItem>
	)
}
