import { Settings2Icon } from "lucide-react"

import type { Vendor } from "@/server/schemas"
import {
	DetailsInfoGroup,
	DetailsInfoGroupsWrapper,
	DetailsSection,
	DetailsSectionHeading,
	DetailsSectionsGrouping,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

export const VendorSettingsSection: DetailsSectionComponent<Vendor> = ({
	data: vendor,
}) => (
	<DetailsSectionsGrouping>
		<DetailsSection>
			<DetailsSectionHeading>Settings</DetailsSectionHeading>
			<div className="grid grid-cols-2 gap-4">
				{/* <section className="flex flex-col">
					<Overline>Active</Overline>
					<span className="text-foreground-strong">
						{vendor.isActive ? "Yes" : "No"}
					</span>
				</section> */}
				<DetailsInfoGroup label="Performance Rank">
					{vendor.performanceRank}
				</DetailsInfoGroup>
			</div>
		</DetailsSection>
		<DetailsSection>
			<DetailsSectionHeading>Accounting</DetailsSectionHeading>
			<DetailsInfoGroupsWrapper>
				<DetailsInfoGroup label="Taxes">
					Utah Sales Tax
					{/* TODO: Replace with real taxes */}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Terms">
					Net 30
					{/* TODO: Replace with real terms */}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Department">
					1000 - Fleet Maintenance
					{/* TODO: Replace with real department */}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Expense Category">
					001 - Sales Department
					{/* TODO: Replace with real expense category */}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Location">
					03 - North Region
					{/* TODO: Replace with real location */}
				</DetailsInfoGroup>
			</DetailsInfoGroupsWrapper>
		</DetailsSection>
	</DetailsSectionsGrouping>
)
VendorSettingsSection.label = "Settings"
VendorSettingsSection.icon = Settings2Icon
