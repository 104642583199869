import { SettingsIcon } from "lucide-react"
import { useFormContext } from "react-hook-form"

import { AddEditSection, AddEditSectionHeading } from "@/components/ui/add-edit"
import { Checkbox } from "@/components/ui/checkbox"
import type { AddEditSectionDefinition } from "@/components/shared/add-edit"

export const EquipmentAddEditSettings: AddEditSectionDefinition = {
	label: "Settings",
	icon: SettingsIcon,
	component: <SettingsFormSection />,
}

function SettingsFormSection() {
	const { register } = useFormContext()
	return (
		<AddEditSection>
			<AddEditSectionHeading>Settings</AddEditSectionHeading>
			<div className="flex items-center gap-1">
				<Checkbox {...register("listAllMfg")} id="listAllMfg" />
				List all mfg
			</div>
		</AddEditSection>
	)
}
