import { addEditConfig } from "@/config/add-edit"
import type { AddEditConfigKeys } from "@/config/add-edit"
import { featureConfig } from "@/config/features"
import type { Feature } from "@/config/features"
import { pagesConfig } from "@/config/pages"

import { usePageId } from "./use-page-id"

export const usePageInfo = () => {
	const pageId = usePageId()
	const pageGroupId = pageId.split(".")[0]

	const feature = pagesConfig
		.find((obj) => obj.id === pageGroupId)
		?.pages.find((page) => page.id === pageId)?.feature as Feature

	const addEdit =
		feature && feature in addEditConfig ?
			(feature as AddEditConfigKeys)
		:	null

	const pageGroup = pageGroupId?.toUpperCase()
	const pageTitle = feature ? featureConfig[feature].labels.navigation : ""
	return { feature, pageGroup, pageTitle, addEdit }
}
