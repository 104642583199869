// export const metaSchema = createSchemaFromFields(metaFields)
// CreateSchema returns any, hardcode it for now

import { pgTable } from "drizzle-orm/pg-core"
import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import type { z } from "zod"

import { metaFields } from "@/lib/utils/data/schema"
import { locationTable, scopeTable } from "@/server/tables"

const tempTable = pgTable(
	"temp_meta_table",
	metaFields(scopeTable, locationTable),
)

export const metaSchema = createSelectSchema(tempTable).omit({
	scopeId: true,
	createdAt: true,
	updatedAt: true,
})
export type Meta = z.infer<typeof metaSchema>

export const newMetaSchema = createInsertSchema(tempTable).omit({
	scopeId: true,
	createdAt: true,
	updatedAt: true,
})
export type NewMeta = z.infer<typeof newMetaSchema>
