import { employeeFormSchema } from "@/server/schemas"
import { AddEdit } from "@/components/shared/add-edit"
import type { AddEditStateProps } from "@/components/shared/add-edit"

import { EquipmentAddEditDevices } from "./sections/devices"
import { EquipmentAddEditFinancial } from "./sections/financial"
import { EquipmentAddEditInfo } from "./sections/info"
import { EquipmentAddEditSettings } from "./sections/settings"
import { EquipmentAddEditSummary } from "./sections/summary"

// TODO: Add equipment form schema
type EquipmentAddEditProps = AddEditStateProps<typeof employeeFormSchema>
export function EquipmentAddEdit({ ...props }: EquipmentAddEditProps) {
	return (
		<AddEdit
			{...props}
			summary={<EquipmentAddEditSummary />}
			sectionDefinitions={{
				info: EquipmentAddEditInfo,
				devices: EquipmentAddEditDevices,
				financial: EquipmentAddEditFinancial,
				settings: EquipmentAddEditSettings,
			}}
			feature="equipment"
			submitHandlers={{
				add: (data) => console.log("Adding equipment"),
				edit: (data) => console.log("Editing equipment"),
			}}
			schema={employeeFormSchema}
		/>
	)
}
