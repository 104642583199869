import { InfoIcon } from "lucide-react"

import type { Employee } from "@/server/schemas"
import { DetailsSectionsGrouping } from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"
import { Notes } from "@/components/shared/details/notes"

import { EmployeeAttachmentsSection } from "./attachments"
import { EmployeePersonalInfoSection } from "./personal-info"
import { EmployeeWorkInfoSection } from "./work-info"

const EmployeeInfoSection: DetailsSectionComponent<Employee> = ({
	data: employee,
}) => (
	<DetailsSectionsGrouping>
		{employee.notes && (
			<Notes notes={employee.notes ? [employee.notes] : undefined} />
		)}
		<EmployeeWorkInfoSection employee={employee} />
		<EmployeePersonalInfoSection employee={employee} />
		<EmployeeAttachmentsSection />
	</DetailsSectionsGrouping>
)

EmployeeInfoSection.label = "Info"
EmployeeInfoSection.icon = InfoIcon

export { EmployeeInfoSection }
