import { locationFormSchema } from "@/server/schemas"
import { AddEdit } from "@/components/shared/add-edit"
import type { AddEditStateProps } from "@/components/shared/add-edit"

import { LocationAddEditContacts } from "./sections/contacts"
import { LocationAddEditInfo } from "./sections/info"
import { LocationAddEditSummary } from "./sections/summary"

type LocationAddEditProps = AddEditStateProps<typeof locationFormSchema>

export function LocationAddEdit({ ...props }: LocationAddEditProps) {
	return (
		<AddEdit
			{...props}
			summary={<LocationAddEditSummary />}
			sectionDefinitions={{
				info: LocationAddEditInfo,
				contacts: LocationAddEditContacts,
			}}
			feature="location"
			submitHandlers={{
				add: (data) => {
					console.log(data)
				},
				edit: (data) => {
					console.log(data)
				},
			}}
			schema={locationFormSchema}
		/>
	)
}
