import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import { getTableSelectColumns } from "@/lib/utils/data/validators"
import { locationContactTable } from "@/server/tables"

const locationContactOmitFields = {
	scopeId: true,
	createdAt: true,
	updatedAt: true,
} as const
export const locationContactSelectColumns = {
	...getTableSelectColumns(locationContactTable, locationContactOmitFields),
}

export const locationContactSchema = createSelectSchema(
	locationContactTable,
).omit(locationContactOmitFields)
export type LocationContact = z.infer<typeof locationContactSchema>

export const newLocationContactSchema = createInsertSchema(
	locationContactTable,
).omit(locationContactOmitFields)
export type NewLocationContact = z.infer<typeof newLocationContactSchema>
