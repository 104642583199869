import { trpcClient } from "@/trpc/client"

import type { DetailsViewComponent } from "@/components/shared/details/details-view"
import { DetailsViewContainer } from "@/components/shared/details/details-view"

import { CustomerHistorySection } from "./sections/history"
import { CustomerInfoSection } from "./sections/info"
import { CustomerPageHeader } from "./sections/page-header"
import { CustomerPanelHeader } from "./sections/panel-header"
import { CustomerSettingsSection } from "./sections/settings"

export const CustomerDetailsView: DetailsViewComponent = (props) => {
	const { id: customerId } = props
	const [result] = trpcClient.customers.getCustomerById.useSuspenseQuery({
		id: parseInt(customerId),
	})

	if (!result.success) {
		throw new Error("Failed to fetch customer")
	}

	return (
		<DetailsViewContainer
			data={result.value}
			headers={{
				page: CustomerPageHeader,
				panel: CustomerPanelHeader,
			}}
			sections={{
				info: CustomerInfoSection,
				settings: CustomerSettingsSection,
				history: CustomerHistorySection,
			}}
		/>
	)
}
