import type { FakePart } from "@/routes/_main+/resources+/parts/fake-parts"
import { PlusIcon, ShoppingBagIcon } from "lucide-react"

import { Button } from "@/components/ui/button"
import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"
import { PurchaseOrderDetailsCard } from "@/components/features/purchase-order/details-card"

export const PartPurchaseOrdersSection: DetailsSectionComponent<FakePart> = ({
	data: part,
}) => (
	<DetailsSection>
		<DetailsSectionHeading>Purchase Orders</DetailsSectionHeading>
		<PurchaseOrderDetailsCard />
		<Button className="mt-2 flex items-center gap-1.5">
			<PlusIcon className="size-4" /> Request Part
		</Button>
	</DetailsSection>
)
PartPurchaseOrdersSection.label = "Purchase Orders"
PartPurchaseOrdersSection.icon = ShoppingBagIcon
