import { trpcClient } from "@/trpc/client"

import { DetailsViewContainer } from "@/components/shared/details/details-view"
import type { DetailsViewComponent } from "@/components/shared/details/details-view"

import { VendorHistorySection } from "./sections/history"
import { VendorInfoSection } from "./sections/info"
import { VendorPageHeader } from "./sections/page-header"
import { VendorPanelHeader } from "./sections/panel-header"
import { VendorSettingsSection } from "./sections/settings"

export const VendorDetailsView: DetailsViewComponent = (props) => {
	const { id: vendorId } = props
	const [result] = trpcClient.vendors.getVendorById.useSuspenseQuery({
		id: parseInt(vendorId),
	})

	if (!result.success) {
		throw new Error("Failed to fetch vendor")
	}

	return (
		<DetailsViewContainer
			data={result.value}
			headers={{ page: VendorPageHeader, panel: VendorPanelHeader }}
			sections={{
				info: VendorInfoSection,
				settings: VendorSettingsSection,
				history: VendorHistorySection,
			}}
		/>
	)
}
