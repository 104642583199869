import { useEffect, useRef } from "react"
import type { WorkOrder } from "@/routes/_main+/work+/work-orders/columns"
import { Hammer, User, WrenchIcon } from "lucide-react"

import { useDetailsState } from "@/lib/hooks/use-details-state"
import { Chip } from "@/components/ui/chip"
import {
	DetailsCard,
	DetailsCardInfoLine,
	DetailsCardInfoLinesWrapper,
	DetailsCardOverline,
	DetailsCardSubtitle,
	DetailsCardTitle,
} from "@/components/ui/details-card"

interface WorkOrderDetailCardProps {
	workOrder: WorkOrder
	isActive: boolean
}
export function WorkOrderDetailCard({
	workOrder,
	isActive,
}: WorkOrderDetailCardProps) {
	const { open, isOpen } = useDetailsState()
	const cardRef = useRef<HTMLButtonElement>(null)

	useEffect(() => {
		if (isOpen && isActive) {
			cardRef.current?.scrollIntoView({
				behavior: "smooth",
				block: "nearest",
				inline: "nearest",
			})
		}
	}, [isOpen, isActive])

	return (
		<DetailsCard
			ref={cardRef}
			// statusLine="info"
			isActive={isActive}
			onClick={() =>
				open({
					type: "work-order",
					id: workOrder.id,
				})
			}
		>
			<DetailsCardOverline icon={WrenchIcon}>
				Work Order
			</DetailsCardOverline>
			{/* <Button
				variant="ghost"
				size="icon"
				className="absolute right-1 top-1"
			>
				<EllipsisIcon className="size-4" />
			</Button> */}

			<DetailsCardTitle>
				{workOrder.year} {workOrder.make} {workOrder.model}
			</DetailsCardTitle>
			<DetailsCardSubtitle>
				{workOrder.vin} | {workOrder.licensePlate}
			</DetailsCardSubtitle>

			<div className="mt-1.5 flex flex-wrap items-center gap-1">
				<Chip variant="success">BAY {workOrder.bay}</Chip>
				<Chip
					variant={
						workOrder.status === "WAITING FOR PARTS" ?
							"error"
						:	"info"
					}
				>
					{workOrder.status}
				</Chip>
			</div>

			<DetailsCardInfoLinesWrapper>
				{workOrder.issues.length > 0 && (
					<DetailsCardInfoLine icon={Hammer} className="items-start">
						<div>{workOrder.issues.join(", ")}</div>
					</DetailsCardInfoLine>
				)}
				<DetailsCardInfoLine icon={User}>
					{workOrder.driver}
				</DetailsCardInfoLine>
				{/* <Button
					variant="outline"
					className="mt-2 gap-1 border-success-border/50 bg-success/30 text-lg font-bold"
					size="sm"
				>
					<TimerIcon className="text-success" />
					1h 34m
					<Avatar className="size-6">
						<AvatarFallback className="text-sm">
							{workOrder.driver}
						</AvatarFallback>
					</Avatar>
				</Button> */}
			</DetailsCardInfoLinesWrapper>
		</DetailsCard>
	)
}
