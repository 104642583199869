import React, { useCallback, useRef } from "react"
import type { Table as TanstackTable } from "@tanstack/react-table"
import { flexRender } from "@tanstack/react-table"

import { PageHeader } from "@/components/ui/page"
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table"
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip"
import { Info } from "@/components/shared/page-info"

import { ViewSwitcherButtons } from "../layout/view-switcher"

export function Grid<TanstackTableSchema>({
	listDataState,
}: {
	listDataState: TanstackTable<TanstackTableSchema>
}): JSX.Element {
	const lastSelectedRowRef = useRef<string | null>(null)

	const handleRowClick = useCallback(
		(event: React.MouseEvent, rowId: string) => {
			const rows = listDataState.getRowModel().rows
			const currentRowIndex = rows.findIndex((row) => row.id === rowId)

			if (event.shiftKey && lastSelectedRowRef.current) {
				const lastRowIndex = rows.findIndex(
					(row) => row.id === lastSelectedRowRef.current,
				)

				// Calculate the range of rows to select
				const start = Math.min(currentRowIndex, lastRowIndex)
				const end = Math.max(currentRowIndex, lastRowIndex)

				// Create a new selection object
				const newSelection: Record<string, boolean> = {}
				for (let i = start; i <= end; i++) {
					if (rows[i] && rows[i]?.id) newSelection[rows[i]!.id] = true
				}

				// Merge with existing selection
				listDataState.setRowSelection((prev) => ({
					...prev,
					...newSelection,
				}))
			} else if (event.ctrlKey || event.metaKey) {
				// Check if the row is already selected
				listDataState.getRow(rowId).toggleSelected()
			} else {
				// Handle single selection
				listDataState.setRowSelection({ [rowId]: true })
			}

			lastSelectedRowRef.current = rowId
		},
		[listDataState],
	)

	return (
		<>
			<PageHeader>
				<Info />
				<ViewSwitcherButtons />
			</PageHeader>
			<Table>
				<TableHeader>
					{listDataState.getHeaderGroups().map((headerGroup) => (
						<TableRow key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								const columnHeader =
									header.isPlaceholder ? null : (
										flexRender(
											header.column.columnDef.header,
											header.getContext(),
										)
									)

								return (
									<TooltipProvider
										key={header.id}
										delayDuration={300}
									>
										<Tooltip key={header.id}>
											<TooltipTrigger asChild>
												<TableHead
													key={header.id}
													className="min-w-[40px] truncate px-3 capitalize"
												>
													{columnHeader}
												</TableHead>
											</TooltipTrigger>
											{typeof columnHeader ===
												"string" && (
												<TooltipContent side="bottom">
													{columnHeader}
												</TooltipContent>
											)}
										</Tooltip>
									</TooltipProvider>
								)
							})}
						</TableRow>
					))}
				</TableHeader>
				<TableBody>
					{listDataState.getRowModel().rows.map((row) => (
						<TableRow
							key={row.id}
							// data-state={row.getIsSelected() && "selected"}
							onClick={(e) => handleRowClick(e, row.id)}
						>
							{row.getVisibleCells().map((cell) => (
								<TableCell
									key={cell.id}
									className="min-w-[50px] truncate px-3 capitalize"
								>
									{flexRender(
										cell.column.columnDef.cell,
										cell.getContext(),
									)}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</>
	)
}
