import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import { getTableSelectColumns } from "@/lib/utils/data/validators"
import { customerContactTable } from "@/server/tables/customer"

const customerContactOmitFields = {
	scopeId: true,
	createdAt: true,
	updatedAt: true,
} as const
export const customerContactSelectColumns = {
	...getTableSelectColumns(customerContactTable, customerContactOmitFields),
}

export const customerContactSchema = createSelectSchema(
	customerContactTable,
).omit(customerContactOmitFields)
export type CustomerContact = z.infer<typeof customerContactSchema>

export const newCustomerContactSchema = createInsertSchema(
	customerContactTable,
).omit(customerContactOmitFields)
export type NewCustomerContact = z.infer<typeof newCustomerContactSchema>
