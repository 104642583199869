import { PlusIcon } from "lucide-react"

import type { Vendor } from "@/server/schemas"
import { Button } from "@/components/ui/button"
import { DetailsSection } from "@/components/ui/details-view"
import { ContactDetailsCard } from "@/components/features/contact/details/card"

export function VendorContactsSection({ vendor }: { vendor: Vendor }) {
	return (
		<DetailsSection>
			<div className="flex items-center gap-2">
				<div className="text-lg font-bold">Contacts</div>
			</div>
			<div className="flex flex-wrap gap-2">
				{vendor.contacts.length > 0 ?
					vendor.contacts.map((contact) => (
						<ContactDetailsCard
							key={contact.id}
							contact={contact}
						/>
					))
				:	<div className="w-full p-4 text-center text-foreground-weak">
						No Contacts
					</div>
				}
			</div>
			<Button
				variant="outline"
				className="mt-2 w-full gap-2 border-dashed border-border text-primary shadow-none"
			>
				<PlusIcon className="size-4" />
				Add Contact
			</Button>
		</DetailsSection>
	)
}
