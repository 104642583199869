import { trpcClient } from "@/trpc/client"

import type { DetailsViewComponent } from "@/components/shared/details/details-view"
import { DetailsViewContainer } from "@/components/shared/details/details-view"

import { LocationContactsSection } from "./sections/contacts"
import { LocationInfoSection } from "./sections/info"
import { LocationPageHeader } from "./sections/page-header"
import { LocationPanelHeader } from "./sections/panel-header"

export const LocationDetailsView: DetailsViewComponent = (props) => {
	const { id: locationId } = props
	const [result] = trpcClient.locations.getLocationById.useSuspenseQuery({
		id: parseInt(locationId),
	})

	if (!result.success) {
		throw new Error("Failed to fetch location")
	}

	return (
		<DetailsViewContainer
			data={result.value}
			headers={{ page: LocationPageHeader, panel: LocationPanelHeader }}
			sections={{
				info: LocationInfoSection,
				contacts: LocationContactsSection,
			}}
		/>
	)
}
