import { createContext, useContext, useEffect, useMemo, useState } from "react"

import { TooltipProvider } from "@/components/ui/tooltip"

import type { MockShopWatchActivity } from "../../shop-watch/mock-data"

interface ShopWatchTeamViewUserContext {
	selectedActivity: MockShopWatchActivity | null
	setSelectedActivity: React.Dispatch<
		React.SetStateAction<MockShopWatchActivity | null>
	>
	isListOpen: boolean
	setIsListOpen: React.Dispatch<React.SetStateAction<boolean>>
	isRunning: boolean
	setIsRunning: React.Dispatch<React.SetStateAction<boolean>>
	time: number
	setTime: React.Dispatch<React.SetStateAction<number>>
	timeTotal: number
	setTimeTotal: React.Dispatch<React.SetStateAction<number>>
	clearShopWatchTimers: () => void
}

// Context & Hook
const ShopWatchTeamViewUserContext =
	createContext<ShopWatchTeamViewUserContext | null>(null)

export const useShopWatchTeamViewUser = () => {
	const context = useContext(ShopWatchTeamViewUserContext)
	if (!context) {
		throw new Error(
			"useShopWatchTeamViewUser must be used within a ShopWatchTeamViewUserProvider",
		)
	}
	return context
}

// Provider Component
export const ShopWatchTeamViewUserProvider = ({
	children,
}: {
	children: React.ReactNode
}) => {
	const [selectedActivity, setSelectedActivity] =
		useState<MockShopWatchActivity | null>(null)
	const [time, setTime] = useState(0) // Time in seconds
	const [timeTotal, setTimeTotal] = useState(0) // Time in seconds
	const [isRunning, setIsRunning] = useState(false)
	const [isListOpen, setIsListOpen] = useState(false)

	const clearShopWatchTimers = () => {
		// Select new item, reset timers, etc
		setIsRunning(false)
		setTime(0)
	}

	useEffect(() => {
		let timer: NodeJS.Timeout | undefined
		if (isRunning) {
			timer = setInterval(() => {
				setTime((prevTime) => prevTime + 1)
			}, 1000)
		} else if (!isRunning && time !== 0) {
			clearInterval(timer)
		}
		return () => clearInterval(timer)
	}, [isRunning, time])

	const contextValue: ShopWatchTeamViewUserContext = useMemo(
		() => ({
			selectedActivity,
			setSelectedActivity,
			time,
			setTime,
			timeTotal,
			setTimeTotal,
			isListOpen,
			setIsListOpen,
			isRunning,
			setIsRunning,
			clearShopWatchTimers,
		}),
		[
			selectedActivity,
			setSelectedActivity,
			time,
			setTime,
			timeTotal,
			isListOpen,
			setIsListOpen,
			setTimeTotal,
			isRunning,
			setIsRunning,
		],
	)

	return (
		<ShopWatchTeamViewUserContext.Provider value={contextValue}>
			<TooltipProvider delayDuration={0}>{children}</TooltipProvider>
		</ShopWatchTeamViewUserContext.Provider>
	)
}
