export interface MockShopWatchActivity {
	id?: string
	name?: string
	estimate?: number
}

export interface MockShopWatchTask {
	id?: string
	type?: string
	name?: string
}

export const MockShopWatchActivities: MockShopWatchActivity[] = [
	{
		id: "18-0044",
		name: "Oil Change",
		estimate: 165,
	},
	{
		id: "18-0182",
		name: "Check/Repear Leaks",
		estimate: 45,
	},
	{
		id: "18-1465",
		name: "Rotate Tires (4)",
		estimate: 30,
	},
	{
		id: "18-043",
		name: "Look at vehicle with eyes",
		estimate: 1,
	},
	{
		id: "18-931",
		name: "Break pad replacement - front 2",
		estimate: 150,
	},
]

export const MockShopWatchTasks: MockShopWatchTask[] = [
	{
		id: "861",
		type: "task",
		name: "Clean Workstation",
	},
	{
		id: "524",
		type: "task",
		name: "Waste Disposal",
	},
	{
		id: "321",
		type: "task",
		name: "Parts Delivery",
	},
	{
		id: "311",
		type: "task",
		name: "Load/unload Equipment",
	},
]
