import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import type { z } from "zod"

import {
	getTableSelectColumns,
	jsonBuildArray,
	jsonBuildObject,
} from "@/lib/utils/data/validators"
import { customerTable } from "@/server/tables/customer"

import { metaSchema, newMetaSchema, newTagSchema, tagSchema } from "../shared"
import {
	customerAddressSchema,
	customerAddressSelectColumns,
	newCustomerAddressSchema,
} from "./customerAddress"
import {
	customerBillingSchema,
	customerBillingSelectColumns,
	newCustomerBillingSchema,
} from "./customerBilling"
import {
	customerContactSchema,
	customerContactSelectColumns,
	newCustomerContactSchema,
} from "./customerContact"
import { customerMetaSelectColumns } from "./customerMeta"
import { customerTagSelectColumns } from "./customerTag"

const customerOmitFields = {
	scopeId: true,
	createdAt: true,
	updatedAt: true,
	isDeleted: true,
	deletedDate: true,
} as const
export const customerSelectColumns = {
	...getTableSelectColumns(customerTable, customerOmitFields),
	billing: jsonBuildObject(customerBillingSelectColumns),
	contacts: jsonBuildArray(customerContactSelectColumns),
	addresses: jsonBuildArray(customerAddressSelectColumns),
	metas: jsonBuildArray(customerMetaSelectColumns),
	tags: jsonBuildArray(customerTagSelectColumns),
}

export const customerSchema = createSelectSchema(customerTable)
	.omit(customerOmitFields)
	.extend({
		billing: customerBillingSchema.nullable(),
		contacts: customerContactSchema.array(),
		addresses: customerAddressSchema.array(),
		metas: metaSchema.array(),
		tags: tagSchema.array(),
	})
export type Customer = z.infer<typeof customerSchema>

export const newCustomerSchema = createInsertSchema(customerTable)
	.omit(customerOmitFields)
	.extend({
		billing: newCustomerBillingSchema.nullable().default(null),
		contacts: newCustomerContactSchema.array().default([]),
		addresses: newCustomerAddressSchema.array().default([]),
		metas: newMetaSchema.array().default([]),
		tags: newTagSchema.array().default([]),
	})
export type NewCustomer = z.infer<typeof newCustomerSchema>

// Add/Edit form schema
// TODO: Add validation
export const customerFormSchema = newCustomerSchema
