import { CalendarIcon, CircleEllipsisIcon, Package2 } from "lucide-react"

import {
	DetailsCard,
	DetailsCardInfoLine,
	DetailsCardInfoLinesWrapper,
	DetailsCardOverline,
	DetailsCardSubtitle,
	DetailsCardTitle,
} from "@/components/ui/details-card"

//TODO: Type this
// interface PurchaseOrderProps {}

//TODO: Implement this with real data
export function PurchaseOrderDetailsCard() {
	/* { purchaseOrder }: PurchaseOrderProps */
	return (
		<DetailsCard className="@container">
			<DetailsCardOverline icon={Package2}>
				Purchase Order
			</DetailsCardOverline>
			{/* <Button
				variant="ghost"
				size="icon"
				className="absolute right-1 top-1"
			>
				<EllipsisIcon className="size-4" />
			</Button> */}

			<DetailsCardTitle>Bob's Best Parts</DetailsCardTitle>
			<DetailsCardSubtitle>PO 15239012</DetailsCardSubtitle>

			<DetailsCardInfoLinesWrapper>
				<DetailsCardInfoLine icon={CalendarIcon}>
					Created 10/31/24
				</DetailsCardInfoLine>
				<DetailsCardInfoLine icon={CircleEllipsisIcon}>
					Submitted 11/1/24
				</DetailsCardInfoLine>
			</DetailsCardInfoLinesWrapper>

			{/* <Button
				variant="outline"
				className="bottom-2 right-2 ml-2 mt-2 gap-1 @sm:absolute"
				size="sm"
			>
				<BoxesIcon className="size-4 text-foreground-weak" />{" "}
				<p className="font-bold">326</p>
				<p>items</p>
			</Button> */}
		</DetailsCard>
	)
}
