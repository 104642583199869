import { getTableSelectColumns } from "@/lib/utils/data/validators"
import { vendorMetaTable } from "@/server/tables"

export const vendorMetaSelectColumns = {
	...getTableSelectColumns(vendorMetaTable, {
		scopeId: true,
		createdAt: true,
		updatedAt: true,
	}),
}
