import type { WorkOrder } from "@/routes/_main+/work+/work-orders/columns"
import type { Table as TanstackTable } from "@tanstack/react-table"
import { CircleCheckBigIcon, ListIcon, RotateCwIcon } from "lucide-react"

import { BoardView } from "@/components/shared/board"
import { WorkOrderDetailCard } from "@/components/features/work-order/details/card"

export function WorkOrderBoard({
	listDataState,
}: {
	listDataState: TanstackTable<WorkOrder>
}) {
	return (
		<BoardView<WorkOrder>
			listDataState={listDataState}
			columns={[
				{
					key: "todo",
					title: "To Do",
					icon: ListIcon,
					matcher: (item) => item.status === "Out Of Service",
				},
				{
					key: "in-progress",
					title: "In Progress",
					icon: RotateCwIcon,
					matcher: (item) => item.status === "In Maintenance",
				},
				{
					key: "done",
					title: "Done",
					icon: CircleCheckBigIcon,
					matcher: (item) => item.status === "Available",
				},
			]}
			renderCard={(item, isActive) => (
				<WorkOrderDetailCard workOrder={item} isActive={isActive} />
			)}
		/>
	)
}
