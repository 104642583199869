import type { FakeEquipment } from "@/routes/_main+/resources+/equipment/fake-equipment"
import { DollarSignIcon } from "lucide-react"

import { DetailsSectionsGrouping } from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

import { EquipmentAccountingSection } from "./accounting"
import { EquipmentLeaseSection } from "./lease"
import { EquipmentValueSection } from "./value"

export const EquipmentFinancialSection: DetailsSectionComponent<
	FakeEquipment
> = ({ data: equipment }) => (
	<DetailsSectionsGrouping>
		<EquipmentValueSection />
		<EquipmentLeaseSection />
		<EquipmentAccountingSection />
	</DetailsSectionsGrouping>
)
EquipmentFinancialSection.label = "Financial"
EquipmentFinancialSection.icon = DollarSignIcon
