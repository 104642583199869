import { trpcClient } from "@/trpc/client"

import { DetailsViewContainer } from "@/components/shared/details/details-view"
import type { DetailsViewComponent } from "@/components/shared/details/details-view"

import { PartHistorySection } from "./sections/history"
import { PartInfoSection } from "./sections/info"
import { PartInventorySection } from "./sections/inventory"
import { PartPageHeader } from "./sections/page-header"
import { PartPanelHeader } from "./sections/panel-header"
import { PartPurchaseOrdersSection } from "./sections/purchase-orders"
import { PartSettingsSection } from "./sections/settings"
import { PartVendorsSection } from "./sections/vendors"

export const PartDetailsView: DetailsViewComponent = (props) => {
	const { id: partId } = props
	const [result] = trpcClient.parts.getPartById.useSuspenseQuery({
		id: parseInt(partId),
	})

	if (!result.success) {
		throw new Error("Failed to fetch part")
	}

	return (
		<DetailsViewContainer
			data={result.value}
			headers={{ page: PartPageHeader, panel: PartPanelHeader }}
			sections={{
				info: PartInfoSection,
				settings: PartSettingsSection,
				inventory: PartInventorySection,
				purchaseOrders: PartPurchaseOrdersSection,
				vendors: PartVendorsSection,

				history: PartHistorySection,
			}}
		/>
	)
}
