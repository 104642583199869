import { forwardRef } from "react"
import { List } from "lucide-react"

import { cn } from "@/lib/utils/classnames"
import { Button } from "@/components/ui/button"
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover"

import { useShopWatch } from "../../context"
import { ShopWatchActivityDropdown } from "../activity-dropdown"
import { ShopWatchTotal } from "./totals"

export const ShopWatchFooter = forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
	const { selectedActivity, timeTotal, isListOpen, setIsListOpen } =
		useShopWatch()
	return (
		<div
			className={cn("flex h-[32px] items-center", className)}
			{...props}
			ref={ref}
		>
			{selectedActivity.id != null ?
				<Popover open={isListOpen} onOpenChange={setIsListOpen}>
					<PopoverTrigger asChild>
						<Button
							onClick={() => setIsListOpen(true)}
							size="sm"
							variant="ghost"
							className="px-1 text-primary-text"
						>
							<List />
						</Button>
					</PopoverTrigger>
					<PopoverContent
						align="end"
						side="right"
						className="size-auto p-0"
					>
						<ShopWatchActivityDropdown />
					</PopoverContent>
				</Popover>
			:	<></>}
			{timeTotal > 0 && <ShopWatchTotal className="ml-auto" />}
		</div>
	)
})
ShopWatchFooter.displayName = "ShopWatchFooter"
