import { cn } from "@/lib/utils/classnames"
import type { Tag } from "@/server/schemas"
import { Chip } from "@/components/ui/chip"

export function Tags({ tags, className }: { tags: Tag[]; className?: string }) {
	return (
		<div className={cn("flex flex-wrap gap-1", className)}>
			{tags.map((tag) => (
				<Chip
					variant="primary"
					key={tag.rowId.toString() + tag.tag}
					className="capitalize"
				>
					{tag.tag}
				</Chip>
			))}
		</div>
	)
}
