import { DollarSignIcon } from "lucide-react"
import { useFormContext } from "react-hook-form"

import {
	AddEditSection,
	AddEditSectionHeading,
	AddEditSectionsGrouping,
} from "@/components/ui/add-edit"
import { Checkbox } from "@/components/ui/checkbox"
import { DetailsInfoGroup } from "@/components/ui/details-view"
import { Input } from "@/components/ui/input"
import type { AddEditSectionDefinition } from "@/components/shared/add-edit"

export const EquipmentAddEditFinancial: AddEditSectionDefinition = {
	label: "Financial",
	icon: DollarSignIcon,
	component: <GroupedFinancialSections />,
}

function GroupedFinancialSections() {
	return (
		<AddEditSectionsGrouping>
			<ValueFormSection />
			<LeaseFormSection />
			<AccountingFormSection />
		</AddEditSectionsGrouping>
	)
}

function ValueFormSection() {
	const { register } = useFormContext()
	return (
		<AddEditSection columned>
			<AddEditSectionHeading>Value</AddEditSectionHeading>
			<DetailsInfoGroup label="Book value">
				<Input {...register("bookValue")} placeholder={"$100,000"} />
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Residual value">
				<Input
					{...register("residualValue")}
					placeholder={"$100,000"}
				/>
			</DetailsInfoGroup>
			<DetailsInfoGroup label="In service date">
				<Input
					{...register("inServiceDate")}
					placeholder={"MM/DD/YYYY"}
				/>
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Useful months after">
				<Input {...register("usefulMonthsAfter")} placeholder={"60"} />
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Depreciation Code">
				<Input {...register("depreciationCode")} placeholder={"AB1"} />
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Original purchase info">
				<Input
					{...register("originalPurchaseInfo")}
					placeholder={"12345678"}
				/>
			</DetailsInfoGroup>
			<div className="col-span-full flex items-center gap-1">
				<Checkbox
					{...register("applyDistributedLabor")}
					id="applyDistributedLabor"
				/>
				Apply distributed labor
			</div>
			<div className="col-span-full flex items-center gap-1">
				<Checkbox
					{...register("applyDistributedMisc")}
					id="applyDistributedMisc"
				/>
				Apply distributed misc
			</div>
		</AddEditSection>
	)
}

function LeaseFormSection() {
	const { register } = useFormContext()
	return (
		<AddEditSection columned>
			<AddEditSectionHeading>Lease</AddEditSectionHeading>
			<DetailsInfoGroup label="Lease number">
				<Input {...register("leaseNumber")} placeholder={"12345678"} />
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Monthly lease fee">
				<Input {...register("monthlyLeaseFee")} placeholder={"$100"} />
			</DetailsInfoGroup>
		</AddEditSection>
	)
}

function AccountingFormSection() {
	const { register } = useFormContext()
	return (
		<AddEditSection columned>
			<AddEditSectionHeading>Accounting</AddEditSectionHeading>
			<DetailsInfoGroup label="Flat rate maintenance billing code">
				<Input
					{...register("flatRateMaintenanceBillingCode")}
					placeholder={"TRK500"}
				/>
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Department">
				<Input
					{...register("department")}
					placeholder={"1000 - Fleet Maintenance"}
				/>
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Expense category">
				<Input
					{...register("expenseCategory")}
					placeholder={"001 - Operating expenses"}
				/>
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Unit">
				<Input
					{...register("unit")}
					placeholder={"0001 - North Region"}
				/>
			</DetailsInfoGroup>
		</AddEditSection>
	)
}
