import { ListCheck, User, Users2 } from "lucide-react"
import { AnimatePresence, motion } from "motion/react"

import { Button } from "@/components/ui/button"

import { useNavbar } from "../navbar/context"
import { ShopWatchTrackingCollapsed } from "./collapsed/tracking"
import { useShopWatch } from "./context"
import { ShopWatchExpanded } from "./expanded"
import { useShopWatchGlobal } from "./global-context"

export const ShopWatch = () => {
	const { isExpanded } = useNavbar()
	const { time } = useShopWatch()
	const { isTeamView, setIsTeamViewOpen } = useShopWatchGlobal()

	return (
		<>
			{isTeamView ?
				<AnimatePresence>
					<motion.div
						initial={{ y: 5, opacity: 1 }}
						animate={{ y: 0, opacity: 1 }}
						exit={{ y: 5, opacity: 1 }}
						transition={{
							type: "spring",
							stiffness: 350,
							damping: 20,
							mass: 1,
						}}
					>
						<>
							{isExpanded ?
								<motion.div
									initial={{ y: -5, opacity: 0 }}
									animate={{ y: 0, opacity: 1 }}
									exit={{ y: -5, opacity: 0 }}
									transition={{
										duration: 1,
									}}
								>
									<Button
										onClick={() =>
											setIsTeamViewOpen(
												(prevValue) => !prevValue,
											)
										}
										variant="primary"
										size="lg"
										className="w-full px-1.5"
									>
										<Users2 size={18} />

										<div className="ml-2">Team Clocks</div>
									</Button>
									<div className="mt-2 flex gap-4 px-4">
										<div className="flex items-center gap-0.5">
											<User
												size={16}
												className="text-foreground-weaker"
											/>
											5
										</div>

										<div className="flex items-center gap-0.5">
											<ListCheck
												size={16}
												className="text-foreground-weaker"
											/>
											12
										</div>

										<div className="ml-auto flex items-center gap-2">
											<div className="size-2 rounded-full bg-success-weak" />
											<div className="text-sm uppercase tracking-wider text-foreground-weak">
												Active
											</div>
										</div>
									</div>
								</motion.div>
							:	<motion.div
									initial={{ y: -5, opacity: 0 }}
									animate={{ y: 0, opacity: 1 }}
									exit={{ y: -5, opacity: 0 }}
									transition={{
										duration: 1,
									}}
								>
									<Button
										onClick={() =>
											setIsTeamViewOpen(
												(prevValue) => !prevValue,
											)
										}
										variant="primary"
										size="lg"
										className="w-full px-1.5"
									>
										<Users2 size={24} />
									</Button>
								</motion.div>
							}
						</>
					</motion.div>
				</AnimatePresence>
			:	<>
					{isExpanded ?
						<AnimatePresence>
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{
									duration: 0.3,
									ease: "easeInOut",
								}}
							>
								<ShopWatchExpanded />
							</motion.div>
						</AnimatePresence>
					:	<>
							{time > 0 && (
								<AnimatePresence>
									<motion.div
										initial={{
											opacity: 0,
											width: 48,
										}}
										animate={{
											opacity: 1,
											width: 48,
										}}
										exit={{ opacity: 0, width: 48 }}
										layout
										transition={{
											duration: 1,
											ease: "easeInOut",
										}}
									>
										<ShopWatchTrackingCollapsed />
									</motion.div>
								</AnimatePresence>
							)}
						</>
					}
				</>
			}
		</>
	)
}
