import type { FunctionComponent } from "react"
import type React from "react"
import type { LucideIcon } from "lucide-react"

import { useDetailsState } from "@/lib/hooks/use-details-state"
import {
	DetailsHeader,
	DetailsPage,
	DetailsPageBody,
	DetailsPanel,
	DetailsPanelBody,
	DetailsTabs,
	DetailsTabsContent,
	DetailsTabsList,
	DetailsTabsTrigger,
} from "@/components/ui/details-view"
import { MasonryLayout } from "@/components/ui/masonry"

/**
 * @description  An implementation of this component should take a single 'id' prop
 * 				 and should house a corresponding {@link DetailsViewContainer} component
 * @usage
 * ```tsx
 * export const ExampleDetailsView: DetailsViewComponent = (props) => {
 * 	const { id } = props
 * 	const { data } = some-api.getData(id)
 * 	return <DetailsViewContainer data={data} headers={...} sections={...} />
 * }
 * ```
 */
export type DetailsViewComponent = React.FunctionComponent<{
	id: string
}>
export type MultiDetailsViewComponent = React.FunctionComponent<{
	ids: string[]
}>

/**
 * @description A container component that, when passed appropriate Details sections and headers,
 *              will render a {@link DetailsPage} or {@link DetailsPanel} component based on the screen size.
 * @param headers - The page and panel headers to use - These components MUST accept a `data` prop.
 * @param sections - The sections to render - These components MUST accept a `data` prop.
 * @param data - The item being displayed in the details view. This will be passed to the headers and sections.
 * @usage
 * ```tsx
 * <DetailsViewContainer
 * 	headers={{
 * 		page: ExamplePageHeader,
 * 		panel: ExamplePanelHeader,
 * 	}}
 * 	sections={{
 * 		info: {
 * 			label: "Info",
 * 			icon: InfoIcon,
 * 			content: ExampleInfoSection,
 * 		},
 * 		history: {
 * 			label: "History",
 * 			icon: TimerIcon,
 * 			content: ExampleHistorySection,
 * 		},
 * 	}}
 * 	data={exampleData}
 * />
 */
export const DetailsViewContainer = <T,>({
	headers,
	data,
	sections,
}: DetailsViewContainerProps<T>) => {
	const { page: PageHeader, panel: PanelHeader } = headers
	const { tab, setTab } = useDetailsState()
	return (
		<div className="size-full @container">
			{/* At larger screens, show the page */}
			<DetailsPage className="hidden @container @3xl:flex">
				<DetailsHeader className="border-b-2 border-border-weak pb-3 pr-3">
					<PageHeader data={data} />
					{/* <Separator className="h-[2px] bg-border-weak" /> */}
				</DetailsHeader>
				<DetailsPageBody>
					<MasonryLayout>
						{Object.entries(sections).map(
							([key, SectionComponent]) => (
								<SectionComponent key={key} data={data} />
							),
						)}
					</MasonryLayout>
				</DetailsPageBody>
			</DetailsPage>

			{/* At smaller screens, show the panel */}
			<DetailsTabs
				defaultValue={Object.keys(sections)[0]}
				value={tab}
				onValueChange={setTab}
				className="@3xl:hidden"
			>
				<DetailsPanel>
					<DetailsHeader>
						<PanelHeader data={data} />
						<DetailsTabsList className="mt-2">
							{Object.entries(sections).map(
								([key, SectionComponent]) => (
									<DetailsTabsTrigger
										key={key}
										value={key}
										title={SectionComponent.label}
										Icon={SectionComponent.icon}
									>
										<span className="hidden truncate @[60px]:block">
											{SectionComponent.label}
										</span>
									</DetailsTabsTrigger>
								),
							)}
						</DetailsTabsList>
					</DetailsHeader>
					<DetailsPanelBody>
						{Object.entries(sections).map(
							([key, SectionComponent]) => (
								<DetailsTabsContent key={key} value={key}>
									<SectionComponent data={data} />
								</DetailsTabsContent>
							),
						)}
					</DetailsPanelBody>
				</DetailsPanel>
			</DetailsTabs>
		</div>
	)
}

interface DetailsViewContainerProps<T = void> {
	headers: {
		page: DetailsHeaderContent<T>
		panel: DetailsHeaderContent<T>
	}
	data: T
	sections: Record<string, DetailsSectionComponent<T>>
}

export type DetailsHeaderContent<T = void> = FunctionComponent<{
	data: T
}>
export type DetailsSectionComponent<T = void> = FunctionComponent<{
	data: T
}> & {
	label: string
	icon: LucideIcon
}
