import { pgTable, primaryKey } from "drizzle-orm/pg-core"

import { tagFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"
import { vendorTable } from "./vendor"

export const vendorTagTable = pgTable(
	"vendor_tag",
	tagFields(scopeTable, vendorTable),
	(table) => [primaryKey({ columns: [table.rowId, table.tag] })],
)
