import { trpcClient } from "@/trpc/client"
import { useDebounce } from "use-debounce"

import { CommandGroup } from "@/components/ui/command"

import { EmployeeSearchItem } from "./search-item"

export function EmployeeSearchPage({ search }: { search: string }) {
	const [debouncedSearch] = useDebounce(search, 300)
	const { data } = trpcClient.employees.fullSearch.useQuery(
		{ query: debouncedSearch.trim() },
		{ placeholderData: (prev) => prev },
	)

	if (!data?.length) return null

	return (
		<CommandGroup heading="Employees">
			{data.map((result) => (
				<EmployeeSearchItem key={result.item.id} {...result} />
			))}
		</CommandGroup>
	)
}
