import { useNavigate } from "@remix-run/react"
import type { FuseResultMatch } from "fuse.js"

import type { Location } from "@/server/schemas"
import { Avatar, AvatarFallback } from "@/components/ui/avatar"
import { CommandItem } from "@/components/ui/command"

import { useCommandMenu } from "../../command-menu-context"
import { HighlightedText } from "../../highlighted-text"

export function LocationSearchItem({
	item: location,
	matches,
}: {
	item: Location
	matches: readonly FuseResultMatch[] | undefined
}) {
	const navigate = useNavigate()
	const { setOpen } = useCommandMenu()

	// Helper to check if a field has matches
	const hasMatch = (path: string) => matches?.some((m) => m.key === path)

	// Helper to check if any address field matches
	const hasAddressMatch = matches?.some(
		(m) =>
			m.key === "street1" ||
			m.key === "street2" ||
			m.key === "city" ||
			m.key === "state" ||
			m.key === "zip",
	)

	// Get all matched fields excluding name and address-related fields
	const hasNonAddressMatch = matches?.some(
		(m) =>
			!["street1", "street2", "city", "state", "zip"].includes(
				m.key ?? "",
			) && m.key !== "name",
	)

	// Find the first matching contact
	const matchingContact = location.contacts.find((contact) =>
		matches?.some(
			(m) =>
				(m.key === "contacts.name" && m.value === contact.name) ||
				(m.key === "contacts.email" && m.value === contact.email) ||
				(m.key === "contacts.phone" && m.value === contact.phone),
		),
	)

	const getAddressFieldPositions = () => {
		let currentPosition = 0
		const positions: { [key: string]: { start: number; end: number } } = {}

		const fields = [
			{ key: "street1", value: location.street1 },
			{ key: "street2", value: location.street2 },
			{ key: "city", value: location.city },
			{ key: "state", value: location.state },
			{ key: "zip", value: location.zip },
		].filter((field) => field.value)

		fields.forEach((field, index) => {
			positions[field.key] = {
				start: currentPosition,
				end: currentPosition + (field.value?.length ?? 0) - 1,
			}

			currentPosition += field.value?.length ?? 0
			if (index < fields.length - 1) {
				currentPosition += 2 // ", " = 2 characters
			}
		})

		return positions
	}

	return (
		<CommandItem
			className="flex items-center justify-between"
			value={location.id.toString()}
			onSelect={() => {
				setOpen(false)
				navigate(
					`/resources/locations?details_type=location&details_id=${location.id}`,
				)
			}}
		>
			<div className="flex items-center gap-3">
				<Avatar>
					<AvatarFallback>{location.name}</AvatarFallback>
				</Avatar>
				<div className="flex flex-col items-start gap-1">
					<HighlightedText
						text={location.name}
						matches={matches}
						path="name"
						className="font-medium"
					/>
					<div className="flex flex-col text-sm text-foreground-weak">
						{/* Show ID if it matches */}
						{hasMatch("id") && (
							<span>
								ID:{" "}
								<HighlightedText
									text={location.id.toString()}
									matches={matches}
									path="id"
									className="font-medium"
								/>
							</span>
						)}

						{/* Show account code if it matches */}
						{hasMatch("accountCode") && location.accountCode && (
							<span>
								Account:{" "}
								<HighlightedText
									text={location.accountCode}
									matches={matches}
									path="accountCode"
									className="font-medium"
								/>
							</span>
						)}

						{/* Show contact info if any contact field matches */}
						{matchingContact && (
							<div className="flex gap-2">
								{matchingContact.name &&
									hasMatch("contacts.name") && (
										<span>
											Contact:{" "}
											<HighlightedText
												text={matchingContact.name}
												matches={matches}
												path="contacts.name"
												className="font-medium"
											/>
										</span>
									)}
								{matchingContact.email &&
									hasMatch("contacts.email") && (
										<span>
											Email:{" "}
											<HighlightedText
												text={matchingContact.email}
												matches={matches}
												path="contacts.email"
												className="font-medium"
											/>
										</span>
									)}
								{matchingContact.phone &&
									hasMatch("contacts.phone") && (
										<span>
											Phone:{" "}
											<HighlightedText
												text={matchingContact.phone}
												matches={matches}
												path="contacts.phone"
												className="font-medium"
											/>
										</span>
									)}
							</div>
						)}

						{/* Show address only if there are no other matches or if address fields match */}
						{(!hasNonAddressMatch || hasAddressMatch) && (
							<span>
								Address:{" "}
								<HighlightedText
									text={[
										location.street1,
										location.street2,
										location.city,
										location.state,
										location.zip,
									]
										.filter(Boolean)
										.join(", ")}
									matches={matches}
									paths={[
										"street1",
										"street2",
										"city",
										"state",
										"zip",
									]}
									fieldPositions={getAddressFieldPositions()}
									className="font-medium"
								/>
							</span>
						)}
					</div>
				</div>
			</div>
		</CommandItem>
	)
}
