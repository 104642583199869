import { sql } from "drizzle-orm"
import {
	boolean,
	check,
	integer,
	PgColumn,
	text,
	timestamp,
} from "drizzle-orm/pg-core"
import type { PgTableWithColumns } from "drizzle-orm/pg-core"

/**
 * Default timestamp fields used in most Drizzle tables.
 *
 * @param createdAt `timestamp("created_at")`
 * @param updatedAt `timestamp("updated_at")`
 *
 * @example
 * export const exampleTable = pgTable("example_table", {
 * 	id: serial("id").primaryKey(),
 * 	...timestampFields,
 * })
 */
export const timestampFields = {
	createdAt: timestamp("created_at", { mode: "string" }).defaultNow(),
	updatedAt: timestamp("updated_at", { mode: "string" })
		.defaultNow()
		.$onUpdate(() => sql`current_timestamp`),
}

/**
 * Default activity fields used in all master Drizzle tables.
 *
 * @param isActive `boolean("is_active")`
 * @param isDeleted `boolean("is_deleted")`
 * @param deletedDate `timestamp("deleted_date")`
 *
 * @example
 * export const exampleTable = pgTable("example_table", {
 * 	id: serial("id").primaryKey(),
 * 	...activeFields,
 * })
 */
export const activeFields = {
	isActive: boolean("is_active").notNull().default(true),
	isDeleted: boolean("is_deleted").notNull().default(false),
	deletedDate: timestamp("deleted_date", { mode: "string" }),
}

/**
 * Default fields used in all Address tables.
 *
 * @param street1 `text("street1").notNull()`
 * @param street2 `text("street2")`
 * @param city `text("city").notNull()`
 * @param state `text("state").notNull()`
 * @param zip `text("zip")`
 *
 * @example
 * export const exampleAddressTable = pgTable("example_address_table", {
 * 	id: serial("id").primaryKey(),
 * 	...addressFields,
 * })
 */
export const addressFields = {
	street1: text("street1").notNull(),
	street2: text("street2"),
	city: text("city").notNull(),
	state: text("state").notNull(),
	zip: text("zip"),
}

/**
 * Default fields used in all Contact tables.
 *
 * @param name `text("name").notNull()`
 * @param title `text("title")`
 * @param phone `text("phone")`
 * @param email `text("email")`
 *
 * @example
 * export const exampleContactTable = pgTable("example_contact_table", {
 * 	id: serial("id").primaryKey(),
 * 	...contactFields,
 * })
 */
export const contactFields = {
	name: text("name").notNull(),
	title: text("title"),
	phone: text("phone"),
	email: text("email"),
}

/**
 * Default fields used in all Meta tables.
 * Note that the primary key, `field`, should be used as part of a composite key with the parent table's id field.
 *
 * @param scopeId `integer("scope_id").references(() => scopeTable.id).notNull()`
 * @param rowId `integer("row_id").references(() => parentTable.id).notNull()`
 * @param field `text("field").notNull()`
 * @param value `text("value").notNull()`
 * @param shortName `text("shortName")`
 * @param description `text("description")`
 * @param createdAt `timestamp("created_at")`
 * @param updatedAt `timestamp("updated_at")`
 *
 * @example
 * export const exampleMetaTable = pgTable(
 * 	"example_meta_table",
 * 	metaFields(scopeTable, parentTable),
 * 	(table) => [primaryKey({ columns: [table.field, table.rowId] })]
 * )
 */
export const metaFields = <
	S extends PgTableWithColumns<{
		name: string
		schema: undefined
		dialect: "pg"
		columns: { id: PgColumn }
	}>,
	P extends PgTableWithColumns<{
		name: string
		schema: undefined
		dialect: "pg"
		columns: { id: PgColumn }
	}>,
>(
	scopeTable: S,
	parentTable: P,
) => {
	return {
		scopeId: integer("scope_id")
			.references(() => scopeTable.id, { onDelete: "cascade" })
			.notNull(),
		rowId: integer("row_id")
			.references(() => parentTable.id, { onDelete: "cascade" })
			.notNull(),
		field: text("field").notNull(),
		value: text("value").notNull(),
		shortName: text("short_name"),
		description: text("description"),
		createdAt: timestamp("created_at", { mode: "string" }).defaultNow(),
		updatedAt: timestamp("updated_at", { mode: "string" })
			.defaultNow()
			.$onUpdate(() => sql`current_timestamp`),
	}
}

/**
 * Default fields used in all Tag tables.
 * Note that the primary key, `tag`, should be used as part of a composite key with the parent table's id field.
 *
 * @param scopeId `integer("scope_id").references(() => scopeTable.id).notNull()`
 * @param rowId `integer("row_id").references(() => parentTable.id).notNull()`
 * @param tag `text("tag").notNull()`
 *
 * @example
 * export const exampleTagTable = pgTable(
 * 	"example_tag_table",
 * 	tagFields(scopeTable, parentTable),
 * 	(table) => [primaryKey({ columns: [table.tag, table.rowId] })]
 * )
 */
export const tagFields = <
	S extends PgTableWithColumns<{
		name: string
		schema: undefined
		dialect: "pg"
		columns: { id: PgColumn }
	}>,
	P extends PgTableWithColumns<{
		name: string
		schema: undefined
		dialect: "pg"
		columns: { id: PgColumn }
	}>,
>(
	scopeTable: S,
	parentTable: P,
) => {
	return {
		scopeId: integer("scope_id")
			.references(() => scopeTable.id, { onDelete: "cascade" })
			.notNull(),
		rowId: integer("row_id")
			.references(() => parentTable.id, { onDelete: "cascade" })
			.notNull(),
		tag: text("tag").notNull(),
	}
}

/**
 * Default fields used in all Note tables.
 *
 * @param scopeId `integer("scope_id").references(() => scopeTable.id).notNull()`
 * @param rowId `integer("row_id").references(() => parentTable.id).notNull()`
 * @param note `text("note").notNull()`
 *
 * @example
 * export const exampleNoteTable = pgTable(
 * 	"example_note_table",
 * 	noteFields(scopeTable, parentTable)
 * )
 */
export const noteFields = <
	S extends PgTableWithColumns<{
		name: string
		schema: undefined
		dialect: "pg"
		columns: { id: PgColumn }
	}>,
	P extends PgTableWithColumns<{
		name: string
		schema: undefined
		dialect: "pg"
		columns: { id: PgColumn }
	}>,
>(
	scopeTable: S,
	parentTable: P,
) => {
	return {
		scopeId: integer("scope_id")
			.references(() => scopeTable.id, { onDelete: "cascade" })
			.notNull(),
		rowId: integer("row_id")
			.references(() => parentTable.id, { onDelete: "cascade" })
			.notNull(),
		note: text("note").notNull(),
		...timestampFields,
	}
}

export const vmrsCodeFields = {
	code: text("code").primaryKey(),
	text: text("text").notNull(),
	details: text("details"),
	...activeFields,
}

export const accountCodeCheck = (column: PgColumn) =>
	check(
		"account_code_check",
		// Account code is in some format between 0.0.0.0 and 0000.0000.0000.0000 and each field can be a different size
		sql`${column} ~ '([0-9]{2,4}[.]){0,6}[0-9]{2,4}'`,
	)
