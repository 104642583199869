import { usePageInfo } from "@/lib/hooks/use-page-info"

import { PageGroup, PageInfo, PageTitle } from "../ui/page"

export function Info() {
	const { pageGroup, pageTitle } = usePageInfo()
	return (
		<PageInfo>
			<PageGroup>{pageGroup}</PageGroup>
			<PageTitle>{pageTitle}</PageTitle>
		</PageInfo>
	)
}
