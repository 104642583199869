import { forwardRef } from "react"

import { cn } from "@/lib/utils/classnames"

import { NAVBAR_ANIMATION_DURATION, NAVBAR_DIMENSIONS } from "../constants"
import { useNavbar } from "../context"

export const DesktopNavbarContainer = forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, style, ...props }, ref) => {
	const { state, isExpanded } = useNavbar()
	const navbarWidth =
		isExpanded ? NAVBAR_DIMENSIONS.EXPANDED : NAVBAR_DIMENSIONS.COLLAPSED

	return (
		<div
			ref={ref}
			data-state={state}
			style={
				{
					"--navbar-width": navbarWidth,
					"transitionDuration": `${NAVBAR_ANIMATION_DURATION}s`,
					...style,
				} as React.CSSProperties
			}
			className={cn(
				"flex w-[--navbar-width] flex-col overflow-hidden p-4 transition-[width] ease-out",
				className,
			)}
			{...props}
		/>
	)
})
DesktopNavbarContainer.displayName = "DesktopNavbarContainer"
