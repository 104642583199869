import { Gauge, Wrench } from "lucide-react"

import {
	DetailsCard,
	DetailsCardInfoLine,
	DetailsCardInfoLinesWrapper,
	DetailsCardOverline,
	DetailsCardSubtitle,
	DetailsCardTitle,
} from "@/components/ui/details-card"

//TODO: Type this
// interface MeterDetailCardProps {}

//TODO: Implement this with real data
export function MeterDetailsCard() {
	/* { purchaseOrder }: MeterDetailCardProps */
	return (
		<DetailsCard className="@container">
			<DetailsCardOverline icon={Gauge}>Meter</DetailsCardOverline>
			{/* <Button
				variant="ghost"
				size="icon"
				className="absolute right-1 top-1"
			>
				<EllipsisIcon className="size-4" />
			</Button> */}

			<DetailsCardTitle>Odometer</DetailsCardTitle>
			<DetailsCardSubtitle>Milebeam7300</DetailsCardSubtitle>

			<DetailsCardInfoLinesWrapper>
				<DetailsCardInfoLine icon={Wrench}>
					5 Services
				</DetailsCardInfoLine>
			</DetailsCardInfoLinesWrapper>
		</DetailsCard>
	)
}
