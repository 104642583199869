import { Check, Pause, Play, Trash } from "lucide-react"
import { AnimatePresence, motion } from "motion/react"

import { cn } from "@/lib/utils/classnames"
import { formatTimerFromSeconds } from "@/lib/utils/time-formatting-helpers"
import { Button } from "@/components/ui/button"

import { TimerProgressBar } from "../../shop-watch/expanded/components/progress-bar"
import { useShopWatchTeamViewUser } from "./context"

export const TeamViewUserTracking = () => {
	const {
		selectedActivity,
		isRunning,
		time,
		setTime,
		setTimeTotal,
		setSelectedActivity,
		setIsRunning,
	} = useShopWatchTeamViewUser()

	const done = () => {
		setTime(0)
		setIsRunning(false)
		setSelectedActivity(null)
		setTimeTotal((prevTimeTotal) => prevTimeTotal + time)
	}

	const cancel = () => {
		setTime(0)
		setIsRunning(false)
		setSelectedActivity(null)
	}

	return (
		<>
			<div>
				<div className="text-sm opacity-70">{selectedActivity?.id}</div>
				<div className="truncate font-bold leading-tight">
					{selectedActivity?.name}
				</div>
			</div>
			<div className="mt-2 flex items-end gap-2">
				<div
					className={cn(
						"flex h-[68px] grow flex-col justify-between rounded p-2 pl-2.5 pr-1.5 pt-1.5 transition-all",
						!isRunning && "bg-background-weaker",
						time > 0 && "bg-selected text-primary-weak",
						isRunning && "bg-success-weak text-success-foreground",
					)}
				>
					<div className="flex items-center">
						<div className="relative w-full flex-col leading-none">
							<div
								className={cn(
									"origin-top-left scale-100 text-3xl font-bold transition-all ease-in-out",
									time > 0 && !isRunning && "scale-[.65]",
								)}
							>
								{formatTimerFromSeconds(time)}
							</div>
							<div
								className={cn(
									"absolute bottom-0 left-0 h-0 origin-top-left scale-0 text-xs opacity-0 transition-all ease-in-out",
									time > 0 &&
										!isRunning &&
										"bottom-0 left-0 h-[12px] scale-100 opacity-100",
								)}
							>
								Paused
							</div>
						</div>
						{isRunning ?
							<Button
								onClick={() => setIsRunning(false)}
								variant="ghost"
								size="sm"
								className="px-1.5"
							>
								<Pause className="text-white" />
							</Button>
						:	<>
								<Button
									onClick={() => setIsRunning(true)}
									variant="ghost"
									size="sm"
									className="pl-1.5 pr-1"
								>
									<Play />
								</Button>
							</>
						}
					</div>
					<TimerProgressBar
						currentTimeSeconds={time}
						estimatedMinutes={selectedActivity?.estimate || 0}
						isRunning={isRunning}
					/>
				</div>
				{time > 0 && !isRunning && (
					<AnimatePresence>
						<motion.div
							className="flex flex-col gap-1"
							initial={{ opacity: 0, x: 10 }}
							animate={{
								opacity: 1,
								x: 0,
							}}
							transition={{
								duration: 0.3,
								type: "spring",
								stiffness: 350,
								damping: 10,
								mass: 0.1,
							}}
						>
							<Button
								onClick={() => cancel()}
								className="px-2"
								size="sm"
								variant="ghost"
							>
								<Trash size={18} className="text-error" />
							</Button>
							<Button
								onClick={() => done()}
								className="bg-success-weak px-2"
								variant="success"
								size="sm"
							>
								<Check size={18} />
							</Button>
						</motion.div>
					</AnimatePresence>
				)}
			</div>
		</>
	)
}
