import { useLocation } from "@remix-run/react"

import type { PageId } from "@/config/pages"
import { getPageFromPathname } from "@/config/pages"

/**
 * Hook to get the current page ID from the pathname
 * Throws if no matching page is found
 */
export function usePageId(pageId?: PageId): PageId {
	const { pathname } = useLocation()

	const id = pageId ?? getPageFromPathname(pathname)?.id
	if (!id) {
		throw new Error(
			"Could not determine page ID from pathname and none provided",
		)
	}
	return id
}
