import { pgTable, primaryKey } from "drizzle-orm/pg-core"

import { metaFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"
import { locationTable } from "./location"

export const locationMetaTable = pgTable(
	"location_meta",
	metaFields(scopeTable, locationTable),
	(table) => {
		return {
			pk: primaryKey({ columns: [table.field, table.rowId] }),
		}
	},
)
