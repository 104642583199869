import type { WorkOrder } from "@/routes/_main+/work+/work-orders/columns"
import { InfoIcon } from "lucide-react"

import { Card } from "@/components/ui/card"
import { Chip } from "@/components/ui/chip"
import { DetailsSection } from "@/components/ui/details-view"
import { Skeleton } from "@/components/ui/skeleton"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

export const WorkOrderInfoSection: DetailsSectionComponent<WorkOrder> = ({
	data: workOrder,
}) => (
	<DetailsSection>
		<div className="flex gap-2">
			<Skeleton className="h-[130px] w-[170px]" />
			<div className="flex grow flex-col gap-2">
				<div className="h-4 w-full">Driver: {workOrder.driver}</div>
				<div>License Plate: {workOrder.licensePlate}</div>
				<div>Bay: {workOrder.bay}</div>
			</div>
		</div>
		{workOrder.issues.map((issue, index) => (
			<Card key={index} className="flex w-full flex-col gap-2 p-3">
				<div className="flex justify-between">
					<div className="h-4 w-1/2">{issue}</div>
					<Chip
						className="w-fit"
						variant={index % 2 ? "warning" : "success"}
					>
						{index % 2 ? "On Hold" : "Ready"}
					</Chip>
				</div>
				<div className="h-4 w-3/4">Issue details...</div>
			</Card>
		))}
	</DetailsSection>
)
WorkOrderInfoSection.label = "Info"
WorkOrderInfoSection.icon = InfoIcon
