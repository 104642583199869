import { createContext, useCallback, useContext, useState } from "react"

import type { MultiDetailsView } from "@/config/details-view"

interface MultiState {
	type: MultiDetailsView
	ids: string[]
}

interface MultiStateContextProps {
	multiSelection: MultiState | null
	setMultiSelection: React.Dispatch<React.SetStateAction<MultiState | null>>
	clearMultiSelection: () => void
}

const MultiStateContext = createContext<MultiStateContextProps | undefined>(
	undefined,
)

export const useMultiState = (): MultiStateContextProps => {
	const context = useContext(MultiStateContext)
	if (!context) {
		throw new Error(
			"useMultiState must be used within a DetailsMultiStateProvider",
		)
	}
	return context
}

export const DetailsMultiStateProvider: React.FC<{
	children: React.ReactNode
}> = ({ children }) => {
	const [multiSelection, setMultiSelection] = useState<MultiState | null>(
		null,
	)

	const clearMultiSelection = useCallback(() => {
		setMultiSelection(null)
	}, [])

	return (
		<MultiStateContext.Provider
			value={{ multiSelection, setMultiSelection, clearMultiSelection }}
		>
			{children}
		</MultiStateContext.Provider>
	)
}
