import { PlusCircle } from "lucide-react"

import { Button } from "@/components/ui/button"
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover"

import { useShopWatch } from "../context"
import { ShopWatchActivityDropdown } from "./activity-dropdown"

export const ShopWatchStart = () => {
	const { isListOpen, setIsListOpen } = useShopWatch()
	return (
		<>
			<Popover open={isListOpen} onOpenChange={setIsListOpen}>
				<PopoverTrigger asChild>
					<Button
						onClick={() => setIsListOpen(true)}
						className="w-full"
						size="lg"
						variant="primary"
					>
						<PlusCircle size={18} className="mr-2" />
						Track Activity
					</Button>
				</PopoverTrigger>
				<PopoverContent
					align="end"
					side="right"
					className="size-auto p-0"
				>
					<ShopWatchActivityDropdown />
				</PopoverContent>
			</Popover>
		</>
	)
}
