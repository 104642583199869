import {
	DetailsInfoGroup,
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import { Link } from "@/components/ui/link"

export function EquipmentValueSection() {
	return (
		<DetailsSection columned>
			<DetailsSectionHeading className="col-start-1 col-end-3">
				Value
			</DetailsSectionHeading>
			<DetailsInfoGroup label="Book value">$24,000</DetailsInfoGroup>
			<DetailsInfoGroup label="Residual value">$22,500</DetailsInfoGroup>
			<DetailsInfoGroup label="In service date">1/23/24</DetailsInfoGroup>
			<DetailsInfoGroup label="Useful months after">72</DetailsInfoGroup>
			<DetailsInfoGroup label="Depreciation Code">AB1</DetailsInfoGroup>
			<DetailsInfoGroup label="Original purchase info">
				<Link to="/">PO 1518439313</Link>
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Apply distributed labor">
				Yes
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Apply distributed misc">
				No
			</DetailsInfoGroup>
		</DetailsSection>
	)
}
