import { pgTable, serial, text } from "drizzle-orm/pg-core"

import { activeFields, timestampFields } from "@/lib/utils/data/schema"

export const scopeTable = pgTable("scope", {
	id: serial("id").primaryKey(),
	clerkOrgId: text("clerk_org_id").notNull(),
	motorApiAccessLevel: text("motor_api_access_level", {
		enum: ["NONE", "BASIC", "PRO", "FULL"],
	})
		.notNull()
		.default("NONE"),
	...activeFields,
	...timestampFields,
})
