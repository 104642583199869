import { BoxesIcon, Package, Phone, UserIcon } from "lucide-react"

import type { Location } from "@/server/schemas"
import { Chip } from "@/components/ui/chip"
import {
	DetailsCard,
	DetailsCardInfoLine,
	DetailsCardOverline,
	DetailsCardSubtitle,
	DetailsCardTitle,
} from "@/components/ui/details-card"

interface LocationDetailsCardProps {
	location: Location
	//item: InventoryItem
	//TODO: Add item type
}

//TODO: Implement this with real data
export function LocationDetailsCard({ location }: LocationDetailsCardProps) {
	return (
		<DetailsCard className="@container">
			<DetailsCardOverline icon={BoxesIcon}>
				Inventory
			</DetailsCardOverline>
			{/* <Button
				variant="ghost"
				size="icon"
				className="absolute right-1 top-1"
			>
				<EllipsisIcon className="size-4" />
			</Button> */}

			<section>
				<DetailsCardTitle>{location.name}</DetailsCardTitle>
				<DetailsCardSubtitle>
					{location.street1} {location.street2}, {location.city},{" "}
					{location.state} {location.zip}
				</DetailsCardSubtitle>
			</section>
			<div className="mt-1 flex flex-wrap items-center gap-1">
				<Chip variant="success">In Stock</Chip>
			</div>
			<div className="mt-2 flex items-end">
				<div className="flex grow flex-col gap-1">
					<DetailsCardInfoLine icon={UserIcon}>
						Carl Contact
					</DetailsCardInfoLine>
					<DetailsCardInfoLine icon={Phone}>
						(681) 785-9913
					</DetailsCardInfoLine>
				</div>
				<div className="flex items-center gap-1 rounded-lg bg-success/30 p-1.5 text-sm">
					<Package className="text-success-text" size={18} />6 Units
				</div>
			</div>

			{/* <Button
				variant="outline"
				className="bottom-2 right-2 ml-2 mt-2 gap-1 border-success-border/50 bg-success/30 @sm:absolute"
				size="sm"
			>
				<BoxesIcon className="size-4 text-success" />
				<p className="font-bold">16</p>
				<p>units</p>
			</Button> */}
		</DetailsCard>
	)
}
