import { Check, Pause, Play, Trash } from "lucide-react"
import { AnimatePresence, motion } from "motion/react"

import { cn } from "@/lib/utils/classnames"
import { formatTimerFromSeconds } from "@/lib/utils/time-formatting-helpers"
import { Button } from "@/components/ui/button"

import { useShopWatch } from "../context"
import { TimerProgressBar } from "./components/progress-bar"

export const ShopWatchTracking = () => {
	const {
		time,
		isRunning,
		selectedActivity,
		setIsRunning,
		setTime,
		setSelectedActivity,
		setTimeTotal,
	} = useShopWatch()

	const done = () => {
		setTime(0)
		setIsRunning(false)
		setSelectedActivity({})
		setTimeTotal((prevTimeTotal) => prevTimeTotal + time)
	}

	const cancel = () => {
		setTime(0)
		setIsRunning(false)
		setSelectedActivity({})
	}

	return (
		<div className="ease flex-col rounded-lg bg-background p-2 text-foreground shadow transition-all">
			<div className="pl-2.5 pr-3">
				<div className="text-sm opacity-70">{selectedActivity?.id}</div>
				<div className="font-bold leading-tight">
					{selectedActivity?.name}
				</div>
			</div>

			<div
				className={cn(
					"mt-2 flex-col items-center rounded p-2 pl-2.5 pr-0.5 pt-1.5 transition-all",
					!isRunning && "bg-background-weaker",
					time > 0 && "bg-selected text-primary-weak",
					isRunning && "bg-success-weak text-success-foreground",
				)}
			>
				<div className="mb-1 flex items-center">
					<div className="relative w-full flex-col leading-none">
						<div
							className={cn(
								"origin-top-left scale-100 text-3xl font-bold transition-all ease-in-out",
								time > 0 && !isRunning && "scale-[.65]",
							)}
						>
							{formatTimerFromSeconds(time)}
						</div>
						<div
							className={cn(
								"absolute bottom-0 left-0 h-0 origin-top-left scale-0 text-xs opacity-0 transition-all ease-in-out",
								time > 0 &&
									!isRunning &&
									"bottom-0 left-0 h-[12px] scale-100 opacity-100",
							)}
						>
							Paused
						</div>
					</div>
					{isRunning ?
						<Button
							onClick={() => setIsRunning(false)}
							variant="ghost"
							size="sm"
							className="px-1.5"
						>
							<Pause className="text-white" />
						</Button>
					:	<>
							<Button
								onClick={() => setIsRunning(true)}
								variant="ghost"
								size="sm"
								className="pl-1.5 pr-1"
							>
								<Play />
							</Button>
						</>
					}
				</div>
				<TimerProgressBar
					currentTimeSeconds={time}
					estimatedMinutes={selectedActivity.estimate || 0}
					isRunning={isRunning}
				/>
			</div>

			{time > 0 && !isRunning && (
				<AnimatePresence>
					<motion.div
						className="mt-2 flex gap-2.5"
						initial={{ y: -5, opacity: 1 }}
						animate={{ y: 0, opacity: 1 }}
						exit={{ y: -5, opacity: 0 }}
						transition={{
							type: "spring",
							stiffness: 350,
							damping: 20,
							mass: 1,
						}}
					>
						<Button
							onClick={() => cancel()}
							className="shrink-0 px-3"
							variant="ghost"
						>
							<Trash size={18} className="text-error" />
						</Button>
						<Button
							onClick={() => done()}
							className="grow bg-success-weak"
							variant="success"
						>
							<Check className="mr-1" size={18} />
							Done
						</Button>
					</motion.div>
				</AnimatePresence>
			)}
		</div>
	)
}
