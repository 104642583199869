import { forwardRef } from "react"

import { cn } from "@/lib/utils/classnames"

import { ShopWatch } from "../shop-watch"
import { ShopWatchProvider } from "../shop-watch/context"
import { NavbarCollapsedContainer } from "./components/collapsed/container"
import { DesktopNavbarContainer } from "./components/desktop-container"
import { NavbarFooter } from "./components/desktop-footer"
import { NavbarExpandedContainer } from "./components/expanded/container"
import { MobileNavbarContainer } from "./components/mobile-container"
import { NAVBAR_DIMENSIONS } from "./constants"

export { NavbarProvider } from "./context"

export const NavbarInset = forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<main
		ref={ref}
		className={cn(
			"relative flex flex-1 gap-1 overflow-hidden md:rounded-xl md:px-1 md:pb-1 lg:gap-2 lg:p-2 lg:pl-0",
			className,
		)}
		{...props}
	/>
))
NavbarInset.displayName = "NavbarInset"

export const AppNavbar = () => {
	return (
		<>
			<MobileNavbarContainer className="lg:hidden">
				<NavbarExpandedContainer className="w-[calc(var(--navbar-width)-theme(spacing.8))] py-8" />
			</MobileNavbarContainer>

			<DesktopNavbarContainer
				style={
					{
						"--expanded-width": NAVBAR_DIMENSIONS.EXPANDED,
						"--collapsed-width": NAVBAR_DIMENSIONS.COLLAPSED,
					} as React.CSSProperties
				}
				className="max-lg:hidden"
			>
				<div className="relative flex-1 overflow-hidden">
					<NavbarExpandedContainer className="w-[calc(var(--expanded-width)-theme(spacing.8))]" />
					<NavbarCollapsedContainer className="w-[calc(var(--collapsed-width)-theme(spacing.8))]" />
				</div>

				<ShopWatchProvider>
					<ShopWatch />
				</ShopWatchProvider>
				<NavbarFooter />
			</DesktopNavbarContainer>
		</>
	)
}
