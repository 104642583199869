import { MailIcon, PhoneIcon } from "lucide-react"

import { Chip } from "@/components/ui/chip"
import {
	DetailsCard,
	DetailsCardInfoLine,
	DetailsCardInfoLinesWrapper,
	DetailsCardOverline,
	DetailsCardSubtitle,
	DetailsCardTitle,
} from "@/components/ui/details-card"

// This is a temporary file. TODO: Standardize contacts

export interface TempLocationContact {
	id: number
	name: string
	locationId: number
	title: string | null
	phone: string | null
	email: string | null
}

interface LocationContactDetailsCardProps {
	contact: TempLocationContact
}
export function LocationContactDetailsCard({
	contact,
}: LocationContactDetailsCardProps) {
	return (
		<DetailsCard>
			<DetailsCardOverline icon={PhoneIcon}>Contact</DetailsCardOverline>
			{/* <Button
				variant="ghost"
				size="icon"
				className="absolute right-2 top-2"
			>
				<EllipsisIcon className="size-4" />
			</Button> */}

			<div className="flex items-center justify-between">
				<DetailsCardTitle>
					<div>{contact.name}</div>
				</DetailsCardTitle>
				<Chip variant="info" className="w-fit uppercase">
					Billing Contact
				</Chip>
			</div>
			<DetailsCardSubtitle>{contact.title}</DetailsCardSubtitle>
			<DetailsCardInfoLinesWrapper>
				<DetailsCardInfoLine icon={MailIcon}>
					{contact.email ?? (
						<p className="select-none text-foreground-weaker">
							N/A
						</p>
					)}
				</DetailsCardInfoLine>
				<DetailsCardInfoLine icon={PhoneIcon}>
					{contact.phone ?? (
						<p className="select-none text-foreground-weaker">
							(xxx) xxx-xxxx
						</p>
					)}
				</DetailsCardInfoLine>
			</DetailsCardInfoLinesWrapper>
		</DetailsCard>
	)
}
