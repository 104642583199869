import {
	boolean,
	doublePrecision,
	integer,
	pgTable,
	serial,
	text,
} from "drizzle-orm/pg-core"

import { timestampFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"

// Customer Billing primary table
export const customerBillingTable = pgTable("customer_billing", {
	id: serial("id").primaryKey(),
	scopeId: integer("scope_id")
		.references(() => scopeTable.id, { onDelete: "cascade" })
		.notNull(),
	billingType: text("billing_type").notNull(),
	termsCode: text("terms_code").notNull(),
	standardPriceCode: text("standard_price_code").notNull(),
	flatRatePriceCode: text("flat_rate_price_code").notNull(),
	laborRate: doublePrecision("labor_rate").notNull(),
	laborMarkupPercent: doublePrecision("labor_markup_percent").notNull(),
	fuelMarkupPercent: doublePrecision("fuel_markup_percent").notNull(),
	useWorkOrderSurcharge: boolean("use_work_order_surcharge")
		.notNull()
		.default(false),
	usePartsInvoiceSurcharge: boolean("use_parts_invoice_surcharge")
		.notNull()
		.default(false),
	...timestampFields,
})
