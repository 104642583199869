import type { FakeEquipment } from "@/routes/_main+/resources+/equipment/fake-equipment"
import { BadgeCheckIcon, InfoIcon } from "lucide-react"

import { Accordion } from "@/components/ui/accordion"
import {
	DetailsInfoGroup,
	DetailsSection,
	DetailsSectionHeading,
	DetailsSectionsGrouping,
} from "@/components/ui/details-view"
import { Link } from "@/components/ui/link"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

import { EquipmentOperatorInspectionsSection } from "./operator-inspections"
import { EquipmentOperatorsSection } from "./operators"
import { EquipmentSpecsSection } from "./specifications"
import { EquipmentTireTreadSection } from "./tire-tread"

export const EquipmentInfoSection: DetailsSectionComponent<FakeEquipment> = ({
	data: equipment,
}) => (
	<>
		<DetailsSectionsGrouping>
			<DetailsSection>
				<DetailsSectionHeading>Info</DetailsSectionHeading>
				<div className="grid grid-cols-2 gap-3">
					<DetailsInfoGroup label="Reg Owner Id">
						{equipment.registeredOwner}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Weight Class">
						{equipment.weightClass}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Reg State">
						{equipment.registrationExpiry}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Reg Expiry">
						{equipment.registrationExpiry}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Off-road?">
						No {/* TODO: Offroad data? */}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Int. Reciprocity Plate?">
						{equipment.reciprocityPlate}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Default Repair Site">
						CORVALLIS {/* TODO: Default Repair Site data? */}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Assignment">
						Long Haul {/* TODO: Assignment data? */}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Route #">
						ORE-17 {/* TODO: Route data? */}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Equipment email">
						truck2@tomco.com {/* TODO: Truck data? */}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Driver Name">
						<Link to="/">Tom Coleman</Link>
						{/* TODO: Driver data? */}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Current oper. phone">
						(345)555-2200 {/* TODO: Driver data? */}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Last inspection">
						<Link to="/">Passed</Link>
						{/* TODO: Inspection data? */}
					</DetailsInfoGroup>
				</div>
				<div className="relative mt-3 flex w-full rounded-md border-2 border-[#e8c14b] p-2">
					<BadgeCheckIcon className="mr-2 size-6 fill-[#d0a21a] text-white" />
					<div className="flex flex-col gap-1">
						<div className="text-lg leading-tight text-foreground-strong">
							50,000 mile / 5 year warranty
						</div>
						<div className="text-sm text-foreground-weak">
							Started 11/1/15, Original parts only
						</div>
					</div>
				</div>
			</DetailsSection>
			<EquipmentTireTreadSection />
			<Accordion type="multiple" className="flex flex-col gap-3">
				<EquipmentOperatorsSection />
				<EquipmentSpecsSection />
				<EquipmentOperatorInspectionsSection />
			</Accordion>
		</DetailsSectionsGrouping>
	</>
)
EquipmentInfoSection.label = "Info"
EquipmentInfoSection.icon = InfoIcon
