import {
	DetailsInfoGroup,
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"

export function EquipmentAccountingSection() {
	return (
		<DetailsSection>
			<DetailsSectionHeading>Accounting</DetailsSectionHeading>
			<DetailsInfoGroup label="Flat rate maintenance billing code">
				TRK500
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Department">
				1000 - Fleet Maintenance
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Expense category">
				001 - Operating expenses
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Unit">
				0001 - North Region
			</DetailsInfoGroup>
		</DetailsSection>
	)
}
