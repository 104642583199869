import containerQueries from "@tailwindcss/container-queries"
import typography from "@tailwindcss/typography"
import type { Config } from "tailwindcss"
import tailwindcssAnimate from "tailwindcss-animate"
import colors from "tailwindcss/colors"
import defaultTheme from "tailwindcss/defaultTheme"

const fontFamily = defaultTheme.fontFamily

export default {
	darkMode: ["class"],
	content: [
		"./app/**/{**,.client,.server}/**/*.{js,jsx,ts,tsx}",
		"./app/styles/*.css",
	],
	theme: {
		container: {
			center: true,
			padding: "2rem",
		},
		colors: {
			current: colors.current,
			white: colors.white,
			black: colors.black,
			transparent: colors.transparent,
			fiveo: {
				50: "hsl(var(--fiveo-50))",
				100: "hsl(var(--fiveo-100))",
				150: "hsl(var(--fiveo-150))",
				200: "hsl(var(--fiveo-200))",
				250: "hsl(var(--fiveo-250))",
				300: "hsl(var(--fiveo-300))",
				350: "hsl(var(--fiveo-350))",
				400: "hsl(var(--fiveo-400))",
				450: "hsl(var(--fiveo-450))",
				500: "hsl(var(--fiveo-500))",
				550: "hsl(var(--fiveo-550))",
				600: "hsl(var(--fiveo-600))",
				650: "hsl(var(--fiveo-650))",
				700: "hsl(var(--fiveo-700))",
				750: "hsl(var(--fiveo-750))",
				800: "hsl(var(--fiveo-800))",
				850: "hsl(var(--fiveo-850))",
				900: "hsl(var(--fiveo-900))",
				950: "hsl(var(--fiveo-950))",
			},
			asphalt: {
				50: "hsl(var(--asphalt-50))",
				100: "hsl(var(--asphalt-100))",
				150: "hsl(var(--asphalt-150))",
				200: "hsl(var(--asphalt-200))",
				250: "hsl(var(--asphalt-250))",
				300: "hsl(var(--asphalt-300))",
				350: "hsl(var(--asphalt-350))",
				400: "hsl(var(--asphalt-400))",
				450: "hsl(var(--asphalt-450))",
				500: "hsl(var(--asphalt-500))",
				550: "hsl(var(--asphalt-550))",
				600: "hsl(var(--asphalt-600))",
				650: "hsl(var(--asphalt-650))",
				700: "hsl(var(--asphalt-700))",
				750: "hsl(var(--asphalt-750))",
				800: "hsl(var(--asphalt-800))",
				850: "hsl(var(--asphalt-850))",
				900: "hsl(var(--asphalt-900))",
				950: "hsl(var(--asphalt-950))",
			},
			go: {
				50: "hsl(var(--go-50))",
				100: "hsl(var(--go-100))",
				150: "hsl(var(--go-150))",
				200: "hsl(var(--go-200))",
				250: "hsl(var(--go-250))",
				300: "hsl(var(--go-300))",
				350: "hsl(var(--go-350))",
				400: "hsl(var(--go-400))",
				450: "hsl(var(--go-450))",
				500: "hsl(var(--go-500))",
				550: "hsl(var(--go-550))",
				600: "hsl(var(--go-600))",
				650: "hsl(var(--go-650))",
				700: "hsl(var(--go-700))",
				750: "hsl(var(--go-750))",
				800: "hsl(var(--go-800))",
				850: "hsl(var(--go-850))",
				900: "hsl(var(--go-900))",
				950: "hsl(var(--go-950))",
			},
			cone: {
				50: "hsl(var(--cone-50))",
				100: "hsl(var(--cone-100))",
				150: "hsl(var(--cone-150))",
				200: "hsl(var(--cone-200))",
				250: "hsl(var(--cone-250))",
				300: "hsl(var(--cone-300))",
				350: "hsl(var(--cone-350))",
				400: "hsl(var(--cone-400))",
				450: "hsl(var(--cone-450))",
				500: "hsl(var(--cone-500))",
				550: "hsl(var(--cone-550))",
				600: "hsl(var(--cone-600))",
				650: "hsl(var(--cone-650))",
				700: "hsl(var(--cone-700))",
				750: "hsl(var(--cone-750))",
				800: "hsl(var(--cone-800))",
				850: "hsl(var(--cone-850))",
				900: "hsl(var(--cone-900))",
				950: "hsl(var(--cone-950))",
			},
			brake: {
				50: "hsl(var(--brake-50))",
				100: "hsl(var(--brake-100))",
				150: "hsl(var(--brake-150))",
				200: "hsl(var(--brake-200))",
				250: "hsl(var(--brake-250))",
				300: "hsl(var(--brake-300))",
				350: "hsl(var(--brake-350))",
				400: "hsl(var(--brake-400))",
				450: "hsl(var(--brake-450))",
				500: "hsl(var(--brake-500))",
				550: "hsl(var(--brake-550))",
				600: "hsl(var(--brake-600))",
				650: "hsl(var(--brake-650))",
				700: "hsl(var(--brake-700))",
				750: "hsl(var(--brake-750))",
				800: "hsl(var(--brake-800))",
				850: "hsl(var(--brake-850))",
				900: "hsl(var(--brake-900))",
				950: "hsl(var(--brake-950))",
			},
			stop: {
				50: "hsl(var(--stop-50))",
				100: "hsl(var(--stop-100))",
				150: "hsl(var(--stop-150))",
				200: "hsl(var(--stop-200))",
				250: "hsl(var(--stop-250))",
				300: "hsl(var(--stop-300))",
				350: "hsl(var(--stop-350))",
				400: "hsl(var(--stop-400))",
				450: "hsl(var(--stop-450))",
				500: "hsl(var(--stop-500))",
				550: "hsl(var(--stop-550))",
				600: "hsl(var(--stop-600))",
				650: "hsl(var(--stop-650))",
				700: "hsl(var(--stop-700))",
				750: "hsl(var(--stop-750))",
				800: "hsl(var(--stop-800))",
				850: "hsl(var(--stop-850))",
				900: "hsl(var(--stop-900))",
				950: "hsl(var(--stop-950))",
			},
			welcome: {
				50: "hsl(var(--welcome-50))",
				100: "hsl(var(--welcome-100))",
				150: "hsl(var(--welcome-150))",
				200: "hsl(var(--welcome-200))",
				250: "hsl(var(--welcome-250))",
				300: "hsl(var(--welcome-300))",
				350: "hsl(var(--welcome-350))",
				400: "hsl(var(--welcome-400))",
				450: "hsl(var(--welcome-450))",
				500: "hsl(var(--welcome-500))",
				550: "hsl(var(--welcome-550))",
				600: "hsl(var(--welcome-600))",
				650: "hsl(var(--welcome-650))",
				700: "hsl(var(--welcome-700))",
				750: "hsl(var(--welcome-750))",
				800: "hsl(var(--welcome-800))",
				850: "hsl(var(--welcome-850))",
				900: "hsl(var(--welcome-900))",
				950: "hsl(var(--welcome-950))",
			},
			background: {
				DEFAULT: "hsl(var(--background))",
				weak: "hsl(var(--background-weak))",
				weaker: "hsl(var(--background-weaker))",
				weakest: "hsl(var(--background-weakest))",
			},
			hover: {
				DEFAULT: "hsl(var(--hover) / 7%)",
			},
			foreground: {
				strong: "hsl(var(--foreground-strong))",
				DEFAULT: "hsl(var(--foreground))",
				weak: "hsl(var(--foreground-weak))",
				weaker: "hsl(var(--foreground-weaker))",
				weakest: "hsl(var(--foreground-weakest))",
			},
			accent: {
				DEFAULT: "hsl(var(--accent))",
				foreground: "hsl(var(--accent-foreground))",
			},
			selected: {
				DEFAULT: "hsl(var(--selected))",
				foreground: "hsl(var(--selected-foreground))",
			},
			border: {
				stronger: "hsl(var(--border-stronger))",
				strong: "hsl(var(--border-strong))",
				DEFAULT: "hsl(var(--border))",
				weak: "hsl(var(--border-weak))",
				weaker: "hsl(var(--border-weaker))",
			},
			primary: {
				DEFAULT: "hsl(var(--primary))",
				border: "hsl(var(--primary-border))",
				foreground: "hsl(var(--primary-foreground))",
				text: "hsl(var(--primary-text))",
				hover: "hsl(var(--primary-hover))",
				weak: "hsl(var(--primary-weak))",
			},
			error: {
				DEFAULT: "hsl(var(--error))",
				border: "hsl(var(--error-border))",
				foreground: "hsl(var(--error-foreground))",
				text: "hsl(var(--error-text))",
				hover: "hsl(var(--error-hover))",
			},
			info: {
				DEFAULT: "hsl(var(--info))",
				border: "hsl(var(--info-border))",
				foreground: "hsl(var(--info-foreground))",
				text: "hsl(var(--info-text))",
				hover: "hsl(var(--info-hover))",
			},
			success: {
				DEFAULT: "hsl(var(--success))",
				border: "hsl(var(--success-border))",
				foreground: "hsl(var(--success-foreground))",
				text: "hsl(var(--success-text))",
				hover: "hsl(var(--success-hover))",
				weak: "hsl(var(--success-weak))",
			},
			warning: {
				DEFAULT: "hsl(var(--warning))",
				border: "hsl(var(--warning-border))",
				foreground: "hsl(var(--warning-foreground))",
				text: "hsl(var(--warning-text))",
				hover: "hsl(var(--warning-hover))",
			},
			input: {
				DEFAULT: "hsl(var(--input))",
			},
			link: {
				DEFAULT: "hsl(var(--link))",
			},
			focus: {
				DEFAULT: "hsl(var(--focus))",
			},
		},
		backdropBlur: {
			"sm": "2px",
			"DEFAULT": "4px",
			"md": "8px",
			"lg": "12px",
			"xl": "16px",
			"2xl": "24px",
			"3xl": "40px",
		},
		boxShadow: {
			DEFAULT: `
			0 1px 1px -0.5px rgb(0 0 0 / 0.03),
			0 2px 2px -1px rgb(0 0 0 / 0.03),
			0 3px 3px -1.5px rgb(0 0 0 / 0.03),
			0 6px 6px -3px rgb(0 0 0 / 0.03),
			0 12px 12px -6px rgb(0 0 0 / 0.03)`,
			sm: `
			0 0.5px 0.5px -0.25px rgb(0 0 0 / 0.04),
			0 1.5px 1.5px -0.75px rgb(0 0 0 / 0.04),
			0 3px 3px -1.5px rgb(0 0 0 / 0.025),
			0 6px 6px -3px rgb(0 0 0 / 0.02),
			0 12px 12px -6px rgb(0 0 0 / 0.02)`,
			md: `
			0 0.5px 0.5px -0.25px rgb(0 0 0 / 0.03),
			0 1.5px 1.5px -0.75px rgb(0 0 0 / 0.035),
			0 3px 3px -1.5px rgb(0 0 0 / 0.04),
			0 6px 6px -3px rgb(0 0 0 / 0.045),
			0 12px 12px -6px rgb(0 0 0 / 0.05)`,
			lg: `
			0 0.5px 0.5px -0.25px rgb(0 0 0 / 0.025),
			0 1.5px 1.5px -0.75px rgb(0 0 0 / 0.035),
			0 3px 3px -1.5px rgb(0 0 0 / 0.045),
			0 6px 6px -3px rgb(0 0 0 / 0.055),
			0 12px 12px -6px rgb(0 0 0 / 0.065)`,

			none: `none`,
		},
		fontSize: {
			// these are the default tailwind sizes, renamed down one level
			"xs": ["0.625rem", { lineHeight: "0.75rem" }],
			"sm": ["0.75rem", { lineHeight: "1rem" }],
			"base": ["0.875rem", { lineHeight: "1.25rem" }],
			"lg": ["1rem", { lineHeight: "1.5rem" }],
			"xl": ["1.125rem", { lineHeight: "1.75rem" }],
			"2xl": ["1.25rem", { lineHeight: "1.75rem" }],
			"3xl": ["1.5rem", { lineHeight: "2rem" }],
			"4xl": ["1.875rem", { lineHeight: "2.25rem" }],
			"5xl": ["2.25rem", { lineHeight: "2.5rem" }],
			"6xl": ["3rem", { lineHeight: "1" }],
			"7xl": ["3.75rem", { lineHeight: "1" }],
			"8xl": ["4.5rem", { lineHeight: "1" }],
			"9xl": ["6rem", { lineHeight: "1" }],
		},
		opacity: {
			"0": "0",
			"30": "0.3",
			"50": "0.5",
			"70": "0.7",
			"100": "1",
		},
		extend: {
			fontFamily: {
				sans: ['"Inter"', ...fontFamily.sans],
			},
			backgroundImage: {
				"gradient-primary":
					"linear-gradient(115deg, #162657 0%, hsl(var(--primary)) 100%)",
				"gradient-light":
					"linear-gradient(187deg, #fffcfd 0%, #fff2f5 22%, #f2f4f9 66%, #d9deec 100%)",
				"gradient-dark":
					"linear-gradient(340deg, #05112a 0%, #0d1a44 22%, #162341 66%, #182d60 100%)",
			},
			keyframes: {
				"accordion-down": {
					from: { height: "0" },
					to: {
						height: "var(--radix-accordion-content-height, var(--radix-collapsible-content-height))",
					},
				},
				"accordion-up": {
					from: {
						height: "var(--radix-accordion-content-height, var(--radix-collapsible-content-height))",
					},
					to: { height: "0" },
				},
				"caret-blink": {
					"0%,70%,100%": { opacity: "1" },
					"20%,50%": { opacity: "0" },
				},
			},
			animation: {
				"accordion-down":
					"accordion-down 0.3s cubic-bezier(0.87, 0, 0.13, 1)",
				"accordion-up":
					"accordion-up 0.3s cubic-bezier(0.87, 0, 0.13, 1)",
				"caret-blink": "caret-blink 1.25s ease-out infinite",
			},
			typography: {
				DEFAULT: {
					css: {
						maxWidth: "100ch",
					},
				},
			},
			screens: {
				"2xl": "1400px",
				"3xl": "1920px",
				"4xl": "2800px",
			},
		},
	},
	plugins: [tailwindcssAnimate, containerQueries, typography],
} satisfies Config
