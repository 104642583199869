import { AnimatePresence, motion } from "motion/react"

import { useShopWatch } from "../context"
import { ShopWatchFooter } from "./components/footer"
import { ShopWatchStart } from "./start"
import { ShopWatchTracking } from "./tracking"

export const ShopWatchExpanded = () => {
	const { selectedActivity, timeTotal } = useShopWatch()
	return (
		<>
			{selectedActivity.id ?
				<AnimatePresence>
					<motion.div
						initial={{ opacity: 0, y: 30 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: 0 }}
						transition={{
							type: "spring",
							stiffness: 600,
							damping: 30,
							mass: 1,
						}}
					>
						<ShopWatchTracking />
					</motion.div>
				</AnimatePresence>
			:	<motion.div
					initial={{ opacity: 0, y: -30 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 0 }}
					transition={{
						type: "spring",
						stiffness: 600,
						damping: 30,
						mass: 1,
					}}
				>
					<ShopWatchStart />
				</motion.div>
			}

			{(selectedActivity.id != null || timeTotal > 0) && (
				<ShopWatchFooter className="mt-1.5 pl-1.5 pr-2.5" />
			)}
		</>
	)
}
