import { boolean, integer, pgTable, serial, text } from "drizzle-orm/pg-core"

import { timestampFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"
import { customerTable } from "./customer"

// Customer Address primary table
export const customerAddressTable = pgTable("customer_address", {
	id: serial("id").primaryKey(),
	scopeId: integer("scope_id")
		.references(() => scopeTable.id, { onDelete: "cascade" })
		.notNull(),
	customerId: integer("customer_id")
		.references(() => customerTable.id, { onDelete: "cascade" })
		.notNull(),
	street1: text("street1").notNull(),
	street2: text("street2"),
	city: text("city").notNull(),
	state: text("state").notNull(),
	zip: text("zip").notNull(),
	isBilling: boolean("is_billing").notNull().default(false),
	...timestampFields,
})
