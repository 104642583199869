import { forwardRef } from "react"
import { Slot } from "@radix-ui/react-slot"
import { tv } from "tailwind-variants"
import type { VariantProps } from "tailwind-variants"

import { cn } from "@/lib/utils/classnames"

import { focusRing } from "../utils"
import { linkClasses } from "./link"

const buttonClasses = tv({
	base: [
		"relative inline-flex items-center justify-center whitespace-nowrap rounded-md text-base font-medium transition disabled:opacity-50",
		focusRing,
	],
	variants: {
		variant: {
			primary:
				"bg-primary text-primary-foreground shadow enabled:hover:bg-primary-hover",
			destructive:
				"bg-error text-error-foreground shadow-sm enabled:hover:bg-error-hover",
			success:
				"bg-success text-success-foreground shadow-sm enabled:hover:bg-success-hover",
			outline:
				"border shadow-sm enabled:hover:bg-hover dark:enabled:hover:bg-hover",
			primaryOutline:
				"border border-primary-weak/30 text-primary-text shadow-sm enabled:hover:bg-hover dark:enabled:hover:bg-hover",
			placeholder:
				"border border-dashed text-primary-text enabled:hover:bg-primary-weak/[10%]",
			ghost: "enabled:hover:bg-hover/[8%] enabled:hover:text-foreground-strong",
			link: linkClasses(),
		},
		size: {
			default: "h-9 px-4 py-2",
			sm: "h-8 rounded-md px-3 text-sm",
			lg: "h-10 rounded-md px-8",
			icon: "size-9 shrink-0",
			none: "h-auto px-2 py-1",
		},
	},
	defaultVariants: {
		variant: "outline",
		size: "default",
	},
})

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonClasses> {
	asChild?: boolean
	pending?: boolean
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			children,
			className,
			disabled,
			pending = false,
			size,
			variant,
			asChild = false,
			...props
		},
		ref,
	) => {
		const Comp = asChild ? Slot : "button"

		return (
			<Comp
				className={cn(
					buttonClasses({ variant, size, className }),
					pending && "cursor-default",
					disabled && !pending && "cursor-not-allowed",
				)}
				ref={ref}
				disabled={pending || disabled}
				aria-disabled={pending || disabled}
				aria-busy={pending}
				{...props}
			>
				{/* {pending && (
					<div
						className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
						aria-hidden="true"
					>
						<Loader2Icon className="size-4 animate-spin" />
					</div>
				)} */}
				{children}
			</Comp>
		)
	},
)
Button.displayName = "Button"

export { Button, buttonClasses }
