import { InfoIcon } from "lucide-react"

import type { Vendor } from "@/server/schemas"
import {
	DetailsInfoGroup,
	DetailsInfoGroupsWrapper,
	DetailsSection,
	DetailsSectionHeading,
	DetailsSectionsGrouping,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"
import { Notes } from "@/components/shared/details/notes"

import { VendorAttachmentsSection } from "./attachments"
import { VendorContactsSection } from "./contacts"

export const VendorInfoSection: DetailsSectionComponent<Vendor> = ({
	data: vendor,
}) => (
	<DetailsSectionsGrouping>
		<DetailsSection>
			{vendor.notes && <Notes notes={[vendor.notes]} />}
			<DetailsSectionHeading>Info</DetailsSectionHeading>
			<DetailsInfoGroupsWrapper>
				<DetailsInfoGroup label="Address">
					<div className="text-foreground-strong">
						{vendor.street1}
						{vendor.street2 && ", " + vendor.street2}
					</div>
					<div className="text-foreground-strong">
						{vendor.city}, {vendor.state} {vendor.zip}
					</div>
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Return Address">
					<div className="text-foreground-strong">
						{vendor.street1}
						{vendor.street2 && ", " + vendor.street2}
					</div>
					<div className="text-foreground-strong">
						{vendor.city}, {vendor.state} {vendor.zip}
					</div>
				</DetailsInfoGroup>
			</DetailsInfoGroupsWrapper>
		</DetailsSection>
		<VendorContactsSection vendor={vendor} />
		<VendorAttachmentsSection />
	</DetailsSectionsGrouping>
)
VendorInfoSection.label = "Info"
VendorInfoSection.icon = InfoIcon
