import { RefreshCcwIcon, Settings2Icon } from "lucide-react"

import type { Employee } from "@/server/schemas"
import { Button } from "@/components/ui/button"
import {
	DetailsInfoGroup,
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

const EmployeeSettingsSection: DetailsSectionComponent<Employee> = ({
	data: employee,
}) => (
	<DetailsSection>
		<DetailsSectionHeading>Settings</DetailsSectionHeading>
		<DetailsInfoGroup label="Employee DBA Authority" inline>
			No
		</DetailsInfoGroup>
		<DetailsInfoGroup label="Full Access" inline className="mb-3">
			No
		</DetailsInfoGroup>
		<ResetPasswordButton />
		<ResetMobilePinButton />
	</DetailsSection>
)

EmployeeSettingsSection.label = "Settings"
EmployeeSettingsSection.icon = Settings2Icon

export { EmployeeSettingsSection }

const ResetPasswordButton = () => {
	return (
		<Button>
			<RefreshCcwIcon className="mr-1 size-4" />
			Reset Password
		</Button>
	)
}

const ResetMobilePinButton = () => {
	return (
		<Button>
			<RefreshCcwIcon className="mr-1 size-4" />
			Reset Mobile PIN
		</Button>
	)
}
