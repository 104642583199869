import { createContext, useContext, useEffect, useMemo, useState } from "react"

import { TooltipProvider } from "@/components/ui/tooltip"

interface ShopWatchGlobalContext {
	isTeamView: boolean // Defines on app level whether we're using individual shop clock or team clocks
	setIsTeamView: React.Dispatch<React.SetStateAction<boolean>>
	isTeamViewOpen: boolean // Is the team clock footer showing
	setIsTeamViewOpen: React.Dispatch<React.SetStateAction<boolean>>
}

// Context & Hook
const ShopWatchGlobalContext = createContext<ShopWatchGlobalContext | null>(
	null,
)

export const useShopWatchGlobal = () => {
	const context = useContext(ShopWatchGlobalContext)
	if (!context) {
		throw new Error(
			"useShopWatchGlobal must be used within a ShopWatchGlobalProvider",
		)
	}
	return context
}

// Provider Component
export const ShopWatchGlobalProvider = ({
	children,
}: {
	children: React.ReactNode
}) => {
	const [isTeamView, setIsTeamView] = useState(false)
	const [isTeamViewOpen, setIsTeamViewOpen] = useState(false)

	useEffect(() => {
		if (isTeamView) return
		setIsTeamViewOpen(false)
	}, [isTeamView])

	const contextValue: ShopWatchGlobalContext = useMemo(
		() => ({
			isTeamView,
			setIsTeamView,
			isTeamViewOpen,
			setIsTeamViewOpen,
		}),
		[isTeamView, setIsTeamView, isTeamViewOpen, setIsTeamViewOpen],
	)

	return (
		<ShopWatchGlobalContext.Provider value={contextValue}>
			<TooltipProvider delayDuration={0}>{children}</TooltipProvider>
		</ShopWatchGlobalContext.Provider>
	)
}
