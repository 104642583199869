import { createContext, useCallback, useContext, useState } from "react"
import type { z } from "zod"

//import type { DefaultValues } from "react-hook-form"

import { addEditConfig } from "@/config/add-edit"
import type { AddEditConfigKeys } from "@/config/add-edit"
import type { locationFormSchema } from "@/server/schemas"

import type { AddEditMode } from "."

// Represents an active add-edit dialog in context.
interface ActiveAddEdit<K extends AddEditConfigKeys> {
	name: K
	mode: AddEditMode

	// Not sure why z.infer<(typeof addEditConfig)[K]["schema"]> doesn't work here.
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	initialValue?: any
}

interface AddEditContextType {
	//activeDialogs: ActiveAddEdit<AddEditConfigKeys>[]
	openDialog: <K extends AddEditConfigKeys>(
		name: K,
		initialValue?: z.infer<(typeof addEditConfig)[K]["schema"]>,
	) => void
	closeDialog: (name: AddEditConfigKeys) => void
}

function createInitialContext(): AddEditContextType {
	const notInitialized = () => {
		throw new Error("AddEditContext not initialized")
	}

	return {
		//activeDialogs: [],
		openDialog: notInitialized,
		closeDialog: notInitialized,
	}
}

const AddEditContext = createContext<AddEditContextType>(createInitialContext())

export const AddEditProvider = ({
	children,
}: {
	children: React.ReactNode
}) => {
	const [activeDialogs, setActiveDialogs] = useState<
		ActiveAddEdit<AddEditConfigKeys>[]
	>([])

	const openDialog = useCallback(
		<K extends AddEditConfigKeys>(
			name: K,
			initialValue?: z.infer<(typeof addEditConfig)[K]["schema"]>,
		) => {
			// The mode is determined on open based on if an initial value is provided.
			const mode = initialValue ? "edit" : "add"

			setActiveDialogs((current) => [
				...current,
				{
					name,
					initialValue: initialValue,
					mode,
				},
			])
		},
		[],
	)

	const closeDialog = useCallback((name: AddEditConfigKeys) => {
		setActiveDialogs((current) =>
			current.filter((dialog) => dialog.name !== name),
		)
	}, [])

	return (
		<AddEditContext.Provider
			value={{
				//activeDialogs,
				openDialog,
				closeDialog,
			}}
		>
			{children}
			{activeDialogs.map((activeDialog) => {
				const { component: ActiveAddEditTemplate } =
					addEditConfig[activeDialog.name]
				return (
					<ActiveAddEditTemplate
						key={activeDialog.name}
						open={true}
						mode={activeDialog.mode}
						onOpenChange={(open) => {
							if (!open) closeDialog(activeDialog.name)
						}}
						initialValue={activeDialog.initialValue}
					/>
				)
			})}
		</AddEditContext.Provider>
	)
}

/**
 * Hook to access the add edit context.
 * Contains active dialogs, and functions to open and close dialogs.
 * @returns The AddEditContext object.
 * @example
 * ```tsx
 * const addEdits = useAddEdit()
 * <Button onClick={addEdits.openDialog("customer")} />
 */
export function useAddEdit() {
	const context = useContext(AddEditContext)
	if (!context) {
		throw new Error("useAddEdit must be used within a AddEditProvider")
	}
	return context
}
