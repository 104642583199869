import { createContext, useContext } from "react"

interface CommandMenuContextType {
	setOpen: (open: boolean) => void
}

const CommandMenuContext = createContext<CommandMenuContextType | undefined>(
	undefined,
)

export function CommandMenuProvider({
	children,
	setOpen,
}: {
	children: React.ReactNode
	setOpen: (open: boolean) => void
}) {
	return (
		<CommandMenuContext.Provider value={{ setOpen }}>
			{children}
		</CommandMenuContext.Provider>
	)
}

export function useCommandMenu() {
	const context = useContext(CommandMenuContext)
	if (!context) {
		throw new Error(
			"useCommandMenu must be used within a CommandMenuProvider",
		)
	}
	return context
}
