import { boolean, integer, pgTable, serial } from "drizzle-orm/pg-core"

import { contactFields, timestampFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"
import { vendorTable } from "./vendor"

export const vendorContactTable = pgTable("vendor_contact", {
	id: serial("id").primaryKey(),
	scopeId: integer("scope_id")
		.references(() => scopeTable.id, { onDelete: "cascade" })
		.notNull(),
	vendorId: integer("vendor_id")
		.references(() => vendorTable.id, { onDelete: "cascade" })
		.notNull(),
	...contactFields,
	isRepresentative: boolean("is_representative").notNull().default(false),
	...timestampFields,
})
