import {
	DetailsInfoGroup,
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"

export function EquipmentTireTreadSection() {
	return (
		<DetailsSection>
			<DetailsSectionHeading>Tire Tread</DetailsSectionHeading>
			<div className="flex gap-3">
				<DetailsInfoGroup label="In Range">13/18</DetailsInfoGroup>
				<DetailsInfoGroup label="Read">11/10/24</DetailsInfoGroup>
			</div>
		</DetailsSection>
	)
}
