import { Children, useCallback, useEffect, useRef, useState } from "react"
import type { ReactNode } from "react"

import { cn } from "@/lib/utils/classnames"

interface MasonryLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
	children: React.ReactNode
	minColumnWidth?: number
}

export const MasonryLayout = ({
	children,
	minColumnWidth = 300,
	className,
}: MasonryLayoutProps) => {
	const maxColumnWidth = minColumnWidth * 2 - 1

	const [items, setItems] = useState<ReactNode[][]>([])
	const [columns, setColumns] = useState(1)
	const containerRef = useRef(null)

	const organizeItems = useCallback(() => {
		const itemsArray = Children.toArray(children)
		const columnHeights = new Array(columns).fill(0)
		const columnItems: ReactNode[][] = Array.from(
			{ length: Math.min(columns, itemsArray.length) },
			() => [],
		)

		itemsArray.forEach((item) => {
			const shortestColumn = columnHeights.indexOf(
				Math.min(...columnHeights),
			)
			columnItems[shortestColumn]?.push(item)
			columnHeights[shortestColumn] += 1
		})

		setItems(columnItems)
	}, [children, columns])

	useEffect(() => {
		const resizeObserver = new ResizeObserver((entries) => {
			if (!entries[0]) return
			const containerWidth = entries[0].contentRect.width
			const childCount = Children.count(children)
			const maxColumns = Math.min(
				Math.floor(containerWidth / minColumnWidth),
				childCount,
			)
			const minColumns = Math.min(
				Math.ceil(containerWidth / maxColumnWidth),
				childCount,
			)
			const optimalColumns = Math.max(
				minColumns,
				Math.min(
					maxColumns,
					Math.floor(containerWidth / minColumnWidth),
				),
			)
			setColumns(optimalColumns)
		})

		if (containerRef.current) {
			resizeObserver.observe(containerRef.current)
		}

		return () => resizeObserver.disconnect()
	}, [maxColumnWidth, minColumnWidth, children])

	useEffect(() => {
		organizeItems()
	}, [organizeItems])

	return (
		<div ref={containerRef} className="size-full">
			<div className={cn("flex gap-10 pb-3 xl:gap-16", className)}>
				{items.map((column, columnIndex) => (
					<div
						key={columnIndex}
						className="flex flex-1 flex-col gap-7"
						style={{
							maxWidth: `${maxColumnWidth}px`,
						}}
					>
						{column.map((item, itemIndex) => (
							<div key={itemIndex} className="break-inside-avoid">
								{item}
							</div>
						))}
					</div>
				))}
			</div>
		</div>
	)
}
