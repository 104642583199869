import { forwardRef } from "react"
import { tv } from "tailwind-variants"
import type { VariantProps } from "tailwind-variants"

import { cn } from "@/lib/utils/classnames"

import { Overline, OverlineSmall } from "./typography"

interface FieldGroupProps extends React.HTMLAttributes<HTMLElement> {
	label: string
	labelClasses?: string
	fieldClasses?: string
	children?: React.ReactNode
	grow?: boolean
	indicateEmpty?: boolean
}

const inlineClasses = "items-center gap-2"
const largerLabelClasses =
	"[&>*[data-label]]:text-base [&>*[data-label]]:tracking-normal [&>*[data-label]]:opacity-[60%]"

export const fieldGroupVariants = tv({
	base: "flex",
	variants: {
		variant: {
			default: `flex-col gap-1`,
			inline: `flex-row ${inlineClasses}`,
			inlineReverse: `flex-row-reverse ${inlineClasses} justify-end`,
			inline2: `flex-row ${inlineClasses} ${largerLabelClasses}`,
			inlineReverse2: `flex-row-reverse ${inlineClasses} justify-end ${largerLabelClasses}`,
		},
	},
	defaultVariants: {
		variant: "default",
	},
})

export interface FieldGroupComponentProps
	extends FieldGroupProps,
		VariantProps<typeof fieldGroupVariants> {}

const FieldGroup = forwardRef<HTMLDivElement, FieldGroupComponentProps>(
	(
		{
			label,
			labelClasses,
			fieldClasses,
			children,
			className,
			variant,
			grow = false,
			indicateEmpty = true,
			...props
		},
		ref,
	) => (
		<div
			ref={ref}
			className={cn(
				fieldGroupVariants({ variant, className }),
				grow && "justify-between",
				indicateEmpty &&
					"[&>[data-value]:empty]:after:text-sm [&>[data-value]:empty]:after:text-foreground-weakest [&>[data-value]:empty]:after:content-['--']",
			)}
			{...props}
		>
			<OverlineSmall data-label className={cn("shrink-0", labelClasses)}>
				{label}
			</OverlineSmall>
			<div data-value className={cn("leading-tight", className)}>
				{children}
			</div>
		</div>
	),
)
FieldGroup.displayName = "FieldGroup"

interface FieldGroupsWrapperProps extends React.HTMLAttributes<HTMLElement> {
	label: string
	labelClasses?: string
	children?: React.ReactNode
	indicateEmpty?: boolean
}

export const fieldGroupsWrapperVariants = tv({
	base: "flex",
	variants: {
		variant: {
			default: "flex-col",
			table: "table border-2",
		},
	},
	defaultVariants: {
		variant: "default",
	},
})

export interface FieldGroupsWrapperComponentProps
	extends FieldGroupsWrapperProps,
		VariantProps<typeof fieldGroupsWrapperVariants> {}

const FieldGroupsWrapper = forwardRef<
	HTMLDivElement,
	FieldGroupsWrapperComponentProps
>(
	(
		{
			label,
			labelClasses,
			children,
			className,
			variant,
			indicateEmpty = true,
			...props
		},
		ref,
	) => (
		<div
			ref={ref}
			className={cn(
				fieldGroupsWrapperVariants({ variant, className }),
				indicateEmpty &&
					"[&:empty]:after:text-sm [&:empty]:after:text-foreground-weakest [&:empty]:after:content-['--']",
			)}
			{...props}
		>
			<Overline className={cn("shrink-0", labelClasses)}>
				{label}
			</Overline>
			{children}
		</div>
	),
)
FieldGroupsWrapper.displayName = "FieldGroupsWrapper"

// const kbdVariants = tv({
//   base: "select-none rounded border px-1.5 py-px font-mono text-[0.7rem] font-normal shadow-sm disabled:opacity-50",
//   variants: {
//     variant: {
//       default: "bg-background-weak text-foreground-weak",
//       outline: "bg-background text-foreground",
//     },
//   },
//   defaultVariants: {
//     variant: "default",
//   },
// })

// export interface KbdProps
//   extends React.ComponentPropsWithoutRef<"kbd">,
//     VariantProps<typeof kbdVariants> {
//   /**
//    * The title of the `abbr` element inside the `kbd` element.
//    * @default undefined
//    * @type string | undefined
//    * @example title="Command"
//    */
//   abbrTitle?: string
// }

// const Kbd = forwardRef<HTMLElement, KbdProps>(
//   ({ abbrTitle, children, className, variant, ...props }, ref) => {
//     return (
//       <kbd
//         className={cn(kbdVariants({ variant, className }))}
//         ref={ref}
//         {...props}
//       >
//         {abbrTitle ?
//           <abbr title={abbrTitle} className="no-underline">
//             {children}
//           </abbr>
//         :	children}
//       </kbd>
//     )
//   },
// )
// Kbd.displayName = "Kbd"

export { FieldGroup }
