import { Button } from "@/components/ui/button"
import {
	DetailsInfoGroup,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import type { MultiDetailsViewComponent } from "@/components/shared/details/details-view"

export const EquipmentMultiDetailsView: MultiDetailsViewComponent = (props) => {
	const { ids: equipmentIds } = props

	return (
		<div className="flex size-full flex-col">
			<div className="border-b border-border-weak p-3">
				<DetailsInfoGroup label="Actions">
					<DetailsSectionHeading>
						{equipmentIds?.length} Selected
					</DetailsSectionHeading>
				</DetailsInfoGroup>
			</div>
			<div className="flex grow flex-col p-5">
				<div className="grow">
					<div className="py-4 text-lg font-bold">Batch actions</div>
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button variant="outline" className="w-full">
								Set Equipment Status
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent align="start">
							<DropdownMenuItem className="p-3">
								Active
							</DropdownMenuItem>
							<DropdownMenuItem className="p-3">
								Inactive
							</DropdownMenuItem>
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
				<Button className="w-full gap-2 self-end border-dashed border-warning text-warning">
					Delete {equipmentIds?.length} Equipment
				</Button>
			</div>
		</div>
	)
}
