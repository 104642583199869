import { pgTable, primaryKey } from "drizzle-orm/pg-core"

import { metaFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"
import { vendorTable } from "./vendor"

export const vendorMetaTable = pgTable(
	"vendor_meta",
	metaFields(scopeTable, vendorTable),
	(table) => [primaryKey({ columns: [table.field, table.rowId] })],
)
