import {
	DetailsInfoGroup,
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"

export function EquipmentLeaseSection() {
	return (
		<DetailsSection columned>
			<DetailsSectionHeading className="col-start-1 col-end-3">
				Lease
			</DetailsSectionHeading>
			<DetailsInfoGroup label="Lease number">
				168122145013
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Monthly lease fee">
				$22,500
			</DetailsInfoGroup>
		</DetailsSection>
	)
}
