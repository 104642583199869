import { useEffect, useState } from "react"
import { trpcClient } from "@/trpc/client"
import {
	BuildingIcon,
	HomeIcon,
	PackageIcon,
	UserIcon,
	UsersIcon,
} from "lucide-react"
import { useDebounce } from "use-debounce"

import {
	Command,
	CommandDialog,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandList,
	CommandLoading,
} from "@/components/ui/command"

import { CommandMenuProvider } from "./command-menu-context"
import { CustomerSearchItem } from "./features/customers/search-item"
import { CustomerSearchPage } from "./features/customers/search-page"
import { EmployeeSearchItem } from "./features/employees/search-item"
import { EmployeeSearchPage } from "./features/employees/search-page"
import { LocationSearchItem } from "./features/locations/search-item"
import { LocationSearchPage } from "./features/locations/search-page"
import { VendorSearchItem } from "./features/vendors/search-item"
import { VendorSearchPage } from "./features/vendors/search-page"
import { QuickCommands } from "./quick-commands"
import { ViewAllItem } from "./view-all-item"

const PAGE_CONFIG = {
	root: {
		icon: HomeIcon,
		label: "Searching everything",
		placeholder: "Search or type a command...",
	},
	customers: {
		icon: UsersIcon,
		label: "Searching in customers",
		placeholder: "Search customers...",
	},
	employees: {
		icon: UserIcon,
		label: "Searching in employees",
		placeholder: "Search employees...",
	},
	locations: {
		icon: BuildingIcon,
		label: "Searching in locations",
		placeholder: "Search locations...",
	},
	vendors: {
		icon: PackageIcon,
		label: "Searching in vendors",
		placeholder: "Search vendors...",
	},
} as const

export type PageType = keyof typeof PAGE_CONFIG

export function GlobalCommandMenu() {
	const [open, setOpen] = useState(false)
	const [search, setSearch] = useState("")
	const [pages, setPages] = useState<PageType[]>([])
	const page = pages[pages.length - 1] ?? "root"

	useEffect(() => {
		const down = (e: KeyboardEvent) => {
			if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
				e.preventDefault()
				setPages([])
				setOpen((open) => !open)
			}
		}
		document.addEventListener("keydown", down)
		return () => document.removeEventListener("keydown", down)
	}, [setOpen])

	const handleNavigateBack = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (
			(e.key === "Escape" && pages.length > 0) ||
			(e.key === "Backspace" && !search)
		) {
			setPages((pages) => pages.slice(0, -1))
		}
	}

	const { icon: FooterIcon, label: footerLabel } = PAGE_CONFIG[page]

	const navigateToPage = (page: PageType, shouldClearSearch = false) => {
		setPages((current) => [...current, page])
		if (shouldClearSearch) setSearch("")
	}

	const [debouncedSearch] = useDebounce(search, 300)
	const { data: customerData } = trpcClient.customers.previewSearch.useQuery(
		{ query: debouncedSearch.trim() },
		{ placeholderData: (prev) => prev },
	)
	const { data: employeeData } = trpcClient.employees.previewSearch.useQuery(
		{ query: debouncedSearch.trim() },
		{ placeholderData: (prev) => prev },
	)
	const { data: locationData } = trpcClient.locations.previewSearch.useQuery(
		{ query: debouncedSearch.trim() },
		{ placeholderData: (prev) => prev },
	)
	const { data: vendorData } = trpcClient.vendors.previewSearch.useQuery(
		{ query: debouncedSearch.trim() },
		{ placeholderData: (prev) => prev },
	)

	return (
		<CommandMenuProvider setOpen={setOpen}>
			<CommandDialog
				open={open}
				onOpenChange={setOpen}
				onEscapeKeyDown={(e) => pages.length > 0 && e.preventDefault()}
			>
				<Command
					className="flex flex-col sm:h-[425px] sm:max-h-[80svh] [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-foreground-weak [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:size-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:size-5"
					shouldFilter={false}
					loop
				>
					<CommandInput
						placeholder={PAGE_CONFIG[page].placeholder}
						value={search}
						onValueChange={setSearch}
						onKeyDown={handleNavigateBack}
					/>
					<CommandList className="max-h-none flex-1">
						<CommandEmpty>No results found.</CommandEmpty>
						{page === "root" && (
							<>
								<QuickCommands
									search={search}
									onNavigate={navigateToPage}
								/>
								<SearchResults
									feature="customers"
									data={customerData}
									renderItem={(result) => (
										<CustomerSearchItem
											key={result.item.id}
											{...result}
										/>
									)}
									onViewAll={() =>
										setPages((p) => [...p, "customers"])
									}
								/>
								<SearchResults
									feature="employees"
									data={employeeData}
									renderItem={({ item, matches }) => (
										<EmployeeSearchItem
											key={item.id}
											item={item}
											matches={matches}
										/>
									)}
									onViewAll={() =>
										setPages((p) => [...p, "employees"])
									}
								/>
								<SearchResults
									feature="locations"
									data={locationData}
									renderItem={({ item, matches }) => (
										<LocationSearchItem
											key={item.id}
											item={item}
											matches={matches}
										/>
									)}
									onViewAll={() =>
										setPages((p) => [...p, "locations"])
									}
								/>
								<SearchResults
									feature="vendors"
									data={vendorData}
									renderItem={({ item, matches }) => (
										<VendorSearchItem
											key={item.id}
											item={item}
											matches={matches}
										/>
									)}
									onViewAll={() =>
										setPages((p) => [...p, "vendors"])
									}
								/>
							</>
						)}
						{page === "customers" && (
							<CustomerSearchPage search={search} />
						)}
						{page === "employees" && (
							<EmployeeSearchPage search={search} />
						)}
						{page === "locations" && (
							<LocationSearchPage search={search} />
						)}
						{page === "vendors" && (
							<VendorSearchPage search={search} />
						)}
					</CommandList>
					<div className="flex items-center gap-2 border-t border-t-border-weak bg-background-weak p-2 text-sm text-foreground-weak">
						<FooterIcon className="size-4" />
						<span>{footerLabel}</span>
					</div>
				</Command>
			</CommandDialog>
		</CommandMenuProvider>
	)
}

interface SearchResultsProps<T> {
	feature: string
	data?: { results: T[]; hasMore: boolean; count: number }
	isLoading?: boolean
	renderItem: (item: T) => React.ReactNode
	onViewAll: () => void
}

function SearchResults<T>({
	feature,
	data,
	isLoading,
	renderItem,
	onViewAll,
}: SearchResultsProps<T>) {
	if (!data?.results.length) return null

	return (
		<CommandGroup heading={`Results found in ${feature}`}>
			{data.results.map(renderItem)}
			{isLoading && <CommandLoading />}
			{data.hasMore && (
				<ViewAllItem
					onSelect={onViewAll}
					label={`View all results in ${feature}...`}
					icon={UsersIcon}
					count={data.count}
				/>
			)}
		</CommandGroup>
	)
}
