import { tv } from "tailwind-variants"

import { cn } from "@/lib/utils/classnames"

export interface BadgeProps
	extends Omit<React.HTMLAttributes<HTMLDivElement>, "children"> {
	variant?: "primary" | "info" | "success" | "warning" | "error"
	value?: string
}

export const badgeClasses = tv({
	base: [
		"flex select-none items-center justify-center overflow-hidden rounded-full text-xs font-semibold transition-colors",
	],
	variants: {
		variant: {
			primary: "bg-primary text-primary-foreground",
			info: "bg-info text-info-foreground",
			success: "bg-success text-success-foreground",
			warning: "bg-warning text-warning-foreground",
			error: "bg-error text-error-foreground",
		},
	},
	defaultVariants: {
		variant: "primary",
	},
})

const Badge: React.FC<BadgeProps> = ({
	className,
	variant,
	value,
	...props
}) => {
	if (value === undefined) {
		return (
			<div
				className={cn(badgeClasses({ variant }), "size-1.5", className)}
				{...props}
			/>
		)
	}
	return (
		<div
			className={cn(badgeClasses({ variant }), "size-4 p-1", className)}
			{...props}
		>
			{value.slice(0, 2)}
		</div>
	)
}

export { Badge }
