import { getTableColumns, is, sql } from "drizzle-orm"
import type { SQL } from "drizzle-orm"
import { PgTimestampString } from "drizzle-orm/pg-core"
import type {
	PgTableWithColumns,
	SelectedFields,
	TableConfig,
} from "drizzle-orm/pg-core"
import type { SelectResultFields } from "drizzle-orm/query-builders/select.types"

/**
 * Filters out specified columns from a Drizzle table.
 *
 * @template T - Drizzle table type
 * @template K - Keys of columns to omit
 *
 * @param {PgTableWithColumns<T>} table - Drizzle table object
 * @param {Partial<Record<K, true>>} omitFields - Object specifying columns to omit
 *
 * @returns {Omit<ReturnType<typeof getTableColumns<PgTableWithColumns<T>>>, K>}
 * Table columns, excluding those specified in omitFields
 *
 * @example
 * const filteredColumns = getTableSelectColumns(locationTable, { createdAt: true, updatedAt: true });
 * // Returns all columns from locationTable except 'createdAt' and 'updatedAt'
 */
export function getTableSelectColumns<
	T extends TableConfig,
	K extends keyof ReturnType<typeof getTableColumns<PgTableWithColumns<T>>>,
>(
	table: PgTableWithColumns<T>,
	omitFields: Partial<Record<K, true>>,
): Omit<ReturnType<typeof getTableColumns<PgTableWithColumns<T>>>, K> {
	const allColumns = getTableColumns(table)
	return Object.fromEntries(
		Object.entries(allColumns).filter(([key]) => !omitFields[key as K]),
	) as Omit<ReturnType<typeof getTableColumns<PgTableWithColumns<T>>>, K>
}

/**
 * Build an SQL JSON object from an object containing selected columns
 *
 * @template T - Table column type
 *
 * @param {T} shape - Drizzle table columns
 * @returns {SQL<{ [K in keyof { [Key in keyof T & string]: SelectResultField<T[Key], true>; }]: { [Key in keyof T & string]: SelectResultField<T[Key], true>; }[K]; }>}
 * SQL object listing all selected columns and nested columns
 *
 * @example
 * // Store selected columns in an object and wrap it with jsonBuildObject when nesting it inside another object
 * export const locationSelectColumns = {
 * 	// Spread selected columns into object
 * 	...getTableSelectColumns(locationTable, locationOmitFields),
 * 	// Wrap nested objects with jsonBuildObject to format them into SQL
 * 	address: jsonBuildObject(addressSelectColumns),
 * 	coordinate: jsonBuildObject(coordinateSelectColumns),
 * 	vehicle: jsonBuildObject(vehicleSelectColumns),
 * }
 *
 * // Use the created object directly in a select query to return objects in the desired shape
 * const res: Location[] = await db.select(locationSelectColumns).from(locationTable)
 *
 * Referenced from "https://gist.github.com/rphlmr/de869cf24816d02068c3dd089b45ae82"
 */
export function jsonBuildObject<T extends SelectedFields>(
	shape: T,
): SQL<SelectResultFields<T>> {
	const chunks: SQL[] = []

	// Iterate through all selected columns
	Object.entries(shape).forEach(([key, value]) => {
		// Skip null or undefined fields
		if (!value) return

		// Don't have trailing commas at the beginning or end of query
		if (chunks.length > 0) {
			chunks.push(sql.raw(`,`))
		}

		// Comma separate select columns
		chunks.push(sql.raw(`'${key}',`))

		// Format dates using sql timezone
		// json_build_object formats to ISO 8601 ...
		if (is(value, PgTimestampString)) {
			chunks.push(sql`timezone('UTC', ${value})`)
		} else {
			// Don't format other data types
			chunks.push(sql`${value}`)
		}
	})

	// Use SQL built-in json_build_object to return selected columns as a typescript type
	return sql<
		SelectResultFields<T>
	>`coalesce(json_build_object(${sql.join(chunks)}), '{}')`
}

/**
 * Build an SQL Aggregate JSON object (array of SQL entries) from an object of selected columns
 *
 * @template T - Table column type or SQL type
 *
 * @param {T} shape - Drizzle table selected columns or SQL selected columns
 * @returns {SQL<InferColumnsDataTypes<T>[]>}
 * SQL Array of selected columns
 *
 * @example
 * // Store selected columns as a type
 * export const orgLocationWithItemsSelectColumns = {
 * 	// Use any combination of existing select columns
 * 	...orgLocationSelectColumns,
 * 	// Make an array out of existing select columns
 * 	orgItems: jsonBuildArray(fullOrgInventoryItemSelectColumns),
 * }
 *
 * // Use the created object in a DB query
 * const res: OrgLocationWithItems[] = await db.select(orgLocationWithItemsSelectColumns).from(orgLocationTable)
 *
 * Referenced from "https://www.answeroverflow.com/m/1091675515565387867"
 */
export function jsonBuildArray<T extends SelectedFields>(
	shape: T,
): SQL<SelectResultFields<T>[]> {
	const chunks: SQL[] = []

	// Format each column
	Object.entries(shape).forEach(([key, value]) => {
		// Skip null or undefined fields
		if (!value) return

		if (chunks.length > 0) {
			chunks.push(sql.raw(`,`))
		}
		chunks.push(sql.raw(`'${key}',`))
		chunks.push(sql`${value}`)
	})

	return sql<
		SelectResultFields<T>[]
	>`coalesce(json_agg(json_build_object(${sql.join(chunks)})), '[]')`
}
