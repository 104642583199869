import {
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion"
import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"

import { EmployeeDetailsCard } from "../../../../employee/details/card"

export function EquipmentOperatorsSection() {
	return (
		<AccordionItem value="operators">
			<AccordionTrigger className="px-0">
				<DetailsSectionHeading>Operators (1)</DetailsSectionHeading>
			</AccordionTrigger>
			<AccordionContent className="mb-5 p-0 pt-1">
				<DetailsSection>
					<EmployeeDetailsCard />
				</DetailsSection>
			</AccordionContent>
		</AccordionItem>
	)
}
