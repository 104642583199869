import { FileClock, SearchIcon, TimerResetIcon } from "lucide-react"

import type { Customer } from "@/server/schemas"
import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import { Input, InputSlot } from "@/components/ui/input"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"
import { PurchaseOrderDetailsCard } from "@/components/features/purchase-order/details-card"

const CustomerHistorySection: DetailsSectionComponent<Customer> = ({
	data: customer,
}) => {
	return (
		<DetailsSection>
			<DetailsSectionHeading>Customer History</DetailsSectionHeading>
			<Input placeholder="Search..." className="mb-3">
				<InputSlot>
					<SearchIcon />
				</InputSlot>
			</Input>
			<PurchaseOrderDetailsCard />
		</DetailsSection>
	)
}
CustomerHistorySection.label = "History"
CustomerHistorySection.icon = TimerResetIcon

export { CustomerHistorySection }
