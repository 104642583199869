import { List } from "lucide-react"

import { Avatar, AvatarFallback } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import { PopoverTrigger } from "@/components/ui/popover"

import { TimerCount } from "../../shop-watch/shared/timer-count"
import { useShopWatchTeamViewUser } from "./context"

export const TeamViewUserHeader = ({
	user,
}: {
	user: { name: string; location: string }
}) => {
	const { timeTotal, setIsListOpen } = useShopWatchTeamViewUser()

	return (
		<div className="flex border-b border-border-weak p-2">
			<Avatar className="mr-2 size-9">
				<AvatarFallback className="font-bold">{`${user.name.charAt(0)}${user.name.split(" ")[1]?.charAt(0)}`}</AvatarFallback>
			</Avatar>
			<div className="flex grow flex-col leading-tight">
				<div className="truncate font-bold">{user.name}</div>
				<div className="flex">
					<TimerCount timeInSeconds={timeTotal} isShowIcon={true} />
				</div>
			</div>
			<PopoverTrigger asChild>
				<Button
					onClick={() => setIsListOpen(true)}
					variant="ghost"
					size="sm"
					className="px-1.5"
				>
					<List />
				</Button>
			</PopoverTrigger>
		</div>
	)
}
