import type { FuseResultMatch } from "fuse.js"

interface HighlightedTextProps {
	text: string
	matches?: readonly FuseResultMatch[]
	path?: string
	paths?: string[]
	className?: string
	fieldPositions?: { [key: string]: { start: number; end: number } }
}

export const HighlightedText = ({
	text,
	matches,
	path,
	paths,
	className,
	fieldPositions,
}: HighlightedTextProps) => {
	if (!matches) return <span className={className}>{text}</span>

	// Get all relevant matches
	const relevantMatches = matches.filter((m) =>
		path ? m.key === path : paths?.includes(m.key ?? ""),
	)

	if (!relevantMatches.length)
		return <span className={className}>{text}</span>

	// Transform indices based on field positions if needed
	const allIndices = relevantMatches
		.flatMap((match) => {
			if (!fieldPositions || !match.key) return match.indices
			const fieldPos = fieldPositions[match.key]
			if (!fieldPos) return match.indices
			// Adjust indices based on field position in concatenated string
			return match.indices.map(
				([start, end]) =>
					[start + fieldPos.start, end + fieldPos.start] as [
						number,
						number,
					],
			)
		})
		.sort((a, b) => a[0] - b[0])
		// Merge overlapping ranges
		.reduce<[number, number][]>((acc, curr) => {
			if (acc.length === 0) return [curr]
			const prev = acc[acc.length - 1]
			if (!prev) return [curr]
			if (curr[0] <= prev[1] + 1) {
				prev[1] = Math.max(prev[1], curr[1])
				return acc
			}
			return [...acc, curr]
		}, [])

	const segments = allIndices.reduce<{ text: string; highlight: boolean }[]>(
		(acc, [start, end], i) => {
			if (i === 0 && start > 0) {
				acc.push({ text: text.slice(0, start), highlight: false })
			}

			acc.push({ text: text.slice(start, end + 1), highlight: true })

			const nextStart = allIndices[i + 1]?.[0]
			if (nextStart) {
				acc.push({
					text: text.slice(end + 1, nextStart),
					highlight: false,
				})
			} else {
				acc.push({ text: text.slice(end + 1), highlight: false })
			}

			return acc
		},
		[],
	)

	return (
		<span className={className}>
			{segments.map((segment, i) =>
				segment.highlight ?
					<mark
						key={i}
						className="rounded-sm bg-[yellow] font-semibold"
					>
						{segment.text}
					</mark>
				:	<span key={i}>{segment.text}</span>,
			)}
		</span>
	)
}
