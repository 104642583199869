import { integer, pgTable, serial, text } from "drizzle-orm/pg-core"

import {
	activeFields,
	addressFields,
	timestampFields,
} from "@/lib/utils/data/schema"

import { scopeTable } from "../user"

export const locationTable = pgTable("location", {
	id: serial("id").primaryKey(),
	scopeId: integer("scope_id")
		.references(() => scopeTable.id, { onDelete: "cascade" })
		.notNull(),
	name: text("name").notNull(),
	...addressFields,

	workstationName: text("workstation_name"),
	accountCode: text("account_code"),

	notes: text("notes"),
	...activeFields,
	...timestampFields,
})
