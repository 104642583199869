import { forwardRef } from "react"
import * as LabelPrimitive from "@radix-ui/react-label"
import { tv } from "tailwind-variants"
import type { VariantProps } from "tailwind-variants"

import { cn } from "@/lib/utils/classnames"

export const labelClasses = tv({
	base: "text-sm",
	variants: {
		variant: {
			default:
				"font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
		},
	},
	defaultVariants: {
		variant: "default",
	},
})

export const Label = forwardRef<
	React.ElementRef<typeof LabelPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
		VariantProps<typeof labelClasses>
>(({ className, variant, ...props }, ref) => (
	<LabelPrimitive.Root
		ref={ref}
		className={cn(labelClasses({ variant, className }))}
		{...props}
	/>
))
Label.displayName = LabelPrimitive.Root.displayName
