import type { FakeEquipment } from "@/routes/_main+/resources+/equipment/fake-equipment"
import { EllipsisIcon } from "lucide-react"

import { cn } from "@/lib/utils/classnames"
import { Button } from "@/components/ui/button"
import { Chip } from "@/components/ui/chip"
import {
	DetailsContext,
	DetailsContextBar,
	DetailsInfoGroup,
	DetailsSubtitle,
	DetailsTitle,
	DetailsTopRightActions,
} from "@/components/ui/details-view"
import type { DetailsHeaderContent } from "@/components/shared/details/details-view"

export const EquipmentPanelHeader: DetailsHeaderContent<FakeEquipment> = ({
	data: equipment,
}) => (
	<>
		<DetailsContextBar>
			<DetailsContext feature="equipment" />
			<DetailsTopRightActions>
				<Button variant="ghost" size="icon">
					<EllipsisIcon className="size-4" />
				</Button>
			</DetailsTopRightActions>
		</DetailsContextBar>
		<div className="flex flex-col gap-3">
			<div className="flex grow gap-3">
				<div className="size-[90px] shrink-0 rounded-md bg-background-weakest max-[500px]:hidden" />
				<div>
					<DetailsTitle>
						{equipment.unit}
						<span className="mx-1 font-normal opacity-70">|</span>
						{`${equipment.year} ${equipment.manufacturer} ${equipment.model}`}
					</DetailsTitle>
					<DetailsSubtitle>
						<div className="mb-0.5 flex items-center gap-2 font-bold uppercase">
							{equipment.type}
							{equipment.status !== "Available" && (
								<Chip
									variant="outline"
									className={cn(
										"shrink-0 px-1.5",
										equipment.status == "Out of Service" &&
											"border-error-border/30 text-error",
									)}
								>
									{equipment.status}
								</Chip>
							)}
						</div>
						<div>
							{equipment.vin} | {equipment.licenseNumber}
						</div>
					</DetailsSubtitle>
					{/* TODO: Replace with real equipment tags */}
					<div className="flex flex-wrap gap-1 py-2">
						<Chip variant="primary">PRIMARY</Chip>
						<Chip variant="info">HARD TO FIND</Chip>
						<Chip variant="info">NON-BILLABLE</Chip>
					</div>
				</div>
			</div>
			<EquipmentOwnerInfoSection equipment={equipment} />
		</div>
	</>
)

function EquipmentOwnerInfoSection({
	equipment,
}: {
	equipment: FakeEquipment
}) {
	return (
		<section className="flex grow flex-row flex-wrap items-center justify-between gap-3 gap-y-1.5">
			<div className="flex gap-3">
				<DetailsInfoGroup label="Owner">
					{equipment.registeredOwner}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Base Location">
					{equipment.baseLocation}
				</DetailsInfoGroup>
			</div>
			<div className="flex items-center rounded-md border border-border-strong bg-background-weak">
				<DetailsInfoGroup
					label="Fleet"
					className="items-center px-2 py-1"
				>
					{equipment.fleet}
				</DetailsInfoGroup>

				<DetailsInfoGroup
					label="Group"
					className="items-center border-x px-2 py-1"
				>
					{equipment.group}
				</DetailsInfoGroup>
				<DetailsInfoGroup
					label="Unit"
					className="items-center px-2 py-1 text-right"
				>
					{equipment.unit}
				</DetailsInfoGroup>
			</div>
		</section>
	)
}
