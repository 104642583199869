export interface FeatureLabels {
	navigation: string
	singular: string
	plural: string
	abbreviation: string
	group?: string
}

export const featureLabels = {
	customer: {
		navigation: "Customers",
		singular: "Customer",
		plural: "Customers",
		abbreviation: "Cust",
	},
	dashboard: {
		navigation: "Dashboard",
		singular: "Dashboard",
		plural: "Dashboard",
		abbreviation: "Dash",
	},
	employee: {
		navigation: "Employees",
		singular: "Employee",
		plural: "Employees",
		abbreviation: "Emp",
	},
	vendor: {
		navigation: "Vendors",
		singular: "Vendor",
		plural: "Vendors",
		abbreviation: "Vend",
	},
	workOrder: {
		navigation: "Work Orders",
		singular: "Work Order",
		plural: "Work Orders",
		abbreviation: "WO",
	},
	part: {
		navigation: "Parts",
		singular: "Part",
		plural: "Parts",
		abbreviation: "Part",
	},
	maintenance: {
		navigation: "Maintenance",
		singular: "Maintenance",
		plural: "Maintenance",
		abbreviation: "Maint",
	},
	shopClock: {
		navigation: "Shop Clock",
		singular: "Shop Clock",
		plural: "Shop Clock",
		abbreviation: "Shop",
	},
	financial: {
		navigation: "Financial",
		singular: "Financial",
		plural: "Financial",
		abbreviation: "Fin",
	},
	inventory: {
		navigation: "Inventory",
		singular: "Inventory",
		plural: "Inventory",
		abbreviation: "Inv",
	},
	equipment: {
		navigation: "Equipment",
		singular: "Equipment",
		plural: "Equipment",
		abbreviation: "Equip",
	},
	service: {
		navigation: "Services",
		singular: "Service",
		plural: "Services",
		abbreviation: "Serv",
	},
	location: {
		navigation: "Locations",
		singular: "Location",
		plural: "Locations",
		abbreviation: "Loc",
	},
	report: {
		navigation: "Reports",
		singular: "Report",
		plural: "Reports",
		abbreviation: "Rep",
	},
	user: {
		navigation: "Users",
		singular: "User",
		plural: "Users",
		abbreviation: "Usr",
	},
	home: {
		navigation: "Home",
		singular: "Home",
		plural: "Home",
		abbreviation: "Home",
	},
	component: {
		navigation: "Components",
		singular: "Component",
		plural: "Components",
		abbreviation: "Comp",
	},
	sentry: {
		navigation: "Sentry",
		singular: "Sentry",
		plural: "Sentry",
		abbreviation: "Sentry",
	},
	deployment: {
		navigation: "Deployment",
		singular: "Deployment",
		plural: "Deployment",
		abbreviation: "Deploy",
	},
} satisfies Record<string, FeatureLabels>
