import { Timer } from "lucide-react"

import { cn } from "@/lib/utils/classnames"
import { formatTimerFromSeconds } from "@/lib/utils/time-formatting-helpers"

export const TimerCount = ({
	timeInSeconds,
	isShowIcon = false,
}: {
	timeInSeconds: number
	isShowIcon?: boolean
}) => {
	return (
		<div
			className={cn(
				"relative flex items-center gap-1 rounded bg-[rgba(0,0,0,.04)] px-1.5 py-1 text-[11px] leading-none",
				isShowIcon && "pl-[18px]",
			)}
		>
			{isShowIcon && <Timer size={14} className="absolute left-0 mr-1" />}
			<div>{formatTimerFromSeconds(timeInSeconds)}</div>
		</div>
	)
}
