import { integer, pgTable, serial } from "drizzle-orm/pg-core"

import { contactFields, timestampFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"
import { locationTable } from "./location"

// Location Contact primary table
export const locationContactTable = pgTable("location_contact", {
	id: serial("id").primaryKey(),
	scopeId: integer("scope_id")
		.references(() => scopeTable.id, { onDelete: "cascade" })
		.notNull(),
	locationId: integer("location_id")
		.references(() => locationTable.id, { onDelete: "cascade" })
		.notNull(),
	...contactFields,
	...timestampFields,
})
