import { FileClock, SearchIcon, TimerResetIcon } from "lucide-react"

import type { Vendor } from "@/server/schemas"
import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import { Input, InputSlot } from "@/components/ui/input"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"
import { PurchaseOrderDetailsCard } from "@/components/features/purchase-order/details-card"

export const VendorHistorySection: DetailsSectionComponent<Vendor> = ({
	data: vendor,
}) => (
	<DetailsSection>
		<DetailsSectionHeading icon={FileClock}>
			Vendor History
		</DetailsSectionHeading>
		<Input placeholder="Search..." className="mb-3">
			<InputSlot>
				<SearchIcon />
			</InputSlot>
		</Input>
		<PurchaseOrderDetailsCard />
	</DetailsSection>
)
VendorHistorySection.label = "History"
VendorHistorySection.icon = TimerResetIcon
