import {
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion"
import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"

export function EquipmentOperatorInspectionsSection() {
	return (
		<AccordionItem value="operator-inspections">
			<AccordionTrigger className="px-0">
				<DetailsSectionHeading>
					Operator Inspections
				</DetailsSectionHeading>
			</AccordionTrigger>
			<AccordionContent className="mb-5 p-0 pt-2">
				<DetailsSection>
					{Array.from({ length: 5 }, (_, i) => (
						<AccordionItem
							className="flex justify-between py-1"
							key={i}
							value={i.toString()}
						>
							<div className="flex items-center gap-2">
								<div className="text-foreground-weak">
									11/9/24
								</div>
								<div className="font-bold">DVIR 14532</div>
							</div>
							<div className="text-foreground-weak">
								Satisfactory
							</div>
						</AccordionItem>
					))}
				</DetailsSection>
			</AccordionContent>
		</AccordionItem>
	)
}
