import { cn } from "@/lib/utils/classnames"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"

interface EmployeeAvatarProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	name: string
	img: string
}

export function EmployeeAvatar({ name, img, className }: EmployeeAvatarProps) {
	return (
		<Avatar
			className={cn("size-28 shrink-0 rounded-md shadow-lg", className)}
		>
			<AvatarImage src={img} />
			<AvatarFallback className="rounded-md text-4xl font-bold">{`${name.charAt(0)}${name.split(" ")[1]?.charAt(0)}`}</AvatarFallback>
		</Avatar>
	)
}
