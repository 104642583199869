import { ChevronRightIcon } from "lucide-react"
import { motion } from "motion/react"

import { cn } from "@/lib/utils/classnames"
import { Avatar, AvatarFallback } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"

import { useNavbar } from "../context"
import { UserDropdownContent } from "./user-dropdown"

export const NavbarFooter = ({ ...props }) => {
	const { toggleNavbar, isExpanded } = useNavbar()

	return (
		<div className="mt-3 border-t border-foreground/[15%] pt-3">
			<motion.div
				className={cn(
					"relative transition-[height]",
					isExpanded ? "h-11" : "h-24",
				)}
				{...props}
			>
				<Button
					variant="ghost"
					size="none"
					onClick={toggleNavbar}
					className={cn(
						"absolute top-0 transition-all",
						isExpanded ? "left-0 h-11 w-8" : (
							"left-1/2 size-11 -translate-x-1/2"
						),
					)}
				>
					<ChevronRightIcon
						className={cn(
							"size-4 shrink-0",
							isExpanded && "rotate-180",
						)}
					/>
				</Button>
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Button
							variant="ghost"
							size="none"
							className={cn(
								"absolute bottom-0 flex flex-1 items-center gap-2 text-left transition-all",
								isExpanded ?
									"right-0 justify-start"
								:	"right-1/2 translate-x-1/2 justify-center",
								isExpanded ?
									"w-[calc(100%-theme(width.9))]"
								:	"size-11 gap-0",
							)}
						>
							<Avatar className="scale-90">
								<AvatarFallback>Mike Mechanic</AvatarFallback>
							</Avatar>
							<div
								className={cn(
									"-space-y-0.5 leading-none transition-[opacity,width]",
									isExpanded ? "w-full opacity-100" : (
										"w-0 opacity-0"
									),
								)}
							>
								<h4 className="truncate text-base font-semibold">
									Mike Mechanic
								</h4>
								<p className="truncate text-sm">
									WP Tampa West
								</p>
							</div>
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent
						align="end"
						side="right"
						className="w-44"
					>
						<UserDropdownContent />
					</DropdownMenuContent>
				</DropdownMenu>
			</motion.div>
		</div>
	)
}
