import { InfoIcon } from "lucide-react"
import { useFormContext } from "react-hook-form"

import { Input } from "@/components/ui/input"
import type { AddEditSectionDefinition } from "@/components/shared/add-edit"

export const EquipmentAddEditInfo: AddEditSectionDefinition = {
	label: "Info",
	icon: InfoIcon,
	component: <EquipmentInfoGroup />,
}

function EquipmentInfoGroup() {
	const { register } = useFormContext()
	return (
		<Input
			{...register("name", {
				required: true,
			})}
			placeholder={"Acme Corporation"}
		/>
	)
}
