import { createContext, useContext, useEffect, useMemo, useState } from "react"

import { TooltipProvider } from "@/components/ui/tooltip"

import type { MockShopWatchActivity } from "./mock-data"

interface ShopWatchContext {
	// state: "expanded" | "collapsed"
	selectedActivity: MockShopWatchActivity
	setSelectedActivity: React.Dispatch<
		React.SetStateAction<MockShopWatchActivity>
	>
	isListOpen: boolean
	setIsListOpen: React.Dispatch<React.SetStateAction<boolean>>
	isRunning: boolean
	setIsRunning: React.Dispatch<React.SetStateAction<boolean>>
	time: number
	setTime: React.Dispatch<React.SetStateAction<number>>
	timeTotal: number
	setTimeTotal: React.Dispatch<React.SetStateAction<number>>
	clearShopWatchTimers: () => void
}

// Context & Hook
const ShopWatchContext = createContext<ShopWatchContext | null>(null)

export const useShopWatch = () => {
	const context = useContext(ShopWatchContext)
	if (!context) {
		throw new Error("useShopWatch must be used within a ShopWatchProvider")
	}
	return context
}

// Provider Component
export const ShopWatchProvider = ({
	children,
}: {
	children: React.ReactNode
}) => {
	const [selectedActivity, setSelectedActivity] = useState({})
	const [time, setTime] = useState(0) // Time in seconds
	const [timeTotal, setTimeTotal] = useState(0) // Time in seconds
	const [isRunning, setIsRunning] = useState(false)
	const [isListOpen, setIsListOpen] = useState(false)

	const clearShopWatchTimers = () => {
		// Select new item, reset timers, etc
		setIsRunning(false)
		setTime(0)
	}

	useEffect(() => {
		let timer: NodeJS.Timeout | undefined
		if (isRunning) {
			if (time == 0) setTime(1) // Set to 1 on click, then update every second
			timer = setInterval(() => {
				setTime((prevTime) => prevTime + 1)
			}, 1000)
		} else if (!isRunning && time !== 0) {
			clearInterval(timer)
		}
		return () => clearInterval(timer)
	}, [isRunning, time])

	const contextValue: ShopWatchContext = useMemo(
		() => ({
			selectedActivity,
			setSelectedActivity,
			time,
			setTime,
			timeTotal,
			setTimeTotal,
			isListOpen,
			setIsListOpen,
			isRunning,
			setIsRunning,
			clearShopWatchTimers,
		}),
		[
			selectedActivity,
			setSelectedActivity,
			time,
			setTime,
			timeTotal,
			isListOpen,
			setIsListOpen,
			setTimeTotal,
			isRunning,
			setIsRunning,
		],
	)

	return (
		<ShopWatchContext.Provider value={contextValue}>
			<TooltipProvider delayDuration={0}>{children}</TooltipProvider>
		</ShopWatchContext.Provider>
	)
}
