import { forwardRef } from "react"
import { Link as RemixLink } from "@remix-run/react"
import { tv } from "tailwind-variants"

export const linkClasses = tv({
	base: "font-medium text-link underline-offset-4 hover:underline",
})

export const Link = forwardRef<
	React.ElementRef<typeof RemixLink>,
	React.ComponentPropsWithoutRef<typeof RemixLink>
>(({ children, className, ...props }, ref) => (
	<RemixLink ref={ref} className={linkClasses({ className })} {...props}>
		{children}
	</RemixLink>
))
Link.displayName = "Link"
