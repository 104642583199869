import type { FakePart } from "@/routes/_main+/resources+/parts/fake-parts"
import { ArrowDown, WarehouseIcon } from "lucide-react"

import type { Location } from "@/server/schemas"
import { Button } from "@/components/ui/button"
import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"
import { LocationDetailsCard } from "@/components/features/location/details/card"

export const PartInventorySection: DetailsSectionComponent<FakePart> = ({
	data: part,
}) => {
	const tempLocation: Location = {
		id: 0,
		name: "Westshore Warehouse",
		street1: "123 Pleasant Street",
		street2: null,
		city: "Tampa",
		state: "FL",
		zip: "33611",
		workstationName: null,
		accountCode: "78945A",
		notes: null,
		contacts: [],
		metas: [],
		tags: [],
	}

	return (
		<DetailsSection>
			<DetailsSectionHeading>Inventory</DetailsSectionHeading>
			{/* TODO: Map over locations where this part is stored */}
			<LocationDetailsCard location={tempLocation} />
			<Button className="mt-2 flex items-center gap-1.5">
				<ArrowDown className="size-4" />
				Transfer part in
			</Button>
		</DetailsSection>
	)
}

PartInventorySection.label = "Inventory"
PartInventorySection.icon = WarehouseIcon
