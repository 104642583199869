import { forwardRef } from "react"
import type { LucideIcon } from "lucide-react"
import { tv } from "tailwind-variants"
import type { VariantProps } from "tailwind-variants"

import { cn } from "@/lib/utils/classnames"

interface IconFieldProps extends React.HTMLAttributes<HTMLElement> {
	icon: LucideIcon
	children: React.ReactNode
	iconSize?: number
	iconClasses?: string
	indicateEmpty?: boolean
	// textSize?: "xs" | "sm" | "lg"
}

export const iconFieldVariants = tv({
	base: "flex items-center gap-1.5",
	variants: {
		variant: {
			default: "",
			sm: "text-sm",
			xs: "text-xs",
			lg: "text-lg",
		},
	},
	defaultVariants: {
		variant: "default",
	},
})

export interface IconFieldComponentProps
	extends IconFieldProps,
		VariantProps<typeof iconFieldVariants> {}

const IconField = forwardRef<HTMLDivElement, IconFieldComponentProps>(
	(
		{
			icon,
			children,
			className,
			variant,
			iconSize,
			iconClasses,
			indicateEmpty = true,
			...props
		},
		ref,
	) => {
		const Icon = icon

		let IconPixelSize
		if (iconSize) {
			IconPixelSize = iconSize
		} else if (variant == "xs") {
			IconPixelSize = 12
		} else if (variant == "sm") {
			IconPixelSize = 14
		} else if (variant == "lg") {
			IconPixelSize = 18
		} else {
			IconPixelSize = 16
		}

		return (
			<div
				ref={ref}
				className={cn(iconFieldVariants({ variant, className }))}
				{...props}
			>
				<Icon
					data-icon
					className={cn("shrink-0 opacity-[60%]", iconClasses)}
					size={IconPixelSize}
				/>
				{children ??
					(indicateEmpty && (
						<span className="tracking-tighter text-foreground-weakest">
							--
						</span>
					))}
			</div>
		)
	},
)
IconField.displayName = "IconField"

export { IconField }
