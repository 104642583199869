import type { ActionFunctionArgs } from "@remix-run/node"
import { data } from "@remix-run/node"
import { useFetcher, useFetchers } from "@remix-run/react"
import { z } from "zod"

import { useRequestInfo } from "@/lib/hooks/use-request-info"
import { setView } from "@/lib/utils/view-cookie.server"

export const ViewFormSchema = z.object({
	boardOverride: z.boolean(),
	preferredView: z.enum(["queue", "grid"]),
})

export type ViewForm = z.infer<typeof ViewFormSchema>

export function useOptimisticViewMode() {
	const fetchers = useFetchers()
	const viewFetcher = fetchers.find(
		(f) => f.formAction === "/utils/view-switch",
	)

	if (viewFetcher && viewFetcher.formData) {
		const view = {
			boardOverride:
				viewFetcher.formData.get("boardOverride") === "true" ?
					true
				:	false,
			preferredView: viewFetcher.formData.get("preferredView"),
		}
		if (view && ViewFormSchema.safeParse(view).success) {
			return ViewFormSchema.safeParse(view).data
		}
	}
}

export function useView() {
	const requestInfo = useRequestInfo()
	const optimisticMode = useOptimisticViewMode()
	const fetcher = useFetcher()

	const setView = (view: ViewForm) => {
		fetcher.submit(
			{
				boardOverride: view.boardOverride,
				preferredView: view.preferredView,
			},
			{ method: "POST", action: "/utils/view-switch" },
		)
	}

	const viewPreference = optimisticMode ?? requestInfo.userPrefs.view

	return {
		boardOverride: viewPreference.boardOverride,
		preferredView: viewPreference.preferredView,
		setView,
	}
}

export async function action({ request }: ActionFunctionArgs) {
	const formData = await request.formData()
	const viewData = {
		boardOverride: formData.get("boardOverride") === "true" ? true : false,
		preferredView: formData.get("preferredView"),
	}

	const result = ViewFormSchema.safeParse(viewData)
	if (!result.success) {
		return data({ error: "Invalid view received" }, { status: 400 })
	}

	const view = result.data

	const responseInit = {
		headers: { "set-cookie": await setView(view) },
	}

	return data({ success: true }, { ...responseInit, status: 200 })
}
