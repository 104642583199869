import type { FakeEmployee } from "@/routes/_main+/resources+/employees/fake-employee"
import { Calendar, MapPin, UserIcon } from "lucide-react"

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import {
	DetailsCard,
	DetailsCardInfoLine,
	DetailsCardInfoLinesWrapper,
	DetailsCardOverline,
	DetailsCardSubtitle,
	DetailsCardTitle,
} from "@/components/ui/details-card"

export function EmployeeDetailsCard() {
	const fakeEmployee: FakeEmployee = {
		id: 1,
		name: "Tom Coleman",
		email: "tom@tomco.com",
		phone: "(555) 555-5555",
		role: "user",
		hireDate: "8/26/85",
		occupation: "Driver",
		img: "https://i.pravatar.cc/180?img=33",
	}

	return (
		<DetailsCard>
			<DetailsCardOverline icon={UserIcon}>Employee</DetailsCardOverline>
			{/* <Button
				variant="ghost"
				size="icon"
				className="absolute right-2 top-2"
			>
				<EllipsisIcon className="size-4" />
			</Button> */}
			<div className="flex items-center gap-2">
				<Avatar className="size-9">
					<AvatarImage src={fakeEmployee.img} />
					<AvatarFallback className="text-4xl font-bold">{`${fakeEmployee.name.charAt(0)}${fakeEmployee.name.split(" ")[1]?.charAt(0)}`}</AvatarFallback>
				</Avatar>
				<section>
					<DetailsCardTitle>{fakeEmployee.name}</DetailsCardTitle>
					<DetailsCardSubtitle>
						{fakeEmployee.occupation}
					</DetailsCardSubtitle>
				</section>
			</div>
			<DetailsCardInfoLinesWrapper>
				<DetailsCardInfoLine icon={MapPin}>
					Corvallis, OR
				</DetailsCardInfoLine>
				<DetailsCardInfoLine icon={Calendar}>
					Since {fakeEmployee.hireDate}
				</DetailsCardInfoLine>
			</DetailsCardInfoLinesWrapper>
		</DetailsCard>
	)
}
