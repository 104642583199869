import { ContactIcon, MailIcon, PhoneIcon } from "lucide-react"

import type { CustomerContact, VendorContact } from "@/server/schemas"
import { Chip } from "@/components/ui/chip"
import {
	DetailsCard,
	DetailsCardInfoLine,
	DetailsCardInfoLinesWrapper,
	DetailsCardOverline,
	DetailsCardSubtitle,
	DetailsCardTitle,
} from "@/components/ui/details-card"
import { IconField } from "@/components/ui/icon-field"

interface ContactDetailsCardProps {
	contact: CustomerContact | VendorContact
}
export function ContactDetailsCard({ contact }: ContactDetailsCardProps) {
	return (
		<DetailsCard>
			<DetailsCardOverline icon={ContactIcon}>
				Contact
			</DetailsCardOverline>
			{/* <Button
				variant="ghost"
				size="icon"
				className="absolute right-2 top-2"
			>
				<EllipsisIcon className="size-4" />
			</Button> */}

			<div className="flex items-center justify-between">
				<DetailsCardTitle>{contact.name}</DetailsCardTitle>
				{"isRepresentative" in contact && contact.isRepresentative && (
					<Chip variant="info" className="w-fit uppercase">
						Billing Contact
					</Chip>
				)}
			</div>
			<DetailsCardSubtitle>{contact.title}</DetailsCardSubtitle>

			<DetailsCardInfoLinesWrapper>
				<DetailsCardInfoLine icon={MailIcon}>
					{contact.email ?? (
						<div className="select-none text-foreground-weaker">
							N/A
						</div>
					)}
				</DetailsCardInfoLine>
				<DetailsCardInfoLine icon={PhoneIcon}>
					{contact.phone ?? (
						<div className="select-none tracking-tighter text-foreground-weakest">
							--
						</div>
					)}
				</DetailsCardInfoLine>
			</DetailsCardInfoLinesWrapper>
		</DetailsCard>
	)
}
