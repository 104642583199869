import { useNavigate } from "@remix-run/react"
import type { FuseResultMatch } from "fuse.js"

import type { Employee } from "@/server/schemas"
import { Avatar, AvatarFallback } from "@/components/ui/avatar"
import { CommandItem } from "@/components/ui/command"

import { useCommandMenu } from "../../command-menu-context"
import { HighlightedText } from "../../highlighted-text"

export function EmployeeSearchItem({
	item: employee,
	matches,
}: {
	item: Employee
	matches: readonly FuseResultMatch[] | undefined
}) {
	const navigate = useNavigate()
	const { setOpen } = useCommandMenu()

	// Helper to check if a field has matches
	const hasMatch = (path: string) => matches?.some((m) => m.key === path)

	return (
		<CommandItem
			className="flex items-center justify-between"
			value={employee.id.toString()}
			onSelect={() => {
				setOpen(false)
				navigate(
					`/resources/employees?details_type=employee&details_id=${employee.id}`,
				)
			}}
		>
			<div className="flex items-center gap-3">
				<Avatar>
					<AvatarFallback>
						{employee.firstName} {employee.lastName}
					</AvatarFallback>
				</Avatar>
				<div className="flex flex-col items-start gap-1">
					<div className="font-medium">
						<HighlightedText
							text={employee.firstName}
							matches={matches}
							path="firstName"
						/>{" "}
						<HighlightedText
							text={employee.lastName}
							matches={matches}
							path="lastName"
						/>
					</div>
					<div className="flex flex-col text-sm text-foreground-weak">
						{/* Show ID if it matches */}
						{hasMatch("id") && (
							<span>
								ID:{" "}
								<HighlightedText
									text={employee.id.toString()}
									matches={matches}
									path="id"
									className="font-medium"
								/>
							</span>
						)}

						{/* Show work email if it matches */}
						{hasMatch("workEmail") && employee.workEmail && (
							<span>
								Email:{" "}
								<HighlightedText
									text={employee.workEmail}
									matches={matches}
									path="workEmail"
									className="font-medium"
								/>
							</span>
						)}

						{/* Show work phone if it matches */}
						{hasMatch("workPhone") && employee.workPhone && (
							<span>
								Phone:{" "}
								<HighlightedText
									text={employee.workPhone}
									matches={matches}
									path="workPhone"
									className="font-medium"
								/>
							</span>
						)}
					</div>
				</div>
			</div>
		</CommandItem>
	)
}
