import type { Table as TanstackTable } from "@tanstack/react-table"

import type { Location } from "@/server/schemas"
import { QueueItemRow, QueueItems } from "@/components/ui/queue"
import { QueueView } from "@/components/shared/queue"

export function LocationQueue({
	listDataState,
}: {
	listDataState: TanstackTable<Location>
}) {
	return (
		<QueueView>
			<QueueItems
				listDataState={listDataState}
				renderItem={(location) => (
					<>
						<QueueItemRow>
							<div className="truncate font-bold">
								{location.name}
							</div>
							{location.contacts &&
								location.contacts[0]?.name && (
									<div className="shrink-0 text-sm text-foreground-weak">
										{location.contacts[0].name}
									</div>
								)}
						</QueueItemRow>
						<QueueItemRow variant="secondary">
							<div className="truncate">
								{location.city}, {location.state}
							</div>
						</QueueItemRow>
					</>
				)}
				onRowClick={(location) => {
					listDataState.setRowSelection({
						[location.id]: true,
					})
				}}
				isActive={(location) =>
					listDataState
						.getSelectedRowModel()
						.rows.some((row) => row.id === location.id.toString())
				}
			/>
		</QueueView>
	)
}
